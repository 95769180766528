import React, {Dispatch, FC, SetStateAction} from "react";
import RenewalDetailsConfirmationTableHeader from "../table-items/RenewalDetailsConfirmationTableHeader";
import RenewalDetailsConfirmationButtonFooter from "../table-items/RenewalDetailsConfirmationButtonFooter";
import RenewalDetailsConfirmationTableItem, {
  RenewalDetailsConfirmationTableItemProps
} from "../table-items/RenewalDetailsConfirmationTableItem";
import "./RenewalsConfirmYourCover.css";
import {lookupI18nString} from "shared-components/dist/translations/LookupI18nString";
import {CoverDetails, PolicyHolder, AdditionalDriver} from "shared/dist/generated/graphql/resolvers-types";
import {CurrencyFormat} from "../../../../../utils/money/CurrencyFormat";
import {formatDateStringFromIsoString} from "shared/dist/stdlib/DateFormat";
import {useAppSelector} from "../../../../../redux/Hooks";
import {selectSelectedQuoteType} from "../../redux/RenewalSelectors";
import {RenewalQuoteType} from "../../models/RenewalQuoteType";
import {
  additionalDriverHasValidBusinessUse,
  classOfUseIncludesBusiness,
  getTotalBusinessMileageForPolicy,
  getTotalPrivateMileageForPolicy
} from "../utils/Helpers";

interface Props {
  confirmed: boolean;
  setConfirmed: Dispatch<SetStateAction<boolean>>;
  setEditModalVisible: Dispatch<SetStateAction<boolean>>;
  policyHolder: PolicyHolder;
  additionalDrivers: AdditionalDriver[]
  coverDetails: CoverDetails;
  previousSectionConfirmed: boolean;
  renewalTimestamp: string;
}

const RenewalsConfirmYourCover: FC<Props> = (
  {
    confirmed,
    setConfirmed,
    setEditModalVisible,
    coverDetails,
    policyHolder,
    additionalDrivers,
    previousSectionConfirmed,
    renewalTimestamp
  }
) => {
  const selectedQuoteType = useAppSelector(selectSelectedQuoteType);
  const items = getStructuredCoverDetails(renewalTimestamp, coverDetails, policyHolder, additionalDrivers, selectedQuoteType);

  return (
    <div
      className={confirmed ? "renewals-confirm-your-cover--confirmed" : `renewals-confirm-your-cover${previousSectionConfirmed ? "" : "--disabled"} unconfirmed`}
      data-testid="renewals-confirm-your-cover"
    >
      <RenewalDetailsConfirmationTableHeader
        title={lookupI18nString("portal.renewals.detailConfirmation.yourCover")}
        confirmed={confirmed}
      />
      {items.map((item, index) => (
        <RenewalDetailsConfirmationTableItem
          key={index}
          testId={item.testId}
          title={item.title}
          subtitle={item.subtitle}
        />
      ))}
      <RenewalDetailsConfirmationButtonFooter
        testId={"your-cover"}
        confirmed={confirmed}
        setConfirmed={setConfirmed}
        setEditModalVisible={setEditModalVisible}
        disabled={!previousSectionConfirmed}
      />
    </div>
  );
};

const getCompulsoryExcessSubtitle = (compulsoryExcess: string, quoteType: RenewalQuoteType | undefined): string =>
  quoteType === "REBROKE"
    ? CurrencyFormat.formatStringAmountWithSymbol(compulsoryExcess)
    : lookupI18nString("portal.renewals.detailConfirmation.yourCover.compulsoryExcess.renewal");

function getStructuredCoverDetails(
  renewalTimestamp: string,
  coverDetails: CoverDetails,
  {mileage, businessMileage, classOfUse}: PolicyHolder,
  additionalDrivers: AdditionalDriver[],
  quoteType: RenewalQuoteType | undefined
): RenewalDetailsConfirmationTableItemProps[] {
  const coverDetailsItems = [
    {
      testId: "cover-type",
      title: lookupI18nString("portal.renewals.detailConfirmation.yourCover.coverType"),
      subtitle: coverDetails.coverType
    },
    {
      testId: "voluntary-excess",
      title: lookupI18nString("portal.renewals.detailConfirmation.yourCover.voluntaryExcess"),
      subtitle: CurrencyFormat.formatStringAmountWithSymbol(coverDetails.excess.voluntary.toString())
    },
    {
      testId: "compulsory-excess",
      title: lookupI18nString("portal.renewals.detailConfirmation.yourCover.compulsoryExcess"),
      subtitle: getCompulsoryExcessSubtitle(coverDetails.excess.compulsory.toString(), quoteType)
    },
    {
      testId: "insurance-start",
      title: lookupI18nString("portal.renewals.detailConfirmation.yourCover.insuranceStart"),
      subtitle: formatDateStringFromIsoString(renewalTimestamp)
    }
  ];

  if (mileage) {
    coverDetailsItems.push({
      testId: "mileage",
      title: lookupI18nString("portal.renewals.detailConfirmation.yourCover.mileage"),
      subtitle: getTotalPrivateMileageForPolicy(additionalDrivers, mileage ?? 0).toString()
    });
  }

  if ((businessMileage && classOfUseIncludesBusiness(classOfUse)) || additionalDriverHasValidBusinessUse(additionalDrivers)) {
    coverDetailsItems.push({
      testId: "mileage",
      title: lookupI18nString("portal.renewals.detailConfirmation.yourCover.businessMileage"),
      subtitle: getTotalBusinessMileageForPolicy(additionalDrivers, businessMileage ?? 0).toString()
    });
  }

  return coverDetailsItems;
}

export default RenewalsConfirmYourCover;
