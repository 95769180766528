import "./RenewalDetailsConfirmationPage.css";
import React, {FC, useState} from "react";
import ContentWithSidebarLayout
  from "../../../../structure/layouts/content-with-sidebar-layout/ContentWithSidebarLayout";
import PortalHeader from "../../shared/portal-header/PortalHeader";
import DetailConfirmationSidebar from "../components/detail-confirmation-sidebar/DetailConfirmationSidebar";
import RenewalsConfirmYourClaims from "./tables/RenewalsConfirmYourClaims";
import RenewalsConfirmYourCar from "./tables/RenewalsConfirmYourCar";
import RenewalsConfirmYourDetails from "./tables/RenewalsConfirmYourDetails";
import RenewalsConfirmYourCover from "./tables/RenewalsConfirmYourCover";
import RenewalsConfirmYourConvictions from "./tables/RenewalsConfirmYourConvictions";
import GetInTouchModal from "../components/get-in-touch-modal/GetInTouchModal";
import Button from "shared-components/dist/buttons/button/Button";
import {useNavigate} from "react-router-dom";
import {ArrowBack, ArrowForward} from "@mui/icons-material";
import RenewalsConfirmYourAdditionalDrivers from "./tables/RenewalsConfirmYourAdditionalDrivers";
import {lookupI18nString} from "shared-components/dist/translations/LookupI18nString";
import {useAppDispatch, useAppSelector} from "../../../../redux/Hooks";
import {
  selectActiveRenewalNotice,
  selectAllDetailsConfirmed,
  selectExistingPolicyForRenewal,
  selectExistingPolicyHolderForRenewal,
  selectRenewalRebrokePaymentFrequency,
  selectRenewalCoverDetails,
  selectSelectedQuoteType
} from "../redux/RenewalSelectors";
import RenewalSummaryError from "../components/renewal-summary-error/RenewalSummaryError";
import {usePolicyQueryParams} from "../../../../router/params/Params";
import {useEffectOnFirstRender} from "shared-components/dist/utils/hooks/UseEffectOnFirstRender";
import RenewalsConfirmAdditionalDriverClaims from "./tables/RenewalsConfirmAdditionalDriverClaims";
import RenewalsConfirmAdditionalDriverConvictions from "./tables/RenewalsConfirmAdditionalDriverConvictions";
import RenewalsConfirmYourCarModifications from "./tables/RenewalsConfirmYourCarModifications";
import RenewalsConfirmNoClaimsProtectionCard from "./no-claims-protection-card/RenewalsConfirmNoClaimsProtectionCard";
import {isUnderTwentyFive} from "../../../quote/vehicle/shared/questions/date-of-birth/validators/IsUnderTwentyFive";
import RenewalsConfirmNumberOfCars from "./tables/RenewalsConfirmNumberOfCars";
import {policyCanBeRenewedOrRebroked} from "../../shared/utils/PolicyStatusUtils";
import {setAllDetailsConfirmed} from "../redux/RenewalSlice";

type ConditionalSections = "AdditionalDriverClaims" | "AdditionalDriverConvictions" | "CarModifications" | "NumberOfCars";
type Section = ConditionalSections | "YourCar" | "YourDetails" | "YourCover" | "YourClaims" | "YourConvictions" | "YourAdditionalDrivers"

const RenewalDetailsConfirmationPage: FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const {policyId} = usePolicyQueryParams();
  const policy = useAppSelector(selectExistingPolicyForRenewal);
  const policyHolder = useAppSelector(selectExistingPolicyHolderForRenewal);
  const paymentFrequency = useAppSelector(selectRenewalRebrokePaymentFrequency);
  const renewalNotice = useAppSelector(selectActiveRenewalNotice);
  const selectedQuoteType = useAppSelector(selectSelectedQuoteType);
  const detailsConfirmed = useAppSelector(selectAllDetailsConfirmed);
  const coverDetails = useAppSelector(selectRenewalCoverDetails);
  const [yourCarConfirmed, setYourCarConfirmed] = useState(detailsConfirmed);
  const [yourDetailsConfirmed, setYourDetailsConfirmed] = useState(detailsConfirmed);
  const [yourCoverConfirmed, setYourCoverConfirmed] = useState(detailsConfirmed);
  const [yourClaimsConfirmed, setYourClaimsConfirmed] = useState(detailsConfirmed);
  const [yourConvictionsConfirmed, setYourConvictionsConfirmed] = useState(detailsConfirmed);
  const [yourAdditionalDriversConfirmed, setYourAdditionalDriversConfirmed] = useState(detailsConfirmed);
  const [yourAdditionalDriversClaimsConfirmed, setYourAdditionalDriversClaimsConfirmed] = useState(detailsConfirmed);
  const [yourAdditionalDriversConvictionsConfirmed, setYourAdditionalDriversConvictionsConfirmed] = useState(detailsConfirmed);
  const [numberOfCarsConfirmed, setNumberOfCarsConfirmed] = useState(detailsConfirmed);
  const [yourCarModificationsConfirmed, setYourCarModificationsConfirmed] = useState(detailsConfirmed);
  const [editModalVisible, setEditModalVisible] = useState(false);

  useEffectOnFirstRender(() => {
    if (!policyCanBeRenewedOrRebroked(policy)) navigate(`/portal/policy/${policyId}/renewals`);
  });

  const vehicleDetails = policy?.vehicleDetails;

  if (!paymentFrequency || !vehicleDetails || !coverDetails || !policyHolder || !renewalNotice) return (<RenewalSummaryError/>);

  const claims = policyHolder.claims;
  const convictions = policyHolder.convictions;
  const additionalDrivers = policy.additionalDrivers;
  const doAnyAdditionalDriversHaveClaims = additionalDrivers.some((additionalDriver) => additionalDriver.claims.length > 0);
  const doAnyAdditionalDriversHaveConvictions = additionalDrivers.some((additionalDriver) => additionalDriver.convictions.length > 0);
  const areAnyAdditionalDriversUnderTwentyFive = additionalDrivers.some((additionalDriver) => isUnderTwentyFive(new Date(additionalDriver.dateOfBirth)));
  const carHasModifications = vehicleDetails.modifications && vehicleDetails.modifications.length > 0;

  const sectionConfirmed = (sections: Section): boolean => {
    switch (sections) {
      case "YourCar":
        return yourCarConfirmed;
      case "YourDetails":
        return yourDetailsConfirmed;
      case "YourCover":
        return yourCoverConfirmed;
      case "YourClaims":
        return yourClaimsConfirmed;
      case "YourConvictions":
        return yourConvictionsConfirmed;
      case "YourAdditionalDrivers":
        return yourAdditionalDriversConfirmed;
      case "AdditionalDriverClaims":
        return doAnyAdditionalDriversHaveClaims ? yourAdditionalDriversClaimsConfirmed : true;
      case "AdditionalDriverConvictions":
        return doAnyAdditionalDriversHaveConvictions ? yourAdditionalDriversConvictionsConfirmed : true;
      case "CarModifications":
        return carHasModifications ? yourCarModificationsConfirmed : true;
      case "NumberOfCars":
        return areAnyAdditionalDriversUnderTwentyFive ? numberOfCarsConfirmed : true;
    }
  };

  const orderedSections: Section[] = ["YourCar", "CarModifications", "YourDetails", "YourCover", "YourClaims", "YourConvictions", "YourAdditionalDrivers", "NumberOfCars", "AdditionalDriverClaims", "AdditionalDriverConvictions"];

  const haveSectionsBeenConfirmedPriorTo = (currentSectionName: Section): boolean => {
    const index = orderedSections.findIndex(section => section === currentSectionName);

    if (index === -1) return false;
    if (index === 0) return true;

    const previousValues = orderedSections.slice(0, index).map(section => sectionConfirmed(section));

    return previousValues.every(value => value);
  };

  const canProgress = orderedSections.every(section => sectionConfirmed(section));

  return (
    <ContentWithSidebarLayout
      header={
        <PortalHeader/>
      }
      mainContent={
        <div className="renewal-details-confirmation-page" data-testid="renewal-details-confirmation-page">
          <h1 className="renewal-details-confirmation-page__title">
            {lookupI18nString("portal.renewals.detailConfirmation.confirmYourDetails")}
          </h1>
          <RenewalsConfirmYourCar
            confirmed={yourCarConfirmed}
            setConfirmed={setYourCarConfirmed}
            setEditModalVisible={setEditModalVisible}
            vehicleDetails={vehicleDetails}
            previousSectionConfirmed={haveSectionsBeenConfirmedPriorTo("YourCar")}
          />

          {carHasModifications &&
            <RenewalsConfirmYourCarModifications
              confirmed={yourCarModificationsConfirmed}
              setConfirmed={setYourCarModificationsConfirmed}
              setEditModalVisible={setEditModalVisible}
              modifications={vehicleDetails.modifications}
              previousSectionConfirmed={haveSectionsBeenConfirmedPriorTo("CarModifications")}
            />
          }

          <RenewalsConfirmYourDetails
            confirmed={yourDetailsConfirmed}
            setConfirmed={setYourDetailsConfirmed}
            setEditModalVisible={setEditModalVisible}
            policy={policy}
            policyHolder={policyHolder}
            previousSectionConfirmed={haveSectionsBeenConfirmedPriorTo("YourDetails")}
          />

          <RenewalsConfirmYourCover
            confirmed={yourCoverConfirmed}
            setConfirmed={setYourCoverConfirmed}
            setEditModalVisible={setEditModalVisible}
            coverDetails={coverDetails}
            policyHolder={policyHolder}
            additionalDrivers={additionalDrivers}
            previousSectionConfirmed={haveSectionsBeenConfirmedPriorTo("YourCover")}
            renewalTimestamp={renewalNotice.renewalTimestamp}
          />

          <RenewalsConfirmYourClaims
            confirmed={yourClaimsConfirmed}
            setConfirmed={setYourClaimsConfirmed}
            setEditModalVisible={setEditModalVisible}
            claims={claims}
            previousSectionConfirmed={haveSectionsBeenConfirmedPriorTo("YourClaims")}
          />

          <RenewalsConfirmYourConvictions
            confirmed={yourConvictionsConfirmed}
            setConfirmed={setYourConvictionsConfirmed}
            setEditModalVisible={setEditModalVisible}
            convictions={convictions}
            previousSectionConfirmed={haveSectionsBeenConfirmedPriorTo("YourConvictions")}
          />

          <RenewalsConfirmYourAdditionalDrivers
            confirmed={yourAdditionalDriversConfirmed}
            setConfirmed={setYourAdditionalDriversConfirmed}
            setEditModalVisible={setEditModalVisible}
            additionalDrivers={additionalDrivers}
            previousSectionConfirmed={haveSectionsBeenConfirmedPriorTo("YourAdditionalDrivers")}
          />

          {areAnyAdditionalDriversUnderTwentyFive &&
            <RenewalsConfirmNumberOfCars
              confirmed={numberOfCarsConfirmed}
              setConfirmed={setNumberOfCarsConfirmed}
              setEditModalVisible={setEditModalVisible}
              previousSectionConfirmed={haveSectionsBeenConfirmedPriorTo("NumberOfCars")}
            />
          }

          {doAnyAdditionalDriversHaveClaims &&
            <RenewalsConfirmAdditionalDriverClaims
              additionalDrivers={additionalDrivers}
              confirmed={yourAdditionalDriversClaimsConfirmed}
              setConfirmed={setYourAdditionalDriversClaimsConfirmed}
              setEditModalVisible={setEditModalVisible}
              previousSectionConfirmed={haveSectionsBeenConfirmedPriorTo("AdditionalDriverClaims")}
            />
          }

          {doAnyAdditionalDriversHaveConvictions &&
            <RenewalsConfirmAdditionalDriverConvictions
              additionalDrivers={additionalDrivers}
              confirmed={yourAdditionalDriversConvictionsConfirmed}
              setConfirmed={setYourAdditionalDriversConvictionsConfirmed}
              setEditModalVisible={setEditModalVisible}
              previousSectionConfirmed={haveSectionsBeenConfirmedPriorTo("AdditionalDriverConvictions")}
            />
          }

          {selectedQuoteType === "REBROKE" && <RenewalsConfirmNoClaimsProtectionCard/>}

          <GetInTouchModal isVisible={editModalVisible} setIsVisible={setEditModalVisible}/>

          <div
            className="renewal-details-confirmation-page__button-footer"
            data-testid="renewal-details-confirmation-page-footer"
          >
            <Button
              className="renewal-details-confirmation-page__button-footer__previous"
              variant="tertiary"
              onClick={() => {
                dispatch(setAllDetailsConfirmed(canProgress));
                navigate(-1);
              }}
              leftIcon={<ArrowBack/>}
            >
              {lookupI18nString("portal.renewals.detailConfirmation.previous")}
            </Button>

            <Button
              className="renewal-details-confirmation-page__button-footer__payment"
              variant="secondary"
              disabled={!canProgress}
              onClick={() => {
                dispatch(setAllDetailsConfirmed(canProgress));
                navigate(`/portal/policy/${policyId}/renewals/payment/${paymentFrequency.toLowerCase()}-payment`);
              }}
              rightIcon={<ArrowForward/>}
            >
              {lookupI18nString("portal.renewals.detailConfirmation.proceedToPayment")}
            </Button>
          </div>
        </div>
      }
      sidebar={
        <DetailConfirmationSidebar/>
      }
    />
  );
};

export default RenewalDetailsConfirmationPage;
