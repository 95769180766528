import {RootState} from "../../../../../../redux/Store";
import {createSelector} from "@reduxjs/toolkit";
import {FormField, isValidFormField} from "shared-components/dist/models/form-field/FormField";
import {activeFormFields} from "shared-components/dist/models/form-field/FormFields";
import {fullMonthsBetween, now, toSafeDate} from "shared/dist/stdlib/Dates";
import {DateOption, SerializableDateOption, toDateOption} from "shared/dist/models/date-option/DateOption";
import {ConditionalFormField} from "shared-components/dist/models/form-field/ConditionalFormField";
import {ConditionalDateFormField} from "shared-components/dist/models/form-field/variants/DateFormField";
import {CoverDetailsFields} from "../CoverDetailsFields";
import {endOfDay, isAfter, isBefore} from "date-fns";
import {ConditionalBooleanFormField} from "shared-components/dist/models/form-field/variants/BooleanFormField";
import {VehicleType, VehicleTypeOption} from "../../components/named-driver/shared/vehicle-type/VehicleTypeOption";
import {isNoClaimsBonusValid} from "./helpers/IsNoClaimsBonusValid";
import {startOfDay} from "date-fns/fp";
import {BreakInCoverDeclaration} from "shared/dist/generated/graphql/resolvers-types";
import {mapBreakInCoverDeclaration} from "../../../../../payment/shared/redux/mappers/BreakInCoverDeclarationMapper";

const coverDetailsSelector = (state: RootState): CoverDetailsFields => state.coverDetails;
const yearsNoClaimsBonusAsNumberSelector = (state: RootState): number | undefined => state.coverDetails.yearsNoClaimsBonus.value ? parseInt(state.coverDetails.yearsNoClaimsBonus.value) : undefined;
const existingPolicyExpiryDateSelector = (state: RootState): ConditionalFormField<string> => state.coverDetails.existingPolicyExpiryDate;
const companyCarPolicyExpiryDateSelector = (state: RootState): ConditionalFormField<string> => state.coverDetails.companyCarPolicyExpiryDate;
const coverStartDateSelector = (state: RootState): FormField<SerializableDateOption> => state.coverDetails.coverStartDate;
const coverStartDateValueSelector = (state: RootState): SerializableDateOption | undefined => state.coverDetails.coverStartDate.value;
const yearsNamedDriverSelector = (state: RootState): ConditionalFormField<Duration> => state.coverDetails.yearsNamedDriver;
const yearsCompanyCarBonusSelector = (state: RootState): ConditionalFormField<Duration> => state.coverDetails.yearsCompanyCarBonus;
const isCurrentlyOrPreviouslyInsuredSelector = (state: RootState): ConditionalBooleanFormField => state.coverDetails.isCurrentlyOrPreviouslyInsured;
const vehicleTypeSelector = (state: RootState): ConditionalFormField<VehicleTypeOption> => state.coverDetails.vehicleType;
export const namedDriverDeclarationAcceptanceSelector = (state: RootState): ConditionalBooleanFormField => state.coverDetails.namedDriverDeclarationAcceptance;
export const companyCarDeclarationAcceptanceSelector = (state: RootState): ConditionalBooleanFormField => state.coverDetails.companyCarDeclarationAcceptance;
const vehiclePreviousPolicyExpiryDateSelector = (state: RootState): ConditionalFormField<string> => state.coverDetails.vehiclePreviousPolicyExpiryDate;
export const breakInCoverDeclarationSelector = (state: RootState): BreakInCoverDeclaration => mapBreakInCoverDeclaration(state.coverDetails.breakInCoverDeclarationAcceptance.value);

export const existingPolicyExpiryDateAsDateSelector = createSelector(
  existingPolicyExpiryDateSelector,
  (existingPolicyExpiryDate: ConditionalFormField<string>): ConditionalDateFormField => {
    return {
      ...existingPolicyExpiryDate,
      value: toSafeDate(existingPolicyExpiryDate.value)
    };
  }
);

export const companyCarPolicyExpiryDateAsDateSelector = createSelector(
  companyCarPolicyExpiryDateSelector,
  (companyCarExpiryDate: ConditionalFormField<string>): ConditionalDateFormField => {
    return {
      ...companyCarExpiryDate,
      value: toSafeDate(companyCarExpiryDate.value)
    };
  }
);

export const vehiclePreviousPolicyExpiryDateAsDateSelector = createSelector(
  vehiclePreviousPolicyExpiryDateSelector,
  (vehiclePreviousPolicyExpiryDate: ConditionalFormField<string>): ConditionalDateFormField => {
    return {
      ...vehiclePreviousPolicyExpiryDate,
      value: toSafeDate(vehiclePreviousPolicyExpiryDate.value)
    };
  }
);

export const coverStartDateAsDateOptionSelector = createSelector(
  coverStartDateSelector,
  (coverStartDate: FormField<SerializableDateOption>): FormField<DateOption> => {
    return {
      ...coverStartDate,
      value: coverStartDate.value ? toDateOption(coverStartDate.value) : undefined
    };
  }
);

export const coverStartDateValueAsDateOptionSelector = createSelector(
  coverStartDateValueSelector,
  (coverStartDate: SerializableDateOption | undefined): DateOption | undefined => {
    return coverStartDate ? toDateOption(coverStartDate) : undefined;
  }
);

export const isCoverStartDateExpiredSelector = createSelector(
  coverStartDateAsDateOptionSelector,
  (coverStartDate: FormField<DateOption>): boolean => (
    coverStartDate.value ? isBefore(endOfDay(coverStartDate.value.date), new Date()) : false
  )
);

export const coverDetailsValidSelector = createSelector(
  coverDetailsSelector,
  (coverDetails: CoverDetailsFields): boolean => {
    return isValidFormField(
      ...activeFormFields(
        coverDetails,
        ["voluntaryExcess"]
      )
    );
  }
);

export const appliedNoClaimsBonusSelector = createSelector(
  yearsNoClaimsBonusAsNumberSelector,
  existingPolicyExpiryDateAsDateSelector,
  coverStartDateAsDateOptionSelector,
  (
    yearsNoClaimsBonus: number | undefined,
    existingPolicyExpiryDate: ConditionalDateFormField,
    coverStartDate: FormField<DateOption>
  ): number => {
    if (!existingPolicyExpiryDate.value || !yearsNoClaimsBonus || !coverStartDate.value) return 0;

    return isNoClaimsBonusValid(yearsNoClaimsBonus, existingPolicyExpiryDate.value, coverStartDate.value.date) ? yearsNoClaimsBonus : 0;
  }
);

export const periodBetweenInsuranceSelector = createSelector(
  yearsNoClaimsBonusAsNumberSelector,
  existingPolicyExpiryDateAsDateSelector,
  coverStartDateAsDateOptionSelector,
  (
    yearsNoClaimsBonus: number | undefined,
    existingPolicyExpiryDate: ConditionalDateFormField,
    coverStartDate: FormField<DateOption>
  ): number | undefined => {
    if (!existingPolicyExpiryDate.value || !yearsNoClaimsBonus || !coverStartDate.value) return undefined;

    if (isAfter(existingPolicyExpiryDate.value, now()) || !isNoClaimsBonusValid(yearsNoClaimsBonus, existingPolicyExpiryDate.value, coverStartDate.value.date)) return 0;

    return fullMonthsBetween(existingPolicyExpiryDate.value, startOfDay(now()));
  }
);

export const canProtectNoClaimsBonusSelector = createSelector(
  appliedNoClaimsBonusSelector,
  (appliedYearsNoClaims: number): boolean => appliedYearsNoClaims >= 5
);

export const yearsNamedDriverValueSelector = createSelector(
  yearsNamedDriverSelector,
  (yearsNamedDriver: FormField<Duration>): number | undefined => yearsNamedDriver.value ? yearsNamedDriver.value.years : undefined
);

export const yearsCompanyCarBonusValueSelector = createSelector(
  yearsCompanyCarBonusSelector,
  (yearsCompanyCarBonus: FormField<Duration>): number | undefined => yearsCompanyCarBonus.value ? yearsCompanyCarBonus.value?.years : undefined
);

export const companyCarPolicyExpiryDateValueSelector = createSelector(
  companyCarPolicyExpiryDateSelector,
  (companyCarPolicyExpiryDate: FormField<string>): string | undefined => companyCarPolicyExpiryDate.value
);

export const shouldShowNamedDriverIneligiblePanelSelector = createSelector(
  isCurrentlyOrPreviouslyInsuredSelector,
  vehicleTypeSelector,
  yearsNamedDriverValueSelector,
  (
    isCurrentlyOrPreviouslyInsured: ConditionalBooleanFormField,
    vehicleType: ConditionalFormField<VehicleTypeOption>,
    yearsNamedDriver: number | undefined
  ): boolean => {
    return isCurrentlyOrPreviouslyInsured.value === false
      || (vehicleType.value?.id !== undefined && vehicleType.value.id !== VehicleType.Car)
      || yearsNamedDriver === 0;
  }
);
