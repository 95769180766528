import {
  ACCIDENT_DESCRIPTIONS
} from "../../../../quote/vehicle/shared/questions/claims/models/AccidentDescriptionOption";
import {AccidentDescription, ClassOfUse, PolicyConviction} from "shared/dist/generated/graphql/resolvers-types";
import {lookupI18nString} from "shared-components/dist/translations/LookupI18nString";
import React, {ReactNode} from "react";
import RenewalDetailsConfirmationTableSection from "../table-items/RenewalDetailsConfirmationTableSection";
import {toGregorianFormat} from "shared/src/stdlib/DateFormat";
import RenewalDetailsConfirmationTableItem from "../table-items/RenewalDetailsConfirmationTableItem";
import {CurrencyFormat} from "../../../../../utils/money/CurrencyFormat";
import {classOfUseIncludesBusiness} from "./Helpers";

export function formatClaimDescription(claimDescription: AccidentDescription): string {
  const accidentDescription = ACCIDENT_DESCRIPTIONS.find((description) => description.vtCode === claimDescription);
  return accidentDescription?.description ?? "";
}

export function formatClassOfUseToString(classofUse: ClassOfUse | undefined): string {
  if (classofUse?.hasCommuting && classOfUseIncludesBusiness(classofUse)) return lookupI18nString("portal.renewals.detailConfirmation.yourDetails.classOfUse.socialCommutingBusiness");

  if (classofUse?.hasCommuting) return lookupI18nString("portal.renewals.detailConfirmation.yourDetails.classOfUse.socialCommuting");

  return lookupI18nString("portal.renewals.detailConfirmation.yourDetails.classOfUse.social");
}

export function formatGender(gender: string): string {
  switch (gender) {
    case "MALE":
      return lookupI18nString("structure.gender.male");
    case "FEMALE":
      return lookupI18nString("structure.gender.female");
    default:
      return lookupI18nString("structure.gender.unknown");
  }
}

export function formatConvictions(convictions: PolicyConviction[], additionalDriversName: string | undefined = undefined): ReactNode {
  return convictions.map((conviction, index) =>
    <RenewalDetailsConfirmationTableSection
      key={index}
      title={
        additionalDriversName
          ? lookupI18nString({
            id: "portal.renewals.detailConfirmation.yourConvictions.tableItem.additionalDriverTitle",
            templateVariables: {
              count: index + 1,
              max: convictions.length,
              driverName: additionalDriversName
            }
          })
          : lookupI18nString({
            id: "portal.renewals.detailConfirmation.yourConvictions.tableItem.title",
            templateVariables: {
              count: index + 1,
              max: convictions.length
            }
          })
      }
      subtitle={toGregorianFormat(new Date(conviction.date))}
    >
      {structureConvictionToItems(conviction, index)}
    </RenewalDetailsConfirmationTableSection>);
}

function structureConvictionToItems(conviction: PolicyConviction, index: number): ReactNode {
  const hasPoints = conviction.penaltyPoints !== 0;
  const hasFine = conviction.fine !== 0;
  const hasBan = conviction.drivingBan != null && conviction.drivingBan.duration !== 0;

  return (
    <>
      {conviction.parentCategoryDescription &&
        <RenewalDetailsConfirmationTableItem
          title={lookupI18nString("portal.renewals.detailConfirmation.yourConvictions.tableItem.type")}
          subtitle={conviction.parentCategoryDescription}
          testId={`portal.renewals.detailConfirmation.yourConvictions.type.${index}`}
        />
      }
      <RenewalDetailsConfirmationTableItem
        title={lookupI18nString("portal.renewals.detailConfirmation.yourConvictions.tableItem.date")}
        subtitle={toGregorianFormat(new Date(conviction.date))}
        testId={`portal.renewals.detailConfirmation.yourConvictions.date.${index}`}
      />
      {hasPoints &&
        <RenewalDetailsConfirmationTableItem
          title={lookupI18nString("portal.renewals.detailConfirmation.yourConvictions.tableItem.points")}
          subtitle={lookupI18nString({
            id: "portal.renewals.detailConfirmation.yourConvictions.tableItem.points.value",
            templateVariables: {value: conviction.penaltyPoints}
          })}
          testId={`portal.renewals.detailConfirmation.yourConvictions.points.${index}`}
        />
      }
      {hasFine &&
        <RenewalDetailsConfirmationTableItem
          title={lookupI18nString("portal.renewals.detailConfirmation.yourConvictions.tableItem.fine")}
          subtitle={CurrencyFormat.formatStringAmountWithSymbol(conviction.fine.toString())}
          testId={`portal.renewals.detailConfirmation.yourConvictions.fine.${index}`}
        />
      }
      {hasBan &&
        <RenewalDetailsConfirmationTableItem
          title={lookupI18nString("portal.renewals.detailConfirmation.yourConvictions.tableItem.drivingBan")}
          subtitle={`${conviction.drivingBan?.duration} ${conviction.drivingBan?.unit}`}
          testId={`portal.renewals.detailConfirmation.yourConvictions.ban.${index}`}/>
      }
    </>
  );
}
