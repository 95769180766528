export default {
  "login.loginButton": "Log In",
  "login.passwordInput.placeholder": "Password",
  "login.forgotPasswordLink": "Forgot Password?",
  "login.errors.incorrectCredentialsEntered": "The email address or password you entered is incorrect.",
  "login.errors.tooManyFailedAttempts": "Too many failed login attempts. Try again soon.",
  "login.errors.sessionTimeout": "Whoops, your session has expired. Please log in again to keep Strollin'.",
  "login.errors.genericLoginFailure": "Something went wrong, please try again.",
  "login.forgotPassword.modal.header": "Forgot Password?",
  "login.forgotPassword.modal.description": "Ok, let's get you a new one. Just enter the email connected to your account and we will send you a link.",
  "login.forgotPassword.modal.emailInput.label": "Email Address",
  "login.forgotPassword.modal.buttons.cancel": "Cancel",
  "login.forgotPassword.modal.buttons.sendLink": "Send Link",
  "login.forgotPassword.helpIsOnTheWayMessage.title": "Help is on the way!",
  "login.forgotPassword.helpIsOnTheWayMessage.description": "If this email is registered to a Stroll account, we will send you instructions on how to reset your password. If you don’t receive an email shortly, come back and try again.",
  "login.forgotPassword.submitButton": "Let's go!",
  "login.forgotPassword.genericFailure": "Something went wrong, please try again.",
  "login.forgotPassword.resetPasswordCodeHasExpired": "Sorry, that reset password link has expired.",
  "login.newHereButton": "I'm New Here",
  "notFound.body.errorMessage.slowDown": "Whoops! Slow down there, driver.",
  "notFound.body.errorMessage.takenAWrongTurn": "You’ve taken a wrong turn.",
  "notFound.body.button.startANewQuote": "Start a new quote",
  "navbar.menu.dashboard": "Dashboard",
  "navbar.menu.policies": "Policies",
  "navbar.menu.policies.summary": "Summary",
  "navbar.menu.policies.documents": "Documents",
  "navbar.menu.policies.renewals": "Renewals",
  "navbar.menu.policies.additionalDrivers": "Additional Drivers",
  "navbar.menu.policies.payments": "Payments",
  "navbar.menu.policies.claims": "Claims",
  "navbar.menu.quotes": "Quotes",
  "navbar.menu.claims": "Claims",
  "navbar.menu.helpAndSupport": "Help & Support",
  "navbar.menu.settings": "Settings",
  "navbar.menu.uploads": "Uploads",
  "navbar.logout": "Log out",
  "navbar.logout.modal.header": "Log out",
  "navbar.logout.modal.description": "Are you sure you want to log out?",
  "navbar.logout.modal.buttons.cancel": "Cancel",
  "navbar.logout.modal.buttons.confirm": "Yes, I'm sure!",
  "daysOfTheWeek.monday": "Monday",
  "daysOfTheWeek.friday": "Friday",
  "daysOfTheWeek.saturday": "Saturday",
  "portal.needToMakeAChange.header": "Need to make a change?",
  "portal.needToMakeAChange.description": "To make a change or cancel your policy, simply get in touch. \nWe’d be happy to help",
  "portal.needToMakeAChange.openingHours": "Mon-Fri: 9am-5pm",
  "portal.reminder.unverifiedEmail.title": "Verify Email Address",
  "portal.reminder.unverifiedEmail.description": "You recently changed your email address and need to verify.",
  "portal.reminder.unverifiedPhoneNumber.title": "Verify Contact Number",
  "portal.reminder.unverifiedPhoneNumber.description": "You recently changed your mobile number and need to verify.",
  "portal.reminder.uploadDocuments.title": "Upload Documents",
  "portal.reminder.uploadDocuments.description": "Upload both parts of your Drivers Licence & proof of No Claims Bonus/Experience (if applicable).",
  "portal.reminder.reviewInProgress.title": "Review in progress",
  "portal.reminder.reviewInProgress.description": "We are currently reviewing your uploaded documents.",
  "portal.reminder.withCustomer.title": "Action Required",
  "portal.reminder.withCustomer.description": "You need to complete further action for your docs to be verified.",
  "portal.reminder.verified.title": "Policy Verified",
  "portal.reminder.verified.description": "You're all set. Happy Driving!",
  "portal.reminder.pendingCancellation.title": "Pending Cancellation",
  "portal.reminder.pendingCancellation.description": "Your policy may be cancelled soon, get in touch with us via Live Chat or call us on 08000 11 31 21.",
  "portal.reminder.renewalsAwaitingInvite.title": "Renewal Coming Soon",
  "portal.reminder.renewalsAwaitingInvite.description": "We’re working on your renewal quote. We'll be sending it to you by email, so keep an eye on your inbox.",
  "portal.reminder.renewalOffered.title": "Time to Renew",
  "portal.reminder.renewalOffered.description": "We’ve sent you your renewal quote by email. For next steps, be sure to check your inbox.",
  "portal.reminder.renewalOffered.link.description": "We’ve got your renewal quote, click here to check it out.",
  "renewal.startRenewal": "View Renewal",
  "renewal.holdingPage.checkBack": "Check back closer to this date.",
  "renewal.holdingPage.renewalDue": "Your renewal is due on <b>{renewalDate}</b>",
  "renewal.holdingPage.renewalDueToday": "Your renewal is due today",
  "renewal.holdingPage.weWillContactYou": "We will contact you three weeks before your renewal is due, so you have plenty of time.",
  "renewal.readyPage.yourRenewalQuoteReady": "Your car insurance renewal is now ready for you",
  "renewal.readyPage.insurer": "It’s with {insurer}.",
  "renewal.readyPage.clickBelowStarted": "To get started, click on the button below.",
  "renewal.expiredPage.yourRenewalOfferHasExpired": "Your renewal offer has expired",
  "renewal.expiredPage.onlineAccessExpired": "Your policy ended with us on {policyEndDate}.{br}{br} Need to discuss your renewal?",
  "renewal.expiredPage.getInTouch": "Get in Touch",
  "renewal.inviteOverduePage.description": "If you would like to sort your insurance out now, you can drop us a note via live chat or call us on <a>08000 11 31 21</a> and we will get you sorted.",
  "renewal.inviteOverduePage.getInTouch": "Get in Touch",
  "renewal.completePage.title": "Renewal Completed",
  "renewal.completePage.description": "Got any questions? Feel free to get in touch with us by clicking the button below.",
  "renewal.completePage.getInTouch": "Get in Touch",
  "renewal.revokedPage.revoked": "Renewal In Review",
  "renewal.revokedPage.wereReviewingYourPolicy": "We’re reviewing your policy renewal and will let you know when it is ready to see",
  "renewal.revokedPage.nextSteps": "Next steps?",
  "renewal.revokedPage.nextStepDetails": "<ul>" + "<li>Keep an eye on your inbox over the next few days for an update</li>" + "</ul>",
  "helpCard.needHelp": "Need Help?",
  "helpCard.needAHand": "If you need a hand or have a question about your upcoming renewal, just drop us a note via chat or call us on <a>08000 11 31 21</a>.",
  "helpCard.chatToUs": "Chat to us",
  "document.upload.header.title": "Document Upload",
  "document.upload.policy.field.name": "Your Policy",
  "document.upload.vehicle.field.name": "Car",
  "document.upload.registration.number.field.name": "Reg Number",
  "document.upload.policy.term.number.field.name": "Renewal Due",
  "document.upload.form.title": "What document would you like to upload?",
  "document.upload.documentTypeError": "Please select a document type.",
  "document.upload.noDocumentsToUploadError": "You must select at least 1 document for upload.",
  "document.upload.document.type.licence.plastic.front": "Driving Licence - Plastic Front",
  "document.upload.document.type.licence.plastic.back": "Driving Licence - Plastic Back",
  "document.upload.document.type.licence.paper.front": "Driving Licence - Paper Front",
  "document.upload.document.type.licence.paper.back": "Driving Licence - Paper Back",
  "document.upload.document.type.ncb": "Proof of No Claims Bonus",
  "document.upload.document.type.namedDriverExperience": "Proof of Named Driver Experience",
  "document.upload.document.type.companyCarBonus": "Proof of Company Car Bonus",
  "document.upload.document.type.other": "Other",
  "document.upload.form.proofOfIdentity": "We use your driving licence in order to prove your identity.",
  "document.upload.form.proofOfIdentity.description": "<b>What we will need:</b>\n" + "<ul>" + "<li>Both sides of the plastic part of your driving licence.</li>" + "<li>Both sides of your counterpart paper licence.</li>" + "</ul>",
  "document.upload.form.ncb": "We will use your proof of No Claims Bonus to verify the years you’ve been claims free driving.",
  "document.upload.form.ncb.description": "<b>What we will need:</b>\n" + "<ul>" + "<li>A clear image or PDF copy of your proof of No Claims Bonus from your previous insurer.</li>" + "</ul>",
  "document.upload.form.namedDriverExperience": "We will use your proof of Named Driving Experience to verify the years you’ve been an additional driver on someone else’s policy.",
  "document.upload.form.namedDriverExperience.description": "<b>What we will need:</b>\n" + "<ul>" + "<li>A clear image or PDF copy for every full year of experience from the broker or insurer of the car (or cars) that you were named on.</li>" + "</ul>",
  "document.upload.form.companyCarBonus": "We will use your proof of Company Car Bonus to verify the years you’ve been claims free driving.",
  "document.upload.form.companyCarBonus.description": "<b>What we will need:</b>\n" + "<ul>" + "<li>A clear image or PDF copy of the proof of Company Car Bonus from your employer’s insurer showing that you had sole use of the company car during the time that the bonus was earned.</li>" + "</ul>",
  "document.upload.form.header": "Drag and drop files to upload",
  "document.upload.form.mobile.header": "Select files to upload",
  "document.upload.form.description": "Accepted file types:",
  "document.upload.form.button.text": "Browse Files",
  "document.upload.form.remove.button.text": "Remove",
  "document.upload.form.submit.button.text": "Confirm and Upload Documents",
  "document.upload.form.submit.button.header": "The image must be clear to see all relevant information.",
  "document.upload.form.successful.upload.message": "{numberOfSuccessfulFiles} file successfully uploaded",
  "document.upload.form.successful.uploads.message": "{numberOfSuccessfulFiles} files successfully uploaded",
  "document.upload.form.failed.upload.message": "{numberOfFailedFiles} file failed to upload",
  "document.upload.form.failed.uploads.message": "{numberOfFailedFiles} files failed to upload",
  "document.upload.form.retry": "Retry",
  "compareYourQuote.title": "Compare Your Quotes",
  "compareYourQuote.description": "Take a look at the best prices that we’ve been able to get you. Choose a quote to find out more and customise your cover.\n You can always come back here to switch between quotes.",
  "compareYourQuote.annualCost": "Annual Cost",
  "compareYourQuote.monthlyCost": "Monthly Cost",
  "compareYourQuote.underageMonthlyPaymentStop.title": "We need to speak to you",
  "compareYourQuote.underageMonthlyPaymentStop.description": "As you are 17, we need to ask you some additional questions so you can pay in monthly instalments. Get in touch with the Stroll team and we will get you covered.",
  "compareYourQuote.underageMonthlyPaymentStop.close": "Close",
  "compareYourQuote.underageMonthlyPaymentStop.contact": "Get in touch",
  "insurerQuoteCard.subheader": "Quote Reference:",
  "insurerQuoteCard.costPerMonth": "/month",
  "insurerQuoteCard.numberOfInstalments": "Paid in {numberOfInstalments} monthly instalments.",
  "insurerQuoteCard.button.text": "Take a look",
  "insurerQuoteCard.noExperience.header": "Starting with zero experience",
  "insurerQuoteCard.namedDriver.header": "Named Driving Experience Discount: Applied",
  "insurerQuoteCard.noClaimsBonus.header": "No Claims Bonus Discount: Applied",
  "insurerQuoteCard.companyCar.header": "Company Car Discount: Applied",
  "insurerQuoteCard.tooltip.calculated.header": "How is this quote calculated?",
  "insurerQuoteCard.noExperience.tooltip.calculated.description": "As you have no previous driving experience or bonus to apply to this quote, we have quoted you the best price.",
  "insurerQuoteCard.noExperience.tooltip.changes.header": "How do I get a bonus or discount?",
  "insurerQuoteCard.noExperience.tooltip.changes.description": "Insurers will give you a discount for each year's claims free driving that you accumulate, so good news, if you purchase this quote and have a claims free year you will have a bonus to use next year!",
  "insurerQuoteCard.namedDriver.tooltip.calculated.description": "Using your previous driving experience from another policy, we're able to apply a discount for the years that you were claims free on that policy. It's important to remember that as part of your onboarding process you will need to provide us proof of each full year's driving experience you've declared.",
  "insurerQuoteCard.namedDriver.tooltip.changes.header": "What if my policy changes?",
  "insurerQuoteCard.namedDriver.tooltip.changes.description": "If there any changes made to your policy throughout the year such as making a claim or adding an inexperienced driver then this could result in your named experience discount being reduced to zero.",
  "insurerQuoteCard.noClaimsBonus.tooltip.calculated.description": "Using the No Claims Bonus that you have entered, we're able to apply a discount for the years that you were claims free. It's important to remember that as part of your onboarding process you will need to provide us proof of each full year's bonus you've declared.",
  "insurerQuoteCard.noClaimsBonus.tooltip.changes.header": "Can I protect my bonus?",
  "insurerQuoteCard.noClaimsBonus.tooltip.changes.description": "Yes, if you have 5 or more years No Claims Bonus, you can protect your bonus for a small fee.  \n" + "\n" + "Eligible for No Claims Bonus Protection? Add protection to your quote on the next page to update your price.",
  "insurerQuoteCard.companyCar.tooltip.calculated.description": "Using your company car bonus, we're able to apply a discount for the years that you were claims free on that policy. It's important to remember, that as part of your onboarding process you will need to provide us proof of the number of years' company car bonus that you've declared.",
  "insurerQuoteCard.companyCar.tooltip.changes.header": "Can I protect my bonus?",
  "insurerQuoteCard.companyCar.tooltip.changes.description": "No, you can’t currently protect your company car bonus when quoting with Stroll online.",
  "insurerQuoteCard.namedDriver.footer.description": "You will need to send us a copy of your driving licence (both parts) and documented proof of each year’s driving experience, from the broker or insurer of the car (or cars) that you were named on.",
  "insurerQuoteCard.companyCar.footer.description": "You will need to send us a copy of your driving licence (both parts) and documented proof of each year’s driving experience, from the broker or insurer of the car (or cars) that you were named on.",
  "insurerQuoteCard.noClaimsBonus.footer.description": "You will need to send us a copy of your driving licence (both parts) and documented proof of your No Claims Bonus, with an expiry date no more than 2 years before your start date.",
  "insurerQuoteCard.noExperience.footer.description": "You will need to send us a copy of your driving licence (both parts).",
  "insurerQuoteCard.body.insurancePremium": "Insurance Premium",
  "insurerQuoteCard.body.APR": "APR",
  "insurerQuoteCard.body.deposit": "Deposit",
  "insurerQuoteCard.body.adminFee": "Admin Fee",
  "insurerQuoteCard.body.Extras": "Extras",
  "insurerQuoteCard.body.quoteWith": "Quote with",
  "policies.extra.smartfobKeycare.description": "Finding your keys has never been easier. Attach the SmartFob to your keys and you can use the app to locate and track where your keys and phone are - it's that simple. This covers the cost to replace your lost or stolen keys up to £1500.",
  "policies.extra.legalExpenses.description": "Legal Expenses comes in useful if you need to make a claim against someone else. It will cover the cost of your legal fees if you want to pursue compensation. It also includes a FREE legal advice helpline service 24/7.",
  "policies.extra.replacementCar.description": "This type of cover is a lifesaver if your car’s out of action for a couple of weeks. We will provide you with a replacement car for up to three weeks if your car has been written off in an \"at fault\" accident.",
  "policies.extra.roadsideAssistancePlatinum.description": "Roadside Assistance cover comes in handy as it offers unlimited mileage recovery anywhere in NI and ROI. We will send a repairer who will either fix your car there and then or tow you to the nearest garage.",
  "policies.extra.excessInsurance.description": "Excess Insurance will reimburse you for the excess you paid following a claim, so you're not out of pocket for too long.",
  "policies.extra.excessInsurance.question": "What level of excess insurance would you like?",
  "policies.extra.excessInsurance.error": "This level of cover does not meet your policy excess.",
  "policies.extra.excess.insurance.option.type1.description": "Up to £350",
  "policies.extra.excess.insurance.option.type2.description": "Up to £500",
  "policies.extra.excess.insurance.option.type3.description": "Up to £750",
  "policies.extra.excess.insurance.option.type4.description": "Up to £1000",
  "policies.extra.excess.insurance.option.type5.description": "Up to £1500",
  "policies.mainContent.insuranceProductInformation.insuranceProductInformationLink": "Your Insurance Product Information",
  "policies.mainContent.policyBooklet.policyBookletLink": "Your Policy Booklet",
  "policies.mainContent.termsOfBusinessAgreement.termsOfBusinessAgreementLink": "Your Terms of Business Agreement",
  "policies.header.title": "Your Policy",
  "policies.header.subHeader.coverSummary": "Cover Summary",
  "policies.header.subHeader.documents": "Documents",
  "policies.header.subHeader.renewals": "Renewal",
  "policies.header.subHeader.additionalDrivers": "Additional Drivers",
  "policies.header.subHeader.payments": "Payments",
  "policies.header.subHeader.claims": "Claims",
  "policies.header.subHeader.policySummaryCardRow.policyNumber": "Policy Number",
  "policies.header.subHeader.policySummaryCardRow.checkBackSoon": "check-back-soon",
  "policies.header.subHeader.policySummaryCardRow.activeOn": "Active on",
  "policies.header.subHeader.policySummaryCardRow.renewalDue": "Renewal Due",
  "policies.header.subHeader.policySummaryCardRow.expiredOn": "Expired on",
  "policies.header.subHeader.policySummaryCardRow.startedOn": "Started on",
  "policies.mainContent.cover.title": "Policy Overview",
  "policies.summary.totalPolicyExcess": "Policy Excess",
  "policies.summary.checkYourDocuments": "See your policy documents",
  "policies.summary.coverType": "Cover Type",
  "policies.summary.noClaimsProtection": "No Claims Bonus",
  "policies.summary.noClaimsProtection.protected": "Protected",
  "policies.summary.noClaimsProtection.unprotected": "Unprotected",
  "policies.mainContent.yourCar": "Your Car",
  "policies.mainContent.yourCar.make": "Make",
  "policies.mainContent.yourCar.model": "Model",
  "policies.mainContent.yourCar.fuel": "Fuel",
  "policies.mainContent.yourCar.transmission": "Transmission",
  "policies.mainContent.yourCar.engineSize": "Engine Size",
  "policies.mainContent.yourCar.yearOfRegistration": "Year of Registration",
  "policies.mainContent.yourCar.trimLevel": "Model Type",
  "policies.mainContent.yourDetails": "Your Details",
  "policies.mainContent.yourDetails.name": "Name",
  "policies.mainContent.yourDetails.dateOfBirth": "Date of Birth",
  "policies.mainContent.yourDetails.address": "Address",
  "policies.mainContent.yourDetails.maritalStatus": "Relationship Status",
  "policies.mainContent.yourDetails.jobTitle": "Job Title",
  "policies.mainContent.yourDetails.secondaryJobTitle": "Secondary Job Title",
  "policies.mainContent.yourAdditionalDrivers": "Your Additional Drivers",
  "policies.mainContent.yourAdditionalDrivers.mainDriver": "Main Driver",
  "policies.mainContent.policyExtras.title.placeholder": "Policy Extra Name",
  "policies.mainContent.policyExtras.title": "Policy Extras",
  "policies.mainContent.policyExtras.policyExtras.startDate": "Active from {startDate}",
  "policies.mainContent.policyExtras.viewDocuments": "View Documents",
  "policies.mainContent.policyExtras.smartfobKeycare.title": "SmartFob Keycare",
  "policies.mainContent.policyExtras.legalExpenses.title": "Legal Expenses",
  "policies.mainContent.policyExtras.replacementCar.title": "Replacement Car",
  "policies.mainContent.policyExtras.roadsideAssistancePlatinum.title": "Roadside Assistance",
  "policies.mainContent.policyExtras.excessInsurance.title": "Excess Insurance",
  "portal.error.unableToLoadPolicy": "Unable to load policy, please try again.",
  "portal.error.unableToLoadRenewal": "Unable to load renewal, please try again.",
  "portal.error.page.title": "Unable to load policy.",
  "portal.error.page.description": "We're having trouble loading your policy right now, please try again later.",
  "portal.spinner.message": "Hang on, we’re getting a few things ready",
  "policies.documents.activeDocuments": "Active Documents",
  "policies.documents.requestPrintedDocuments.ready": "Request Printed Documents",
  "policies.documents.processingMessage": "Your documents are currently being generated. \n" + "They will be with you shortly.",
  "policies.documents.type.certificateAndScheduleOfInsurance.title": "Certificate and Car Insurance Schedule",
  "policies.documents.type.statementOfFact.title": "Statement of Fact",
  "policies.documents.type.noClaimsBonusProtection.title": "No Claims Bonus Protection",
  "policies.documents.type.insuranceProductInformation.title": "Car Insurance IPID",
  "policies.documents.type.termsOfBusinessAgreement.title": "Terms and Conditions",
  "policies.documents.type.policyWordingBooklet.title": "Policy Wording Booklet",
  "policies.documents.type.smartfobKeycare.title": "SmartFob Keycare",
  "policies.documents.type.roadsideAssistancePlatinum.title": "Roadside Assistance Platinum",
  "policies.documents.type.legalExpenses.title": "Legal Expenses",
  "policies.documents.type.excessInsurance.title": "Excess Insurance",
  "policies.documents.type.excessInsuranceSchedule.title": "Excess Insurance Schedule",
  "policies.documents.type.courtesyCar.title": "Replacement Car",
  "policies.documents.type.policySummary.title": "Policy Summary",
  "policies.documents.type.policyExtras.title": "Policy Extras",
  "policies.documents.type.certificateAndScheduleOfInsurance.description": "This document contains your Certificate and Schedule of Insurance. Your certificate shows proof that you have car insurance and the Schedule of Insurance goes into more detail like your level of cover, who is insured to drive your car and how much your excess is.",
  "policies.documents.type.statementOfFact.description": "This is an overview of the information you have given us, that we believe are facts and it forms part of your insurance contract.",
  "policies.documents.type.noClaimsBonusProtection.description": "This doc outlines what you have paid for your NCB protection. It shows info on the discount applied to your policy and the NCB years you would lose if you made a claim and weren't protected.",
  "policies.documents.type.insuranceProductInformation.description": "It’s a short document summarising all the key information of your insurance and it stands for Insurance Policy Information Document.",
  "policies.documents.type.termsOfBusinessAgreement.description": "This goes into all the terms and conditions you have to follow as a customer of Stroll. It’s mighty but holds everything you need to know about T&C’s.",
  "policies.documents.type.policyWordingBooklet.description": "So, this doc shows ALL the information included in your policy like what you’re insured for and what you’re not insured for.",
  "policies.documents.type.default.description.placeholder": "No description available.",
  "policies.archivedDocuments.title": "Archived Documents",
  "policies.expiredDocuments.title": "Expired Documents",
  "policies.cancelledDocuments.title": "Cancelled Documents",
  "policies.upcomingDocuments.title": "Upcoming Documents",
  "policies.upcomingDocuments.sidebar.goBack": "Documents",
  "policies.upcomingDocuments.card.title": "Your new docs are here",
  "policies.upcomingDocuments.card.description": "Check out your updated documents reflecting your new policy changes. We’re sticklers for detail so make sure your info is correct and accurate.",
  "policies.upcomingDocuments.card.button": "View new documents",
  "policies.documents.requestPrintedDocuments.modal.title": "Request Printed Documents",
  "policies.documents.requestPrintedDocuments.modal.address.title": "We will deliver your documents to this address:",
  "policies.documents.requestPrintedDocuments.modal.oldAddress.title": "Old Address?",
  "policies.documents.requestPrintedDocuments.modal.oldAddress.description": "If you need to update your address please contact one of our agents",
  "policies.documents.requestPrintedDocuments.modal.policyBooklet.header": "Looking for your Policy Booklet?",
  "policies.documents.requestPrintedDocuments.modal.policyBooklet.description": "This isn’t included in your printed documents but you can always view it online",
  "policies.documents.requestPrintedDocuments.modal.policyBooklet.link": "here",
  "policies.documents.requestPrintedDocuments.modal.dismiss": "Maybe Later",
  "policies.documents.requestPrintedDocuments.modal.orderPrints": "Order Prints",
  "policies.documents.requestPrintedDocuments.modal.printRequestSent.title": "Request received!",
  "policies.documents.requestPrintedDocuments.modal.printRequestSent.description": "Keep an eye out for your documents, they should arrive within 3 working days.",
  "policies.documents.requestPrintedDocuments.modal.printWeeklyLimitHit.title": "You’ve already ordered a set of documents!",
  "policies.documents.requestPrintedDocuments.modal.printWeeklyLimitHit.description": "You ordered documents on {date}, if you don’t receive them please wait {minDaysBetweenRequests} working days before trying to request more.",
  "policies.documents.requestPrintedDocuments.modal.printBatchLimitHit.title": "You can’t request documents right now.",
  "policies.documents.requestPrintedDocuments.modal.printBatchLimitHit.description": "You’ve already requested this set of documents three times in the last 12 months and we’re unable to provide more.\n" + "If you need assistance contact an agent.",
  "policies.documents.requestPrintedDocuments.modal.error": "Whoops, something's gone wrong. Give it another go.",
  "policies.additionalDrivers.dateOfBirth": "Date of Birth",
  "policies.additionalDrivers.address": "Address",
  "policies.additionalDrivers.carStorage": "Car Storage",
  "policies.additionalDrivers.occupation": "Job Title",
  "policies.additionalDrivers.classOfUse": "Class of Use",
  "policies.additionalDrivers.licenceInformation": "Licence",
  "policies.additionalDrivers.convictions": "Motoring Offences",
  "policies.additionalDrivers.medicalInformation": "Medical Declarations",
  "policies.additionalDrivers.claims": "Claims",
  "policies.additionalDrivers.noAdditionalDrivers.title": "You don't have any additional drivers",
  "policies.additionalDrivers.noAdditionalDrivers.message": "Contact an agent to talk about adding a new driver to your policy.",
  "passwordConfirmation.passwordInput.placeholder": "New Password",
  "passwordConfirmation.repeatPasswordInput.placeholder": "Repeat New Password",
  "passwordConfirmation.matchingPasswords.error.message": "Passwords do not match.",
  "passwordConfirmation.invalidPasswordPolicy.error.message": "Your password must meet the requirements below.",
  "passwordConfirmation.error.message.unauthorized": "Incorrect password, please try again.",
  "passwordConfirmation.error.message.tooManyFailedAttempts": "Too many change password attempts. Try again soon.",
  "passwordConfirmation.error.message.genericFailure": "Something went wrong, please try again.",
  "accountActivation.savePasswordButton": "Activate Account",
  "accountActivation.informationPanel.title": "Activate your account",
  "accountActivation.informationPanel.subTitle": "Create a password to finish setting up your account.",
  "accountActivation.informationPanel.description": "You'll use this password every time you visit your account or log in to the Stroll app.",
  "accountActivation.description": "Create a password to finish setting up your account. You'll use this password every time you visit your account or log in to the Stroll app.",
  "accountActivation.sessionExpired.error.message": "Your session has expired, please refresh the page and try again.",
  "accountSettings.title": "Account Settings",
  "accountSettings.header.phoneNumber": "Mobile Number",
  "accountSettings.header.emailAddress": "Email Address",
  "accountSettings.header.password": "Password",
  "accountSettings.form.edit": "Edit",
  "accountSettings.form.cancel": "Cancel",
  "accountSettings.form.save": "Save",
  "accountSettings.form.sendCode": "Send Code",
  "accountSettings.form.verify": "Verify",
  "accountSettings.form.resendCode": "Resend Code",
  "accountSettings.form.codeSentSuccess": "We sent your code to: {destination}",
  "accountSettings.form.invalidPhoneNumber": "Invalid mobile number.",
  "accountSettings.form.invalidEmail": "The email address you entered is invalid.",
  "accountSettings.form.invalidConfirmationCode": "Invalid confirmation code, please try again.",
  "accountSettings.form.genericUpdateFailure": "Something went wrong, please try again.",
  "accountSettings.form.emailAlreadyExists": "An account with the given email already exists.",
  "accountSettings.form.tooManyAttempts": "Too many attempts. Try again soon.",
  "accountSettings.form.confirmationCodeExpired": "Sorry, that code has expired. Please try again.",
  "accountSettings.form.confirmationCode.placeholder": "Confirmation Code",
  "accountSettings.form.phoneNumberUpdated": "Success! Your mobile number has been updated.",
  "accountSettings.form.emailUpdated": "Success! Your email address has been updated.",
  "accountSettings.form.passwordUpdated": "Success! Your password has been updated.",
  "accountSettings.form.newPassword": "New Password",
  "accountSettings.form.currentPassword": "Current Password",
  "accountSettings.form.confirmPassword": "Confirm Password",
  "accountSettings.form.error.title": "Your password must contain 1 of each:",
  "accountSettings.form.invalidPassword": "Password cannot be the same as the username",
  "accountSettings.form.error.password.format.title": "Your password must contain 1 of each:",
  "accountSettings.form.error.password.specialCharacter": "Special character / symbol",
  "accountSettings.form.error.password.upperCaseLetter": "Uppercase letter",
  "accountSettings.form.error.password.number": "Number",
  "accountSettings.form.error.password.minimumLength": "Minimum length: 8 characters",
  "accountSettings.form.error.password.emailAddress": "Password cannot contain email address",
  "accountSettings.header.marketingPreferences": "Your Marketing Preferences",
  "accountSettings.marketingPreferences.content": "From time to time, we send out some epic updates reminding you about the other great stuff Stroll can do, along with handy hints and tips for all things driving-related.",
  "accountSettings.marketingPreferences.email.text": "I’m happy to receive updates via Email",
  "accountSettings.marketingPreferences.sms.text": "I’m happy to receive updates via SMS",
  "accountSettings.marketingPreferences.updated": "Success! Your preferences have been saved.",
  "accountSettings.marketingPreferences.savePreferences": "Save Preferences",
  "accountAlreadyActivated.title": "It looks like your account has already been created.",
  "accountAlreadyActivated.pleaseLogin": "Please login to view your account.",
  "claims.form.policy": "Policy",
  "claims.form.dateOfIncident": "Date of incident",
  "claims.form.callMeOn": "Call me on",
  "claims.form.claimsTeamHelp": "Please include any info you think is relevant including the incident location, time and if  any other parties were involved. Our Claims partner, Customer Claims Assist, will get back to you.",
  "claims.form.claimDetails": "Claims Details",
  "claims.form.tellUsWhatHappened": "Tell us what happened...",
  "claims.form.requestCallback": "Request Callback",
  "claims.form.invalidPhoneNumber": "Whoops, that number doesn't look right. Give it another go.",
  "claims.form.invalidDate.beforeOriginalPolicyStartDate": "Whoops, that doesn't look right. You can't enter a date before your original policy start date.",
  "claims.form.invalidDate.beforeSelectedPolicyStartDate": "Whoops, that doesn't look right. You can't enter a date before the cover start date of your selected policy.",
  "claims.form.invalidDate.afterSelectedPolicyEndDate": "Whoops, that doesn't look right. You can't enter a date after the cover end date of your selected policy.",
  "claims.form.genericFailure": "Something went wrong, please try again.",
  "claims.form.requestSubmitted.title": "Your request has been submitted!",
  "claims.form.requestSubmitted.description": "Our team will be in touch with you shortly.",
  "claims.form.processingMessage": "You are unable to submit a claim as your policy is still being processed.\nPlease try again later.",
  "quote.sidebar.navigation.title": "Quote Summary",
  "quote.header.modal.logIn": "Log in",
  "quote.header.modal.retrieveQuote": "View saved quotes",
  "quote.header.modal.retrieveQuote.emailAddress": "Email Address",
  "quote.header.modal.retrieveQuote.description": "If you have saved a quote in the last 30 days, enter your email address and we will send you your most recent quote.",
  "quote.header.modal.retrieveQuote.footer.cancel": "Cancel",
  "quote.header.modal.retrieveQuote.footer.sendLink": "Send link",
  "quote.header.modal.retrieveQuote.checkYourEmails.title": "Check your emails",
  "quote.header.modal.retrieveQuote.checkYourEmails.description": "We’ve just sent you instructions to {emailAddress} on how to retrieve your most recent quote. Check your junk folder too, in case the email appears there.",
  "quote.errors.questionIncomplete": "You need to fill this in first.",
  "quoteJourney.shared.insuranceDeclinedQuestion.stop.title": "Hey, we just need more info about this to continue.",
  "quoteJourney.shared.nonMotoringConvictions.stop.title": "Hey, we just need more info about this non-motoring offence to continue.",
  "quoteJourney.shared.licenceCountryOfIssueQuestion.stop.title": "As your driving licence was issued outside of the UK/Ireland, we need to check a few things before we can give you a quote. Please call us on 08000 11 31 21 (we're here Mon-Fri 9am to 5pm).",
  "quoteJourney.shared.mainDriverQuestion.stop.title": "Hey, to get a quote with this driver as the main user on your policy, we need a few more details.",
  "quoteJourney.shared.convictionsQuestions.otherConvictionType.stop.title": "Hey, we need a bit more info from you.",
  "quoteJourney.shared.medicalCondition.softStop.title": "Hey, we just need more info about these DVLA notifications.",
  "quoteJourney.shared.softStop.description": "Get in touch with the Stroll team and we will walk you through the rest of your quote.",
  "quoteJourney.shared.nonMotoringConvictionsQuestion.tooltip.description": "A non-motoring offence is one that is not related to driving at all. You should also include any offences that are still pending.",
  "vehicleQuote.promptOnExit": "Are you sure your want to leave? Your quote info will be lost.",
  "vehicleQuote.title": "Your Car Details",
  "vehicleQuote.footerButton": "Add Your Details",
  "vehicleQuote.softStop.title": "We're unable to proceed with your quote online",
  "vehicleQuote.softStop.description": "Please get in touch for more information.",
  "vehicleQuote.softStop.dismiss": "Dismiss",
  "vehicleQuote.softStop.button.primary": "Get In Touch",
  "vehicleQuote.softStop.button.secondary": "I have nothing left to add",
  "vehicleQuote.hardStop.title": "We cannot proceed with your quote",
  "vehicleQuote.registrationLookup.questionTitle": "Search for your vehicle",
  "vehicleQuote.registrationLookup.placeholder": "e.g. STR 0111",
  "vehicleQuote.registrationLookup.submitButton": "Find My Car",
  "vehicleQuote.registrationLookup.errors.validation.qRegistration": "Hey, we don't currently insure cars with a Q-reg plate.",
  "vehicleQuote.registrationLookup.errors.validation.vehicleIsInvalid": "The vehicle details we received were incomplete. This might be due to the trial vehicle details service in use -- only registrations with the letter 'A' are allowed.",
  "vehicleQuote.registrationLookup.errors.validation.commercialVehicle": "Hey, that looks like a commercial vehicle. We only insure private cars at this time.",
  "vehicleQuote.registrationLookup.errors.validation.invalidRegistrationNumber": "Whoops! Your car reg needs to be ABC 1234 or AB12 ABC format.",
  "vehicleQuote.registrationLookup.errors.validation.invalidRegistrationNumber.isleOfManRegistration": "Hey, we don't currently insure cars that are registered in the Isle of Man.\nChecking out a different car? Make sure you come back and search again.",
  "vehicleQuote.registrationLookup.errors.validation.prohibitedFuelType": "Hey, that looks like a car with a LPG fuel type. We don't currently insure these.",
  "vehicleQuote.registrationLookup.errors.query.VEHICLE_REGISTRATION_INVALID": "Whoops! Your car reg needs to be in ABC 1234 or AB12 ABC format.",
  "vehicleQuote.registrationLookup.errors.query.VEHICLE_REGISTRATION_NOT_FOUND": "Whoops, we can’t seem to find that car. Give it another go or click Search by Make & Model instead.",
  "vehicleQuote.registrationLookup.errors.query.VEHICLE_INFORMATION_INCOMPLETE": "Whoops, we can’t seem to find that car. Give it another go or click Search by Make & Model instead.",
  "vehicleQuote.registrationLookup.errors.query.SERVICE_UNAVAILABLE": "Whoops, we can’t seem to find that car. Give it another go or click Search by Make & Model instead.",
  "vehicleQuote.registrationLookup.errors.query.SANDBOX_LIMITATION": "TBD - Try a registration with 'A' in it - this is a development only limitation",
  "vehicleQuote.registrationLookup.errors.query.GENERIC_ERROR": "Oops, it looks like this isn’t working at the moment.  Please search for your car manually.",
  "vehicleQuote.registrationLookup.errors.query.UNSUCCESSFUL_RESPONSE_CODE": "We are having issues finding info for your registration, please click Search by Make & Model to manually enter your car's details.",
  "vehicleQuote.vehicleConfirmation.questionTitle": "Your Car",
  "vehicleQuote.vehicleConfirmation.rejectButton": "Not My Car",
  "vehicleQuote.manualVehicleLookup.manualButton": "Search by Make & Model",
  "vehicleQuote.manualVehicleLookup.make": "What make is your car?",
  "vehicleQuote.manualVehicleLookup.make.typeahead.placeholder": "Select an option...",
  "vehicleQuote.manualVehicleLookup.model": "Which model is it?",
  "vehicleQuote.manualVehicleLookup.model.typeahead.placeholder": "Select an option...",
  "vehicleQuote.manualVehicleLookup.fuelType": "And what's the fuel type?",
  "vehicleQuote.manualVehicleLookup.fuelType.stop.title": "Hey, that looks like a car with a LPG fuel type. We don't currently insure these.",
  "vehicleQuote.manualVehicleLookup.transmissionType": "What is the transmission type?",
  "vehicleQuote.manualVehicleLookup.yearOfManufacture": "What year was the car manufactured?",
  "vehicleQuote.manualVehicleLookup.yearOfManufacture.tooltip.title": "Not sure when your car was manufactured?",
  "vehicleQuote.manualVehicleLookup.yearOfManufacture.tooltip.description": "If you're looking for this info, you'll find it in your vehicle registration certificate (sometimes called the V5C log book).",
  "vehicleQuote.manualVehicleLookup.yearOfManufacture.stop.title": "Hey, as we only insure cars from 1980 onwards, we cannot give you a quote online today.",
  "vehicleQuote.manualVehicleLookup.engineCapacity": "How big is the engine?",
  "vehicleQuote.manualVehicleLookup.trimLevel": "Specific model / Model type",
  "vehicleQuote.manualVehicleLookup.fullSpec": "Which of these cars is yours?",
  "vehicleQuote.manualVehicleLookup.errors.makesFetchFailed": "Error fetching makes",
  "vehicleQuote.manualVehicleLookup.errors.modelsFetchFailed": "Error fetching models",
  "vehicleQuote.manualVehicleLookup.errors.fuelTypesFetchFailed": "Error fetching fuel types",
  "vehicleQuote.manualVehicleLookup.errors.transmissionTypesFetchFailed": "Error fetching transmission types",
  "vehicleQuote.manualVehicleLookup.errors.yearOfManufactureFetchFailed": "Error fetching years of manufacture",
  "vehicleQuote.manualVehicleLookup.errors.engineCapacityLitresFetchFailed": "Error fetching engine capacities",
  "vehicleQuote.manualVehicleLookup.errors.trimLevelsFetchFailed": "Error fetching model types",
  "vehicleQuote.manualVehicleLookup.errors.fullSpecsFetchFailed": "Error fetching vehicle specs",
  "vehicleQuote.vehicleAssumptions.listTitle": "We've made these assumptions about your car:",
  "vehicleQuote.vehicleAssumptions.questionTitle": "Are these details correct?",
  "vehicleQuote.assumptions.listItems.driverPosition": "<li>Is {driverPosition} hand drive</li>",
  "vehicleQuote.assumptions.listItems.seats": "<li>Has {seats} seats</li>",
  "vehicleQuote.assumptions.listItems.trackerDevice": "<li>{hasTracker} tracker device fitted</li>",
  "vehicleQuote.assumptions.listItems.hasTracker": "<span>Has a</span>",
  "vehicleQuote.assumptions.listItems.hasNoTracker": "<span>Has <b>no</b></span>",
  "vehicleQuote.assumptions.listItems.alarm": "<li>The alarm was fitted by {alarmInstaller}</li>",
  "vehicleQuote.assumptions.listItems.alarm.unknown": "<li>You don't know who fitted your alarm</li>",
  "vehicleQuote.assumptions.listItems.alarm.notFitted": "<li>Your vehicle isn't fitted with an alarm</li>",
  "vehicleQuote.assumptions.listItems.imported": "Is {imported} imported",
  "vehicleQuote.assumptions.listItems.imported.nonImported": "not",
  "vehicleQuote.assumptions.listItems.importType": "<span> from {importType} the EU</span>",
  "vehicleQuote.assumptions.listItems.importType.eu": "within",
  "vehicleQuote.assumptions.listItems.importType.nonEu": "outside",
  "vehicleQuote.assumptions.listItems.estimatedValue": "<li>Is worth {estimatedValue}</li>",
  "vehicleQuote.additionalVehicleDetailsForm.title": "Your car details",
  "vehicleQuote.additionalVehicleDetailsForm.driverPosition": "Is your car left or right hand drive?",
  "vehicleQuote.additionalVehicleDetailsForm.driverPosition.stop.title": "Hey, as your car is a left hand drive we are unable to give you a quote online today as we need some more info.",
  "vehicleQuote.additionalVehicleDetailsForm.seats": "How many seats does it have?",
  "vehicleQuote.additionalVehicleDetailsForm.seats.placeholder": "4",
  "vehicleQuote.additionalVehicleDetailsForm.seats.errors.belowMinimum": "Hey, we don't currently insure cars with less than 2 seats.",
  "vehicleQuote.additionalVehicleDetailsForm.seats.errors.aboveMaximum": "Hey, we don't currently insure cars with more than 7 seats.",
  "vehicleQuote.additionalVehicleDetailsForm.hasTracker": "Does it have a tracking device fitted?",
  "vehicleQuote.additionalVehicleDetailsForm.hasAlarm": "Does it have an alarm fitted?",
  "vehicleQuote.additionalVehicleDetailsForm.alarmInstaller": "Who fitted the alarm?",
  "vehicleQuote.additionalVehicleDetailsForm.imported": "Has your car been imported?",
  "vehicleQuote.additionalVehicleDetailsForm.imported.tooltip.title": "Not sure if your car is imported?",
  "vehicleQuote.additionalVehicleDetailsForm.imported.tooltip.description": "Your car is considered imported if it was registered in another country and has since been re-registered in the UK.",
  "vehicleQuote.additionalVehicleDetailsForm.importType": "What type of import is your car?",
  "vehicleQuote.additionalVehicleDetailsForm.importType.eu.stop.title": "Hey, as your car is an EU import we are unable to give you a quote online today as we need some more info.",
  "vehicleQuote.additionalVehicleDetailsForm.importType.nonEu.stop.title": "Hey, as your car is a Non-EU import we are unable to give you a quote online today as we don't insure them.",
  "vehicleQuote.additionalVehicleDetailsForm.estimatedValue": "What is the current value of your car?",
  "vehicleQuote.additionalVehicleDetailsForm.estimatedValue.tooltip.title": "Not sure what your car is worth?",
  "vehicleQuote.additionalVehicleDetailsForm.estimatedValue.tooltip.description": "Don’t worry if you’re not sure exactly how much your car is worth at the moment, an approximate value will do. Remember, in the event of a claim, your insurer will typically pay the market value of your car.",
  "vehicleQuote.additionalVehicleDetailsForm.submitButton.confirmed": "Confirmed",
  "vehicleQuote.additionalVehicleDetailsForm.submitButton.unconfirmed": "Confirm",
  "vehicleQuote.trackerQuestion": "Does it have a tracking device fitted?",
  "vehicleQuote.modifications.title": "Has your car been modified?",
  "vehicleQuote.modifications.tooltip.description": "We need to know if you or a previous owner has modified the car for performance or cosmetic reasons such as alloy wheels, suspensions, spoilers or tinted windows.",
  "vehicleQuote.modifications.tooManyModificationsStop.title": "Hey, as your car has more modifications we are unable to give you a quote online today as we need some more info.",
  "vehicleQuote.modifications.selection.title": "Which modification?",
  "vehicleQuote.modifications.selection.options.alloyWheels": "Alloy Wheels",
  "vehicleQuote.modifications.selection.options.windowTint": "Window Tint",
  "vehicleQuote.modifications.selection.options.other": "Other",
  "vehicleQuote.modifications.selection.options.other.stop.title": "Hey, as your car has some modifications we are unable to give you a quote online today as we need some more info.",
  "vehicleQuote.modifications.alloyWheelsQuestion.title": "You’ll need to tell us a little more info",
  "vehicleQuote.modifications.alloyWheelsQuestion.options.lessThan2Inches": "Less than 2\" over default",
  "vehicleQuote.modifications.alloyWheelsQuestion.options.moreThan2Inches": "More than 2\" over default",
  "vehicleQuote.modifications.alloyWheelsQuestion.options.moreThan2Inches.stop.title": "Hey, as your car has alloy wheel modifications that are over 2 inches greater in size than the manufacturers standard fit we are unable to give you a quote online today.",
  "vehicleQuote.modifications.windowTintQuestion.title": "You’ll need to tell us a little more info",
  "vehicleQuote.modifications.windowTintQuestion.options.fullVehicle": "Full car",
  "vehicleQuote.modifications.windowTintQuestion.options.fullVehicle.stop.title": "Hey, as your car has window tint modifications that have been fitted to all windows we are unable to give you a quote online today.",
  "vehicleQuote.modifications.windowTintQuestion.options.rearOfVehicle": "Rear of car",
  "vehicleQuote.haveYouBoughtTheCarYetQuestion.title": "Have you bought the car yet?",
  "vehicleQuote.haveYouBoughtTheCarYetQuestion.tooltip.description": "Don't worry if you don't have the car yet, you can still get an insurance quote for the make and model you've been looking at.",
  "vehicleQuote.haveYouBoughtTheCarYetQuestion.purchaseDate.title": "When did you buy the car?",
  "vehicleQuote.haveYouBoughtTheCarYetQuestion.purchaseDate.invalidYearOfManufacture": "Whoops, the manufacturing date for your car doesn't look right, give it another go.",
  "vehicleQuote.haveYouBoughtTheCarYetQuestion.purchaseDate.beforeItWasManufactured": "Whoops, you've entered a purchase date before your car was manufactured.",
  "vehicleQuote.haveYouBoughtTheCarYetQuestion.purchaseDate.afterToday": "Oh, it looks like you've entered a future date. If you're planning to buy your car from the date you've entered or if you're just browsing for prices, click No to this question. We can still quote you.",
  "vehicleQuote.haveYouBoughtTheCarYetQuestion.purchaseDate.afterCoverStartDate": "Oh, it looks like that date is after the cover date you’ve selected. You must be the owner of your car before your policy starts.",
  "vehicleQuote.ownerOrRegisteredKeeperQuestion.title": "Are you (or will you be) the owner and registered keeper of the car?",
  "vehicleQuote.ownerOrRegisteredKeeperQuestion.description": "You are the registered keeper if the V5C log book is in your name. You are also the owner if you bought the car or if it was gifted to you.",
  "vehicleQuote.ownerQuestion.title": "Who is the owner of the car?",
  "vehicleQuote.ownerQuestion.tooltip.description": "The owner of the car is the person who bought it or the person who received it as a gift. This may also be a leasing company* (*there must be a minimum of 12 months left on the agreement).",
  "vehicleQuote.ownerQuestion.stop.title": "Hey, we can only insure this car if it is owned by you, your spouse or if your car is on a leasing agreement with more than 12 months left.",
  "vehicleQuote.ownerQuestion.stop.description": "If someone else owns it, please get in touch with the Stroll Team.",
  "vehicleQuote.registeredKeeperQuestion.title": "Who is the registered keeper of the car?",
  "vehicleQuote.registeredKeeperQuestion.tooltip.description": "The registered keeper is the person named on the V5C log book and is the person who keeps the car on a day-to-day basis.",
  "vehicleQuote.registeredKeeperQuestion.stop.title": "Hey, we can only insure this car if you or your spouse is the registered keeper of the car as noted on the V5C log book.",
  "vehicleQuote.registeredKeeperQuestion.stop.description": "If someone else is the keeper, please get in touch with the Stroll Team.",
  "personalDetails.title": "Your Details",
  "personalDetails.footerButton": "Proceed to Your Cover",
  "personalDetails.titleQuestion": "What's your title?",
  "personalDetails.titleDropdownPlaceholder": "Make a selection",
  "personalDetails.firstNameQuestion": "What's your first name?",
  "personalDetails.firstNameQuestion.placeholder": "Joe",
  "personalDetails.firstNameQuestion.errors.invalidEntry": "Whoops, that doesn't look right. Please enter a valid first name.",
  "personalDetails.surnameQuestion": "And your surname?",
  "personalDetails.surnameQuestion.placeholder": "Bloggs",
  "personalDetails.surnameQuestion.errors.invalidEntry": "Whoops, that doesn't look right. Please enter a valid surname.",
  "personalDetails.dateOfBirthQuestion.title": "What's your date of birth?",
  "personalDetails.dateOfBirthQuestion.errors.isTooYoung.before": "Whoops! This driver must be 17 or above to get a quote and Stroll with us.",
  "personalDetails.dateOfBirthQuestion.errors.isTooYoung.onStartDate": "Whoops, you must be 17 or above on your cover start date to get a quote with us.",
  "personalDetails.dateOfBirthQuestion.errors.isTooYoung.forResidencyDuration": "Whoops, you can't have been a UK resident for {residencyDuration} years if you are {age} years old.",
  "personalDetails.dateOfBirthQuestion.errors.isTooYoung.toBeAHomeowner": "Whoops, you can't be a homeowner if you are only {age} years old.",
  "personalDetails.dateOfBirthQuestion.errors.isTooOld": "Whoops! This driver must be 95 or below to get a quote and Stroll with us.",
  "personalDetails.dateOfBirthQuestion.errors.isTooOld.onStartDate": "Whoops, you must be 95 or below on your cover start date to get a quote with us.",
  "personalDetails.classOfUseQuestion": "What do you use the car for?",
  "personalDetails.classOfUseQuestion.tooltip.title": "Why do we need to know this?",
  "personalDetails.classOfUseQuestion.tooltip.description": "We need to know how much you use your car and the risk associated with it.\n" + "Choose the option which best suits you: \n\n" + "<ul>" + "<li><b>Social only</b> covers day-to-day driving such as visiting family and friends or going shopping. It does not include driving to and from work.</li>" + "<li><b>Social and commuting</b> covers everyday driving, as well as your commute to a permanent place of work.</li>" + "<li><b>Social, commuting and for business</b> covers all of the above plus using your vehicle in connection with work beyond just commuting.</li>" + "</ul>",
  "personalDetails.classOfUseQuestion.errors.nonCommutingEmploymentStatus": "Whoops, you can't be commuting while not in employment.",
  "personalDetails.classOfUseQuestion.errors.nonBusinessEmploymentStatus": "Whoops, that doesn't look right. You can't choose \"Social, Commuting & Business\" with your employment status selection.",
  "personalDetails.solicitingSubQuestion.title": "Do you use the car to carry goods, products or provide a service?",
  "personalDetails.phoneNumberQuestion": "What's your mobile number?",
  "personalDetails.phoneNumberQuestion.placeholder": "789456123",
  "personalDetails.phoneNumberQuestion.tooltip": "Your mobile number is the best way for us to keep in contact with you about your quote and policy. Don't worry, we aren't into spamming.",
  "personalDetails.emailAddressQuestion": "What's your email address?",
  "personalDetails.emailAddressQuestion.tooltip": "Don’t worry, we won't spam you. We just need your email address to send a copy of your quote for handiness.",
  "personalDetails.phoneNumberQuestion.errors.invalidPhoneNumber": "Whoops, that number doesn't look right. Give it another go.",
  "personalDetails.storedAtNightQuestion.title": "Where do you keep your car at night?",
  "personalDetails.storedAtNightQuestion.placeholder": "Make a selection",
  "personalDetails.storedAtNightQuestion.tooltip.title": "Why do we need to know this?",
  "personalDetails.storedAtNightQuestion.tooltip.description": "For your insurance to be valid, we need to know if this is the address your car is most regularly stored at overnight.",
  "personalDetails.isHomeownerQuestion.title": "Are you a homeowner?",
  "personalDetails.isHomeownerQuestion.tooltip": "Some insurers may give you a discount if you own your home outright or have a mortgage on a property.",
  "personalDetails.addressQuestion": "Find your address",
  "personalDetails.addressQuestion.tooltip.description": "If your address doesn't appear in the list, please get in touch with us.",
  "personalDetails.addressQuestion.titleWhenSet": "Your address",
  "personalDetails.addressQuestion.placeholder": "e.g. 45 Beechill Crescent",
  "personalDetails.addressQuestion.loading": "Loading addresses...",
  "personalDetails.addressQuestion.errors.queryError": "Whoops, we can't recognise that address, give it another go or contact us. If you're currently residing outside of Northern Ireland, we aren't strollin' in your area just yet.",
  "personalDetails.addressQuestion.errors.validationError": "We are Strolling our way through the UK and will be in your area shortly.",
  "personalDetails.isCarKeptAtHomeQuestion.title": "Is the car kept at the above address?",
  "personalDetails.isCarKeptAtHomeQuestion.tooltip.description": "For your insurance to be valid, we need to know what address your car is kept at.",
  "personalDetails.isCarKeptAtHomeQuestion.stop.title": "Hey, we just need more info about where you keep your car to complete your quote.",
  "personalDetails.genderQuestion": "What's your gender?",
  "personalDetails.genderQuestion.tooltip.description": "We know that some people don’t identify with a particular gender. For now, simply refer to the sex/gender that is on your birth certificate or gender recognition certificate.",
  "personalDetails.licenceCountryOfIssueQuestion": "Where was your licence issued?",
  "personalDetails.licenceCountryOfIssueQuestion.answer.uk": "United Kingdom",
  "personalDetails.licenceCountryOfIssueQuestion.answer.roi": "Republic of Ireland",
  "personalDetails.licenceCountryOfIssueQuestion.answer.other": "Other",
  "personalDetails.licenceCountryOfIssueQuestion.roiLicenceMessage": "If you're an ROI licence holder, you may need to provide us with a proof of address. This will need to be a copy of a utility bill or bank statement within the last three months that matches the address you are quoting for.",
  "personalDetails.licenceTypeQuestion": "What type of licence do you have?",
  "personalDetails.licenceDurationQuestion": "How long have you held your licence?",
  "personalDetails.licenceDurationQuestion.tooltip.description": "You can find this info on your driving licence. We need to know how long it has been since you have passed your driving test and received your current licence. \n" + "\nIf you haven't passed your test yet, this needs to be the number of years/months since you received your provisional licence.",
  "personalDetails.maritalStatusQuestion": "What's your relationship status?",
  "personalDetails.maritalStatusQuestion.additionalDriverIncompatibleStop.title": "Your marital status is not compatible with your relationship to one of your additional drivers.",
  "personalDetails.maritalStatusQuestion.IncompatibleAnswerStop.reset": "Clear",
  "personalDetails.maritalStatusQuestion.ownerIncompatible.title": "Whoops, that doesn't look right. You've let us know that your spouse is the owner of the car but the marital status you've chosen doesn't match.",
  "personalDetails.maritalStatusQuestion.keeperIncompatible.title": "Whoops, that doesn't look right. You've let us know that your spouse is the registered keeper of the car but the marital status you've chosen doesn't match.",
  "personalDetails.maritalStatusQuestion.ownerIncompatible.description": "Please correct the ownership or change this answer.",
  "personalDetails.maritalStatusQuestion.keeperIncompatible.description": "Please correct the registered keeper or change this answer.",
  "personalDetails.maritalStatusQuestion.dropdownPlaceholder": "e.g. Single",
  "personalDetails.maritalStatusQuestion.tooltip.description": "We need to know this because your relationship status could be a contributing factor in getting you an honest price. <semiBold>Remember, if you live with your partner but you're not married, please select \"Married common law\".</semiBold>",
  "personalDetails.primaryEmploymentQuestion": "What's your employment status?",
  "personalDetails.primaryEmploymentQuestion.dropdownPlaceholder": "Make a selection",
  "personalDetails.primaryEmploymentQuestion.employed.industry.title": "What industry do you work in?",
  "personalDetails.primaryEmploymentQuestion.employed.industry.tooltip.title": "Why do we need to know this?",
  "personalDetails.primaryEmploymentQuestion.employed.industry.tooltip.description": "We use lots of information to work out the price of your car insurance and sometimes the type of job you do can affect this.",
  "personalDetails.primaryEmploymentQuestion.employed.job.title": "What do you do for a living?",
  "personalDetails.primaryEmploymentQuestion.employed.job.tooltip.description": "If you can't find an exact match, don't worry, just use the description that best describes what you do.",
  "personalDetails.primaryEmploymentQuestion.unemployed.hasWorkedInPastYear.title": "Have you worked in the past year?",
  "personalDetails.primaryEmploymentQuestion.unemployed.previousJob.title": "What did you do before you became unemployed?",
  "personalDetails.primaryEmploymentQuestion.unemployed.previousJob.tooltip.description": "If you can't find an exact match, don't worry, just use the description that best describes what you did.",
  "personalDetails.primaryEmploymentQuestion.retired.previousJob.title": "What did you do before you retired?",
  "personalDetails.primaryEmploymentQuestion.retired.previousJob.tooltip.description": "If you can't find an exact match, don't worry, just use the description that best describes what you did.",
  "personalDetails.secondaryEmploymentQuestion": "Do you have a second or part time job?",
  "personalDetails.secondaryEmploymentQuestion.tooltip.title": "What counts as a second job?",
  "personalDetails.secondaryEmploymentQuestion.tooltip.description": "If you're a student but you also do some paid work, please select \"Yes\" and let us know what it is.\n\n" + "If you're in full time employment and have a second job select \"Yes\" and let us know what it is.\n\n" + "If you're a volunteer, please select \"Yes\" too.",
  "personalDetails.secondaryEmploymentQuestion.employmentStatus.title": "What's the status of your secondary occupation?",
  "personalDetails.secondaryEmploymentQuestion.employmentStatus.placeholder": "Make a selection",
  "personalDetails.secondaryEmploymentQuestion.employed.industry.title": "What industry do you work in for your second job?",
  "personalDetails.secondaryEmploymentQuestion.employed.industry.tooltip.title": "Why do we need to know this?",
  "personalDetails.secondaryEmploymentQuestion.employed.industry.tooltip.description": "We use lots of information to work out the price of your car insurance and sometimes the type of job you do can affect this.",
  "personalDetails.secondaryEmploymentQuestion.employed.job.title": "What do you do for your second job?",
  "personalDetails.secondaryEmploymentQuestion.employed.job.tooltip.description": "If you can't find an exact match, don't worry, just use the description that best describes what you do.",
  "personalDetails.secondaryEmploymentQuestion.unemployed.hasWorkedInPastYear.title": "Have you worked in the past year?",
  "personalDetails.secondaryEmploymentQuestion.unemployed.previousJob.title": "What did you do before you became unemployed?",
  "personalDetails.secondaryEmploymentQuestion.unemployed.previousJob.tooltip.description": "If you can't find an exact match, don't worry, just use the description that best describes what you do.",
  "personalDetails.secondaryEmploymentQuestion.retired.previousJob.title": "What did you do before you retired?",
  "personalDetails.secondaryEmploymentQuestion.retired.previousJob.tooltip.description": "If you can't find an exact match, don't worry, just use the description that best describes what you did.",
  "personalDetails.employmentQuestion.employed.industry.placeholder": "e.g. Aerospace",
  "personalDetails.employmentQuestion.employed.job.placeholder": "e.g. Astronaut",
  "personalDetails.employmentQuestion.unemployed.previousJob.placeholder": "e.g. Astronaut",
  "personalDetails.employmentQuestion.retired.previousJob.placeholder": "e.g. Teacher",
  "personalDetails.hasMedicalCondition": "Do you have any medical conditions or disabilities that the DVLA needs to be notified of?",
  "personalDetails.hasMedicalConditionQuestion.tooltip.description": "There are some medical conditions which may affect your driving and therefore need to be notified to the DVLA.  If you have a DVLA notifiable medical condition, you will need to let us know if there have been any licence restrictions applied by them.",
  "personalDetails.medicalCondition": "Please select which restrictions apply.",
  "personalDetails.residency.fromBirth": "Have you continuously lived in the UK from birth?",
  "personalDetails.residency.fromBirth.tooltip": "You're classed as continuously living in the UK since birth if you have not left the country for more than a year. If you have been away for more than a year, count the amount of years you've returned.",
  "personalDetails.residency.duration": "How long have you continuously lived in the UK?",
  "personalDetails.residency.duration.stop.title": "Hey, we just need more info about your residency status.",
  "personalDetails.claims.stops.claimsLimit.title": "Hey, as you have more than five claims we can't quote you online today.",
  "personalDetails.claims.hasClaims.title": "Have you had any motor accidents, claims or losses in the past 5 years?",
  "personalDetails.claims.hasClaims.description": "We'll need to know no matter who was at fault or if a claim was made. You can add up to a maximum of 5 claims.",
  "personalDetails.claims.hasClaims.tooltip.description": "To make sure we provide you with an accurate quote, it is important that you let us know your previous driving history.\n\n" + "Remember, you will need to let us know if you have had any accidents, claims or losses whilst driving someone else's vehicle, not just your own.",
  "personalDetails.claims.date.title": "What date was the incident?",
  "personalDetails.claims.date.tooltip.title": "Not sure of the date?",
  "personalDetails.claims.date.tooltip.description": "Take a moment to look up any paperwork or emails you might have relating to the incident. We don't need to know about any claims over 5 years old.",
  "personalDetails.claims.date.error.inFuture": "Whoops, that doesn't look right. You can't enter a date in the future.",
  "personalDetails.claims.date.error.overFiveYearsInPast": "We don't need to know about claims that happened more than 5 years ago.",
  "personalDetails.claims.status.title": "Is the claim settled or pending?",
  "personalDetails.claims.status.tooltip.title": "Not sure?",
  "personalDetails.claims.status.tooltip.description": "You will know if a claim is settled if all costs have been paid out by, or recouped by, the policyholder's insurer and that you, the policyholder, have been paid back your excess and any injury claims that have been fully paid out.",
  "personalDetails.claims.type.title": "What type of incident did you have?",
  "personalDetails.claims.faultStatus.title": "Was the accident deemed your fault?",
  "personalDetails.claims.faultStatus.tooltip.title": "Not sure who was at fault?",
  "personalDetails.claims.faultStatus.tooltip.description": "Your previous insurer will be able to inform you of this. Take a moment to look up any paperwork or emails you might have relating to the incident.",
  "personalDetails.claims.accidentDescription.title": "What happened in the accident?",
  "personalDetails.claims.claimedPolicy.title": "Which policy were you driving?",
  "personalDetails.claims.claimedPolicy.tooltip.description": "We need to know if this incident was against your own insurance policy or while you were driving on another policy.",
  "personalDetails.claims.noClaimsAffected.title": "Was your No Claims Bonus affected?",
  "personalDetails.claims.noClaimsAffected.tooltip.title": "Not sure how to find out?",
  "personalDetails.claims.noClaimsAffected.tooltip.description": "Your current insurance provider will tell you how many years of No Claims Bonus you have and whether it was affected as a result of the claim.",
  "personalDetails.claims.injuriesOccurred.title": "Were there any injuries?",
  "personalDetails.claims.cost.title": "What was the cost of the claim?",
  "personalDetails.convictionsQuestions.hasConviction.title": "Have you had any of the following:",
  "personalDetails.convictionsQuestions.hasConviction.description.motoringOrFixedPenalty": "Any motoring related offences or fixed penalty notices in the past 5 years?",
  "personalDetails.convictionsQuestions.hasConviction.description.pendingProsecutions": "Any pending prosecutions?",
  "personalDetails.convictionsQuestions.hasConviction.description.drivingDisqualification": "A driving disqualification?",
  "personalDetails.convictionsQuestions.hasConviction.tooltip.title": "Why do we need to know this?",
  "personalDetails.convictionsQuestions.hasConviction.tooltip.description": "We need to know of any driving offences you have committed such as speeding, careless driving or drink-driving. If you're unsure of the code and what offence you may have, they are recorded against your driving record which can be found on the written part of your licence." + "\n\nDon’t worry if you have attended a speed awareness course or have been issued a parking offence, you don’t need to declare this.",
  "personalDetails.convictionsQuestions.subQuestion.type.title": "What was the offence type?",
  "personalDetails.convictionsQuestions.subQuestion.type.tooltip.description": "If you're not sure of the offence details, you can find the code on the written part of your licence.",
  "personalDetails.convictionsQuestions.subQuestion.type.fetchError": "Sorry, we've failed to retrieve motoring offence types. Give it another go.",
  "personalDetails.convictionsQuestions.subQuestion.offence.title": "What was the offence for?",
  "personalDetails.convictionsQuestions.subQuestion.offence.fetchError": "Sorry, we've failed to retrieve motoring offence types. Give it another go.",
  "personalDetails.convictionsQuestions.subQuestion.date.title": "What date did the offence take place?",
  "personalDetails.convictionsQuestions.subQuestion.date.tooltip.description": "If you're not sure of the exact date, you can find it on the written part of your licence.\n\nIf your motoring offence date is still pending, please contact us.",
  "personalDetails.convictionsQuestions.subQuestion.date.dateInFutureError": "Whoops, that doesn't look right. You can't enter a date in the future.",
  "personalDetails.convictionsQuestions.subQuestion.date.dateOverFiveYearsError": "We don't need to know about this as it happened more than 5 years ago.",
  "personalDetails.convictionsQuestions.subQuestion.receivedPenaltyPoints.title": "Did you receive any penalty points?",
  "personalDetails.convictionsQuestions.subQuestion.convictionPenaltyPoints.title": "How many did you receive?",
  "personalDetails.convictionsQuestions.subQuestion.convictionPenaltyPoints.placeholder": "e.g. 3",
  "personalDetails.convictionsQuestions.subQuestion.receivedFine.title": "Were you fined?",
  "personalDetails.convictionsQuestions.subQuestion.fineAmount.title": "How much were you fined?",
  "personalDetails.convictionsQuestions.subQuestion.fineAmount.placeholder": "e.g. £60",
  "personalDetails.convictionsQuestions.subQuestion.receivedDrivingBan.title": "Did you receive a driving ban?",
  "personalDetails.convictionsQuestions.subQuestion.drivingBanDuration.title": "How long was the ban for?",
  "personalDetails.convictionsQuestions.subQuestion.alcoholTestMethod.title": "What method was used to test for alcohol?",
  "personalDetails.convictionsQuestions.subQuestion.alcoholLevelRecorded.title": "What was the alcohol level recorded?",
  "personalDetails.convictionsQuestions.subQuestion.alcoholLevelRecorded.tooltip.title": "What does this mean?",
  "personalDetails.convictionsQuestions.subQuestion.alcoholLevelRecorded.tooltip.description": "The alcohol in your breath is measured in micrograms. \n" + "The legal limit for drivers in NI is 35 microgrammes per 100 millilitres of breath.",
  "personalDetails.convictionsQuestions.subQuestion.alcoholLevelRecorded.placeholder": "0",
  "personalDetails.convictionsQuestions.tooManyConvictions.stop.title": "Hey, as you have more than five motoring offences we can't quote you online today.",
  "personalDetails.convictionsQuestions.convictionType.drivingWhileUsingMobilePhone": "Driving while using a mobile phone",
  "personalDetails.nonMotoringConvictionsQuestion": "Have you any non-motoring offences?",
  "personalDetails.additionalDrivers.title": "Additional Drivers",
  "personalDetails.additionalDrivers.prefix": "Primary Driver",
  "personalDetails.additionalDrivers.description": "You can add or remove up to 4 drivers and see the impact on price at the end.",
  "personalDetails.additionalDrivers.button": "Add Driver",
  "personalDetails.mainDriver": "Who is the main driver on the policy?",
  "personalDetails.insuranceDeclinedQuestion.title": "Have you ever had insurance declined, cancelled or special terms imposed?",
  "additionalDriver.add.title": "Additional driver",
  "additionalDriver.add.sidebar.title.yourDetails": "Your Details",
  "additionalDriver.add.sidebar.title.refineYourQuote": "Refine Your Quote",
  "additionalDriver.add.sidebar.title.goBack": "Go Back",
  "additionalDriver.add.footer.cancel": "Cancel",
  "additionalDriver.add.footer.add": "Add Driver",
  "additionalDriver.edit.title": "Edit",
  "additionalDriver.edit.sidebar.title.yourDetails": "Your Details",
  "additionalDriver.edit.sidebar.title.refineYourQuote": "Refine Your Quote",
  "additionalDriver.edit.sidebar.title.goBack": "Go Back",
  "additionalDriver.edit.footer.cancel": "Cancel",
  "additionalDriver.edit.footer.save": "Save",
  "additionalDriver.remove.button": "Remove Driver",
  "additionalDriver.remove.modal.title": "Remove Driver",
  "additionalDriver.remove.modal.description": "This action is permanent, once removed all record of this driver will be removed from your quote. ",
  "additionalDriver.remove.modal.buttons.cancel": "Cancel",
  "additionalDriver.remove.modal.buttons.remove": "Remove",
  "additionalDriver.relationshipToProposerQuestion.title": "What's their relationship to the policy holder?",
  "additionalDriver.relationshipToProposerQuestion.placeholder": "Make a selection",
  "additionalDriver.titleQuestion.title": "What's their title?",
  "additionalDriver.firstNameQuestion.errors.invalidEntry": "Whoops, that doesn't look right. Please enter a valid first name.",
  "additionalDriver.firstNameQuestion.title": "What's their first name?",
  "additionalDriver.firstNameQuestion.placeholder": "Joe",
  "additionalDriver.surnameQuestion.title": "And their surname?",
  "additionalDriver.surnameQuestion.errors.invalidEntry": "Whoops, that doesn't look right. Please enter a valid surname.",
  "additionalDriver.surnameQuestion.placeholder": "Bloggs",
  "additionalDriver.dateOfBirthQuestion.title": "What's their date of birth?",
  "additionalDriver.dateOfBirthQuestion.invalid.childDateOfBirthBeforeProposer": "Whoops, that doesn't look right. Your child must be younger than you.",
  "additionalDriver.dateOfBirthQuestion.invalid.grandchildDateOfBirthBeforeProposer": "Whoops, that doesn't look right. Your grandchild must be younger than you.",
  "additionalDriver.dateOfBirthQuestion.invalid.parentDateOfBirthBeforeProposer": "Whoops, that doesn't look right. Your parent can’t be younger than you.",
  "additionalDriver.dateOfBirthQuestion.invalid.grandparentDateOfBirthBeforeProposer": "Whoops, that doesn't look right. Your grandparent must be older than you.",
  "additionalDriver.dateOfBirthQuestion.invalid.licenceDuration": "Whoops! You can't have held your licence for {licenceLength} years as you are only {numberOfYearsOld} years old.",
  "additionalDriver.dateOfBirthQuestion.invalid.licenceDuration.invalidMonths": "Whoops! You can't have held your licence for that long as you're only {years} years and {months} months old.",
  "additionalDriver.householdNumberOfCarsSubQuestion.title": "How many vehicles are at your household?",
  "additionalDriver.householdNumberOfCarsSubQuestion.stop.title": "Hey, we just need a bit more info from you.",
  "additionalDriver.genderQuestion.title": "What's their gender?",
  "additionalDriver.genderQuestion.tooltip.description": "We know that some people don’t identify with a particular gender. For now simply refer to the sex/gender that is on their birth certificate or gender recognition certificate.",
  "additionalDriver.residency.fromBirth": "Have they lived in the UK continuously from birth?",
  "additionalDriver.residency.fromBirth.tooltip": "They're classed as continuously living in the UK since birth if they've not left the country for more than a year. If they have been away for more than a year, count the amount of years they've returned.",
  "additionalDriver.residency.duration": "How long have they continuously lived in the UK?",
  "additionalDriver.residency.duration.stop.title": "Hey, we just need more info about their residency status.",
  "additionalDriver.maritalStatusQuestion": "What's their relationship status?",
  "additionalDriver.maritalStatusQuestion.dropdownPlaceholder": "e.g Single",
  "additionalDriver.maritalStatusQuestion.tooltip.description": "We need to know this because the relationship status of this driver could be a contributing factor in getting you an honest price.",
  "additionalDriver.maritalStatusQuestion.driverRelationshipStatusInvalid": "Whoops, that doesn't look right. Please check the driver's relationship status is compatible with their relationship to you. ",
  "additionalDriver.driverRelationshipStatusQuestion.maritalStatusInvalid.spouse": "Whoops, that doesn't look right. Please check your relationship status previously entered is correct.",
  "additionalDriver.driverRelationshipStatusQuestion.maritalStatusInvalid.moreThanOneSpouse": "Whoops, that doesn't look right. You've already selected another driver as your spouse.",
  "additionalDriver.driverRelationshipStatusQuestion.maritalStatusInvalid.moreThanOneCommonLawSpouse": "Whoops, that doesn't look right. You've already selected another driver as your common law spouse.",
  "additionalDriver.driverRelationshipStatusQuestion.maritalStatusInvalid.moreThanOneCivilPartner": "Whoops, that doesn't look right. You've already selected another driver as your civil partner.",
  "additionalDriver.driverRelationshipStatusQuestion.maritalStatusInvalid.spouse.commonLaw": "Whoops, that doesn't look right. Please check your relationship status previously entered is correct.",
  "additionalDriver.driverRelationshipStatusQuestion.maritalStatusInvalid.civilPartner": "Whoops, that doesn't look right. Please check your relationship status previously entered is correct. ",
  "additionalDriver.primaryEmploymentQuestion": "What's their employment status?",
  "additionalDriver.primaryEmploymentQuestion.dropdownPlaceholder": "Make a selection",
  "additionalDriver.primaryEmploymentQuestion.employed.industry.title": "What industry do they work in?",
  "additionalDriver.primaryEmploymentQuestion.employed.industry.tooltip.title": "Why do we need to know this?",
  "additionalDriver.primaryEmploymentQuestion.employed.industry.tooltip.description": "We use lots of information to work out the price of your car insurance and sometimes the type of job that someone does can affect this.",
  "additionalDriver.primaryEmploymentQuestion.employed.job.title": "What do they do for a living?",
  "additionalDriver.primaryEmploymentQuestion.employed.job.tooltip.description": "If you can't find an exact match, don't worry, just use the description that best describes what this driver does.",
  "additionalDriver.primaryEmploymentQuestion.unemployed.hasWorkedInPastYear.title": "Have they worked in the past year?",
  "additionalDriver.primaryEmploymentQuestion.unemployed.previousJob.title": "What did they do before they became unemployed?",
  "additionalDriver.primaryEmploymentQuestion.unemployed.previousJob.tooltip.description": "If you can't find an exact match, don't worry, just use the description that best describes what this driver did.",
  "additionalDriver.primaryEmploymentQuestion.retired.previousJob.title": "What did they do before they retired?",
  "additionalDriver.primaryEmploymentQuestion.retired.previousJob.tooltip.description": "If you can't find an exact match, don't worry, just use the description that best describes what this driver used to do.",
  "additionalDriver.primaryEmploymentQuestion.invalid.employmentStatus.employee": "Whoops, that doesn't look right. The employment status you have chosen means this driver cannot be your employee.",
  "additionalDriver.primaryEmploymentQuestion.invalid.employmentStatus.employer": "Whoops, that doesn't look right. The employment status you have chosen means this driver cannot be your employer.",
  "additionalDriver.primaryEmploymentQuestion.invalid.employmentStatus.businessPartner": "Whoops, that doesn't look right. The employment status you have chosen means you and this driver couldn't be business partners.",
  "additionalDriver.secondaryEmploymentQuestion": "Do they have a second or part time job?",
  "additionalDriver.secondaryEmploymentQuestion.tooltip.title": "What counts as a second job?",
  "additionalDriver.secondaryEmploymentQuestion.tooltip.description": "If this driver is a student but they also do some paid work, please select \"Yes\" and let us know what it is.\n\n" + "If this driver is in full time employment and has a second job select \"Yes\" and let us know what it is.\n\n" + "If this driver is a volunteer, please select \"Yes\" too.",
  "additionalDriver.secondaryEmploymentQuestion.employmentStatus.title": "What's the status of their secondary occupation?",
  "additionalDriver.secondaryEmploymentQuestion.employmentStatus.placeholder": "Make a selection",
  "additionalDriver.secondaryEmploymentQuestion.employed.industry.title": "What industry do they work in?",
  "additionalDriver.secondaryEmploymentQuestion.employed.industry.tooltip.title": "Why do we need to know this?",
  "additionalDriver.secondaryEmploymentQuestion.employed.industry.tooltip.description": "We use lots of information to work out the price of your car insurance and sometimes the type of job that someone does can affect this.",
  "additionalDriver.secondaryEmploymentQuestion.employed.job.title": "What do they do for a living?",
  "additionalDriver.secondaryEmploymentQuestion.employed.job.tooltip.description": "If you can't find an exact match, don't worry, just use the description that best describes what this driver does.",
  "additionalDriver.secondaryEmploymentQuestion.unemployed.hasWorkedInPastYear.title": "Have they worked in the past year?",
  "additionalDriver.secondaryEmploymentQuestion.unemployed.previousJob.title": "What did they do before they became unemployed?",
  "additionalDriver.secondaryEmploymentQuestion.unemployed.previousJob.tooltip.description": "If you can't find an exact match, don't worry, just use the description that best describes what this driver did.",
  "additionalDriver.secondaryEmploymentQuestion.retired.previousJob.title": "What did they do before they retired?",
  "additionalDriver.secondaryEmploymentQuestion.retired.previousJob.tooltip.description": "If you can't find an exact match, don't worry, just use the description that best describes what this driver used to do.",
  "additionalDriver.classOfUseQuestion": "What will they use the car for?",
  "additionalDriver.classOfUseQuestion.tooltip.title": "Why do we need to know this?",
  "additionalDriver.classOfUseQuestion.tooltip.description": "We need to know how much they use your car and the risk associated with it.\n" + "Choose the option which best suits them:\n\n" + "<ul>" + "<li><b>Social only</b> covers day-to-day driving such as visiting family and friends or going shopping. It does not include driving to and from work.</li>" + "<li><b>Social and commuting</b> covers everyday driving, as well as their commute to a permanent place of work.</li>" + "<li><b>Social, commuting and for business</b> covers all of the above plus using your vehicle in connection with work beyond just commuting.</li>" + "</ul>",
  "additionalDriver.classOfUseQuestion.tooltip.description.forWorkingRelationship": "We need to know how much they use your car and the risk associated with it.\n" + "Choose the option which best suits them:\n\n" + "<ul>" + "<li><b>Social only</b> covers day-to-day driving such as visiting family and friends or going shopping. It does not include driving to and from work.</li>" + "<li><b>Social and commuting</b> covers everyday driving, as well as their commute to a permanent place of work.</li>" + "<li><b>Social and business</b> covers day-to-day driving plus using your vehicle in connection with work. It does not include cover for this driver to drive to and from work.</li>" + "</ul>",
  "additionalDriver.classOfUse.softStop.businessUse.spousalRelationship.student": "Hey! It looks like you don't need business use for your occupation type. Please select if you need social only, or commuting included",
  "additionalDriver.classOfUse.softStop.businessOrCommutingUse.spousalRelationship.unemployed": "Hey! It looks like you don't need business use or commuting use for your occupation type. Please select social only, or let us know if you have an occupation.",
  "additionalDriver.classOfUse.softStop.businessOrCommutingUse.namedDriver": "We can only quote for commuting/business use for this driver if we have a bit more info about their relationship to the policyholder. Please call us on 08000 11 31 21 (Mon-Fri 9am to 5pm).",
  "additionalDriver.classOfUse.softStop.commutingUse.workingRelationship.employed": "Hey! As a work colleague, insurers will only provide cover for the driver for social use only or for business use. Cover cannot be provided to commute to and from work from home.",
  "additionalDriver.classOfUse.softStop.businessOrCommutingUse.workingRelationship.unemployed": "Hey! Due to your occupation status, you can only request cover for social use. If you need business use on this car, please update your occupation and let us know your job info!",
  "additionalDriver.licenceTypeQuestion": "What type of licence do they have?",
  "additionalDriver.licenceCountryOfIssueQuestion": "Where was their licence issued?",
  "additionalDriver.licenceDurationQuestion": "How long have they held their licence?",
  "additionalDriver.licenceDurationQuestion.tooltip.description": "You can find this info on their driving licence. We need to know how long it has been since they have passed their driving test and received their current licence. \n\n" + "If they haven't passed their test yet, this needs to be the number of years/months since they received their provisional licence.",
  "additionalDriver.hasMedicalConditionQuestion": "Do they have any medical conditions or disabilities that the DVLA need to be notified of?",
  "additionalDriver.hasMedicalConditionQuestion.tooltip.description": "There are some medical conditions which may affect someone's driving and therefore needs to be notified to the DVLA. If this driver has a DVLA notifiable medical condition, you will need to let us know if there have been any restrictions applied by them.",
  "additionalDriver.medicalConditionQuestion": "Please select which restrictions apply.",
  "additionalDriver.claims.stops.claimsLimit.title": "Hey, as they have more than five claims we can't quote you online today.",
  "additionalDriver.claims.hasClaims.title": "Have they had any motor accidents, claims or losses in the past 5 years?",
  "additionalDriver.claims.hasClaims.description": "We'll need to know no matter who was at fault or if a claim was made. You can add up to a maximum of 5 claims.",
  "additionalDriver.claims.hasClaims.tooltip.description": "To make sure we provide you with an accurate quote, it is important that you let us know their previous driving history.\n\n" + "Remember, you will need to let us know if they have had any accidents, claims or losses whilst driving someone else's vehicle, not just their own.",
  "additionalDriver.claims.date.title": "What date was the incident?",
  "additionalDriver.claims.date.tooltip.title": "Not sure of the date?",
  "additionalDriver.claims.date.tooltip.description": "Ask them to take a moment to look up any paperwork or emails they might have relating to the incident. We don't need to know about any claims over 5 years old.",
  "additionalDriver.claims.date.error.inFuture": "Whoops, that doesn't look right. You can't enter a date in the future.",
  "additionalDriver.claims.date.error.overFiveYearsInPast": "We don't need to know about claims that happened more than 5 years ago.",
  "additionalDriver.claims.status.title": "Is the claim settled or pending?",
  "additionalDriver.claims.status.tooltip.title": "Not sure?",
  "additionalDriver.claims.status.tooltip.description": "You will know if a claim is settled if all costs have been paid out by, or recouped by, the policyholder's insurer and that you, the policyholder, have been paid back your excess and any injury claims that have been fully paid out.",
  "additionalDriver.claims.type.title": "What type of incident did they have?",
  "additionalDriver.claims.faultStatus.title": "Was the accident deemed their fault?",
  "additionalDriver.claims.faultStatus.tooltip.title": "Not sure who was at fault?",
  "additionalDriver.claims.faultStatus.tooltip.description": "Their previous insurer will be able to inform you of this. Ask them to take a moment to look up any paperwork or emails they might have relating to the incident.",
  "additionalDriver.claims.accidentDescription.title": "What happened in the accident?",
  "additionalDriver.claims.claimedPolicy.title": "Which policy were they driving?",
  "additionalDriver.claims.claimedPolicy.tooltip.description": "We need to know if this incident was against their own insurance policy, on another policy, or if it was on your policy.",
  "additionalDriver.claims.noClaimsAffected.title": "Was their No Claims Bonus affected?",
  "additionalDriver.claims.noClaimsAffected.tooltip.title": "Not sure how to find out?",
  "additionalDriver.claims.noClaimsAffected.tooltip.description": "Their current insurance provider will tell you how many years of NCB they have and whether it was affected as a result of the claim.",
  "additionalDriver.claims.injuriesOccurred.title": "Were there any injuries?",
  "additionalDriver.claims.cost.title": "What was the cost of the claim?",
  "additionalDriver.convictionsQuestions.hasConviction.title": "Have they had any of the following:",
  "additionalDriver.convictionsQuestions.hasConviction.tooltip.title": "Why do we need to know this?",
  "additionalDriver.convictionsQuestions.hasConviction.tooltip.description": "We need to know of any driving offences a driver has committed such as speeding, careless driving or drink-driving. If you're unsure of the code and what offence they may have, they are recorded against a driving record which can be found on the written part of their licence." + "\n\nDon’t worry if they have attended a speed awareness course or have been issued a parking offence, you don’t need to declare this.",
  "additionalDriver.convictionsQuestions.subQuestion.type.title": "What was the offence type?",
  "additionalDriver.convictionsQuestions.subQuestion.type.fetchError": "Sorry, we've failed to retrieve motoring offence types. Give it another go.",
  "additionalDriver.convictionsQuestions.subQuestion.type.tooltip.description": "If you're not sure of the motoring offence details, you can find the code on the written part of their licence",
  "additionalDriver.convictionsQuestions.subQuestion.offence.title": "What was the offence?",
  "additionalDriver.convictionsQuestions.subQuestion.offence.fetchError": "Sorry, we've failed to retrieve motoring offence types. Give it another go.",
  "additionalDriver.convictionsQuestions.subQuestion.date.title": "What date did the offence take place?",
  "additionalDriver.convictionsQuestions.subQuestion.date.tooltip.description": "If you're not sure of the exact date, you can find it on the written part of their licence.\n\nIf your motoring offence is still pending, please contact us.",
  "additionalDriver.convictionsQuestions.subQuestion.receivedPenaltyPoints.title": "Did they receive penalty points?",
  "additionalDriver.convictionsQuestions.subQuestion.convictionPenaltyPoints.title": "How many penalty points did they receive for this motoring offence?",
  "additionalDriver.convictionsQuestions.subQuestion.receivedFine.title": "Were they fined?",
  "additionalDriver.convictionsQuestions.subQuestion.fineAmount.title": "How much were they fined?",
  "additionalDriver.convictionsQuestions.subQuestion.receivedDrivingBan.title": "Did they receive a ban?",
  "additionalDriver.convictionsQuestions.subQuestion.drivingBanDuration.title": "How long were they banned for?",
  "additionalDriver.convictionsQuestions.subQuestion.alcoholTestMethod.title": "What was the method used to test for alcohol?",
  "additionalDriver.convictionsQuestions.subQuestion.alcoholLevelRecorded.title": "What was the alcohol level recorded (mg/l)?",
  "additionalDriver.convictionsQuestions.subQuestion.alcoholLevelRecorded.tooltip.title": "What does this mean?",
  "additionalDriver.convictionsQuestions.subQuestion.alcoholLevelRecorded.tooltip.description": "The alcohol in your breath is measured in micrograms. \n" + "The legal limit for drivers in NI is 35 microgrammes per 100 millilitres of breath.",
  "additionalDriver.convictionsQuestions.tooManyConvictions.stop.title": "Hey, as they have more than five motoring offences we can't quote you online today with this driver.",
  "additionalDriver.nonMotoringConvictionsQuestion": "Have they any non-motoring offences?",
  "additionalDriver.insuranceDeclinedQuestion.title": "Have they ever had insurance declined, cancelled or special terms imposed?",
  "coverDetails.title": "Your Cover",
  "coverDetails.footerButton": "Let's Go",
  "coverDetails.voluntaryExcessQuestion.title": "Let us know what the max voluntary excess you're willing to quote with.",
  "coverDetails.voluntaryExcessQuestion.tooltip.title": "What is it?",
  "coverDetails.voluntaryExcessQuestion.tooltip.description": "Voluntary excess is the amount you must pay out if you make a claim, in addition to the compulsory excess set by your insurance provider.\n\n" + "Choosing a higher voluntary excess now may lower your price but don't forget that you will have to pay that amount at a future date if something happens. It's also important to remember you can't claim for any damages that cost less than your excess.",
  "coverDetails.averageMileageQuestion.title": "How many miles will your car do this year?",
  "coverDetails.averageMileageQuestion.description": "Don't forget to include all social and commuting that you do. This excludes business miles.",
  "coverDetails.averageMileageQuestion.placeholder": "e.g 5000",
  "coverDetails.averageMileageQuestion.tooltip.title": "Why do we need to know this?",
  "coverDetails.averageMileageQuestion.tooltip.description": "This is the total mileage you expect to do over the next year. This should include all driving to and from work, running errands and visiting family and friends.\n\n" + "Need help working it out?\n" + "Think about how many miles you usually drive per day or week, then multiply it to get a yearly figure.",
  "coverDetails.averageBusinessMileageQuestion.title": "How many business miles will your car do this year?",
  "coverDetails.averageBusinessMileageQuestion.description": "This is for any journeys that you do that are work related other than travelling to your one place of work.",
  "coverDetails.averageBusinessMileageQuestion.tooltip.title": "Why do we need to know this?",
  "coverDetails.averageBusinessMileageQuestion.tooltip.description": "We need to know the number of business miles you think your car will do over the next year so we can give you an accurate quote. \n\n" + "Need help working it out? \n" + "Think about how many business miles you usually drive per day or week, then multiply it to get a yearly figure.\n\n" + "Don’t forget to exclude holidays or time off.",
  "coverDetails.coverStartDateQuestion.title": "When would you like your insurance to start?",
  "coverDetails.coverStartDateQuestion.placeholder": "Select date...",
  "coverDetails.coverStartDateQuestion.tooltip": "Most insurers update their rates daily, responding to industry changes. This means that you can only select dates up to 30 days from today when quoting for your car insurance.",
  "coverDetails.coverTypeQuestion.title": "What type of cover would you like?",
  "coverDetails.coverTypeQuestion.tooltip.description": "There are three types of cover to choose from: \n" + "<ul>" + "<li><b>Comprehensive</b> is the highest level of cover and will cover you for any damage to your own car, plus damage to any third parties involved in an incident. Windscreen cover is also included as standard.</li>" + "<li><b>Third Party, Fire and Theft</b> covers injury to other people or damage to their property. It also covers your car for theft and fire damage.</li>" + "<li><b>Third Party Only</b> is the minimum level of cover that you can legally have and only covers injury to other people and their cars, or damage to their property in the event of an incident.</li>" + "</ul>",
  "coverDetails.errors.coverStartDateQuestion.noDateOfBirth": "TBD - Cover start date invalid",
  "coverDetails.errors.coverStartDateQuestion.carNotYetPurchased": "Oh, it looks like that date is before the car purchase date you’ve given. You must be the owner of your car before your policy starts.",
  "coverDetails.errors.coverStartDateQuestion.policyStartBeforeCurrentPolicyExpiryDate": "Oh, it looks like you want to start your policy before your existing policy expires.\n You must select a date after your existing policy expiry date.",
  "coverDetails.previouslyHeldInsuranceQuestion.title": "Are you currently or have you previously been insured to drive a car?",
  "coverDetails.previouslyHeldInsuranceQuestion.title.new": "Do you currently or have you previously held a policy in your own name?",
  "coverDetails.previouslyHeldInsuranceQuestion.description": "This will also include if you had sole use of a company car.",
  "coverDetails.yearsNoClaimsBonusSubQuestion.title": "How many years No Claims Bonus (NCB) do you have?",
  "coverDetails.yearsNoClaimsBonusSubQuestion.tooltip.title": "Why is this important?",
  "coverDetails.yearsNoClaimsBonusSubQuestion.tooltip.description": "You might be able to get a discount off your car insurance price depending on the number of years you haven't claimed against your insurance.\n\n" + "Please note we can only accept your NCB if it has all been earned in the UK, used within the past 2 years and won't be actively used on another policy when your cover starts. If your NCB was earned on a van, motorcycle, company car or fleet insurance we won't be able to accept this.",
  "coverDetails.yearsNoClaimsBonusSubQuestion.placeholder": "Please select",
  "coverDetails.existingPolicyExpiryDateSubQuestion.title": "What is the expiry date of your existing policy?",
  "coverDetails.existingPolicyExpiryDateSubQuestion.tooltip.description": "The expiry date of your current policy will be noted on your certificate of insurance. Remember, you don't want any gaps in cover so make sure that your new policy starts as soon as your previous policy ends.",
  "coverDetails.existingPolicyExpiryDateSubQuestion.warning.exceedsTwoYears": "Unfortunately we can only accept your NCB if it has all been earned in the UK, used within the past 2 years and won't be actively used on another policy when your cover starts.",
  "coverDetails.existingPolicyExpiryDateSubQuestion.warning.exceedsThirtyDays": "Unfortunately we can only accept policies that expire in the next 30 days.",
  "coverDetails.protectNoClaimsBonusSubQuestion.title": "Would you like to protect your No Claims Bonus?",
  "coverDetails.protectNoClaimsBonusSubQuestion.tooltip.title": "What is NCB protection?",
  "coverDetails.protectNoClaimsBonusSubQuestion.tooltip.description": "If you have an incident, your No Claims Bonus will usually be reduced at your next renewal. \n\n" + "When you opt to buy NCB Protection you are protecting the bonus you have earned from your previous driving experience as a policyholder. \n\n" + "It is important to remember that if you do have an accident, even though your NCB won't be reduced your price may still change at your next renewal.",
  "coverDetails.creditSearchesStatement.title": "How We Get Your Quote",
  "coverDetails.creditSearchesStatement.description": "To help get you the best quote, verify your identity and prevent fraud, we may obtain data from credit reference agencies and via soft searches. This will not affect your credit rating. You can find more information on our Privacy Notice.",
  "coverDetails.breakInCover.upToFourteenDays.title": "It looks like there is a gap in your cover",
  "coverDetails.breakInCover.upToFourteenDays.description": "This can occur when you have 1-14 days where your car is uninsured",
  "coverDetails.breakInCover.upToFourteenDays.hasNotBeenInAnAccident": "Your car has not been involved in any incidents that have or may result in a loss whilst it’s been uninsured",
  "coverDetails.breakInCover.upToFourteenDays.hasNoPendingConvictions": "You have no pending police prosecutions",
  "coverDetails.breakInCover.upToFourteenDays.hasBeenKeptAtHomeAddress": "Your car has been kept at the address you declared in this quote whilst it has been uninsured",
  "coverDetails.breakInCover.upToFourteenDays.confirmation": "Can you confirm the below is accurate and correct?",
  "coverDetails.breakInCover.softStops.underFourteenDays.title": "Sorry, we can't insure you online.",
  "coverDetails.breakInCover.softStops.underFourteenDays.description": "As you cannot agree to the above statements, you will need to get in touch with the Stroll team to progress your quote.",
  "coverDetails.breakInCover.softStops.overFourteenDays.title": "Oh it looks like there will be a gap in your cover.",
  "coverDetails.breakInCover.softStops.overFourteenDays.description": "It is usually recommended that you insure your car from the date of purchase to make sure you're protected." + "\n\nBy proceeding with a gap in cover,  you may be at risk of being out of pocket if you have no other insurance in place to cover your car.",
  "coverDetails.ownOrCompanyCarQuestion.title": "Was this your own car or a company car?",
  "coverDetails.ownOrCompanyCarQuestion.placeholder": "Make a selection",
  "coverDetails.ownOrCompanyCarQuestion.option.companyCar": "Company Car",
  "coverDetails.ownOrCompanyCarQuestion.option.ownPolicy": "My Car",
  "coverDetails.hasVehicleBeenInsuredQuestion.title.oneYearOnly": "You’ve let us know that you’ve owned your car for 1 year. Has there been insurance cover on your vehicle during that time?",
  "coverDetails.hasVehicleBeenInsuredQuestion.title.oneMonthOnly": "You’ve let us know that you’ve owned your car for 1 month. Has there been insurance cover on your vehicle during that time?",
  "coverDetails.hasVehicleBeenInsuredQuestion.title.oneDayOnly": "You’ve let us know that you’ve owned your car for 1 day. Has there been insurance cover on your vehicle during that time?",
  "coverDetails.hasVehicleBeenInsuredQuestion.title.yearsOnly": "You’ve let us know that you’ve owned your car for {years} years. Has there been insurance cover on your vehicle during that time?",
  "coverDetails.hasVehicleBeenInsuredQuestion.title.monthsOnly": "You’ve let us know that you’ve owned your car for {months} months. Has there been insurance cover on your vehicle during that time?",
  "coverDetails.hasVehicleBeenInsuredQuestion.title.daysOnly": "You’ve let us know that you’ve owned your car for {days} days. Has there been insurance cover on your vehicle during that time?",
  "coverDetails.namedDriverOnOwnVehicleQuestion.title": "Were you insured as a named driver on that policy?",
  "coverDetails.vehiclePreviousPolicyExpiryDateQuestion.title": "What is the expiry date of that policy?",
  "coverDetails.vehiclePreviousPolicyExpiryDateQuestion.tooltip": "The expiry date of the policy currently active on the car will be noted on the certificate of insurance.\n\n" + "Remember, you don't want any gaps or overlaps in cover, so make sure that your new policy starts as soon as the previous policy ends.",
  "coverDetails.previousNamedDriverQuestion.title": "Have you previously been named on someone else’s policy?",
  "coverDetails.previousNamedDriverQuestion.tooltip.description": "<b>What does this mean?</b>\nIf you have previously been a named driver on someone else’s car insurance policy, you may have earned a discount for the number of years claim-free driving. To qualify as a named driver, you must have been listed as an additional driver on the policy documents.\n\n<b>Can I use experience from a different type of vehicle?</b>\nWe can only accept driving experience from other car policies. We cannot accept experience from van, taxi, motorcycle or haulage policies.",
  "coverDetails.currentlyOrPreviouslyInsuredQuestion.title": "Are you currently insured on this car, or have you been in the past 90 days?",
  "coverDetails.currentlyOrPreviouslyInsuredQuestion.namedOnOwnVehicle.title": "Are you currently, or have you previously, been insured on this policy, or any other in the past 90 days?",
  "coverDetails.currentlyOrPreviouslyInsuredQuestion.tooltip.description": "You will be currently insured on the car if you are listed as an additional driver on the policy documents, or also answer yes if you have only recently been removed from the policy (within 90 days from the cover start date of this quote).",
  "coverDetails.vehicleTypeQuestion.title": "What type of vehicle was it?",
  "coverDetails.vehicleTypeQuestion.option.car": "Car",
  "coverDetails.vehicleTypeQuestion.option.motorBike": "Motorbike",
  "coverDetails.vehicleTypeQuestion.option.van": "Van",
  "coverDetails.vehicleTypeQuestion.option.other": "Other",
  "coverDetails.yearsNamedDriverQuestion.title": "How many years have you been named on this policy?",
  "coverDetails.yearsNamedDriverQuestion.description": "Each year must be a full 12 months.  If there is more than 30 days break between each year, count the number of full years experience from the end of that break.",
  "coverDetails.yearsNamedDriverQuestion.tooltip.description": "<b>Example:</b> Jess was insured as an additional driver for 2 years on her mum’s policy.  She then went travelling for 6 months, and since she has come home she has been reinsured on her mum’s policy for the past 1 year.\n\nAs there was a gap in Jess’s driving experience of more than 30 days, she has only been named on this policy as an additional driver for 1 year.",
  "coverDetails.namedDriver.confirmation.title": "Good News! It looks like we can accept your named driving experience.",
  "coverDetails.namedDriver.confirmation.subtitle": "Based on what you’ve told us, you qualify for a discount if you are able to confirm that you meet the below criteria. Don’t worry if you can’t, select No and we’ll still get you our best price.",
  "coverDetails.namedDriver.confirmation.description": "Can you confirm that:",
  "coverDetails.namedDriver.confirmation.proofOfYears": "Proof of every year can be provided from the broker or insurer of the car (or cars) that you were named on. ",
  "coverDetails.namedDriver.confirmation.consecutiveYears": "The number of years that you have selected are consecutive.",
  "coverDetails.namedDriver.confirmation.noBreaks": "There are no breaks of 30+ days between each full year.",
  "coverDetails.namedDriver.confirmation.ukPolicyOnly": "Your experience has been earned on UK policies.",
  "coverDetails.namedDriver.confirmation.noOtherVehicle": "You are not actively using this experience on any other car.",
  "coverDetails.namedDriver.confirmation.notPartOfFleet": "Your experience wasn’t earned on a fleet policy.",
  "coverDetails.namedDriver.ineligiblePanel.title": "Oh, it looks like we’ve hit a speed bump.",
  "coverDetails.namedDriver.ineligiblePanel.subtitle": "Based on what you’ve told us, you don’t qualify for a named driving experience discount this time.",
  "coverDetails.namedDriver.ineligiblePanel.description": "Keep going though, we will still get you our best price.",
  "coverDetails.namedDriverBreakInCover.softStop.title": "Hey, we just need more info about the insurance history of this car to complete your quote.",
  "coverDetails.namedDriverBreakInCover.softStop.description": "Get in touch with the Stroll team and we will walk you through the rest of your quote.",
  "coverDetails.companyCar.confirmation.title": "Let’s check if you qualify…",
  "coverDetails.companyCar.confirmation.subtitle": "To use a company car bonus, please confirm that you are able to meet the below criteria. Don’t worry if you can’t, select No and we’ll still get you our best price.",
  "coverDetails.companyCar.confirmation.description": "Can you confirm that:",
  "coverDetails.companyCar.confirmation.soleUse": "You had sole use of the company car during the time that the bonus was earned.",
  "coverDetails.companyCar.confirmation.proofOfYears": "Proof of your company car bonus can be provided.",
  "coverDetails.companyCar.confirmation.consecutiveYears": "The number of years that you have selected are consecutive.",
  "coverDetails.companyCar.confirmation.noBreaks": "There are no breaks of 30+ days between each full year.",
  "coverDetails.companyCar.confirmation.ukPolicyOnly": "Your experience has been earned on UK policies.",
  "coverDetails.companyCar.confirmation.noOtherVehicle": "You are not actively using this bonus on any other car.",
  "coverDetails.companyCar.confirmation.noLongerInsured": "You are no longer insured on the company car policy.",
  "coverDetails.companyCar.ineligiblePanel.title": "Oh, it looks like we’ve hit a speed bump",
  "coverDetails.companyCar.ineligiblePanel.subtitle": "Based on what you’ve told us, you don’t qualify for a company car discount this time.",
  "coverDetails.companyCar.ineligiblePanel.description": "Keep going though, we will still get you our best price.",
  "coverDetails.companyCar.forYourInformation.title": "For your information",
  "coverDetails.companyCar.forYourInformation.description": "Based on what you've told us, you don't qualify for a company car discount this time.\n\nKeeping going though, we will still get you our best price.",
  "coverDetails.companyCarBonusQuestion.title": "How many years company car bonus do you have?",
  "coverDetails.companyCarBonusQuestion.tooltip.description": "You might be able to get a discount off your car insurance price depending on the number of years you haven’t claimed against the insurance for the company car that you had sole use of.",
  "coverDetails.companyCarPolicyExpiryDateQuestion.title": "What is the expiry date of your company car policy?",
  "coverDetails.companyCarPolicyExpiryDateQuestion.tooltip.description": "The expiry date of your company car policy will be noted on your certificate of insurance, or the last date that you were insured on the company car policy. ",
  "quote.shared.buyQuoteRequirements.title": "What’s required if I buy this quote?",
  "quote.shared.buyQuoteRequirements.noExperience.description": "You will need to send us a copy of your driving licence (both parts).",
  "quote.shared.buyQuoteRequirements.namedDriver.description": "You will need to send us a copy of your driving licence (both parts) and documented proof of each year’s driving experience, from the broker or insurer of the car (or cars) that you were named on. ",
  "quote.shared.buyQuoteRequirements.noClaimsBonus.description": "You will need to send us a copy of your driving licence (both parts) and documented proof of your No Claims Bonus, with an expiry date no more than 2 years before your start date.",
  "quote.shared.buyQuoteRequirements.companyCar.description": "You will need to send us a copy of your driving licence (both parts) and documented proof of your company car bonus, with an expiry date no more than 30 days before your start date.",
  "quoteDetails.title": "Refine Your Quote",
  "quoteDetails.description": "Here’s your quote {firstName}, now let’s get you insured! You can customise your policy and when you’re ready, pay all in one go or spread the cost. We’re flexible so it’s up to you.",
  "quoteDetails.tabGroup.annualCost": "Annual Cost",
  "quoteDetails.tabGroup.monthlyCost": "Monthly Cost",
  "quoteDetails.insurerInformation": "Yay, the best quote we found you was with",
  "quoteDetails.footerButton": "Agree & Purchase",
  "quoteDetails.priceCard.quotePrice.label": "Your Quote:",
  "quoteDetails.priceCard.quotePrice.costPerMonth": "/month",
  "quoteDetails.priceCard.insurerName": "Quote with",
  "quoteDetails.priceCard.insurancePremium": "Premium",
  "quoteDetails.priceCard.strollBrokerDiscount": "Stroll Broker Discount",
  "quoteDetails.priceCard.adminFee": "Admin Fee",
  "quoteDetails.priceCard.adminFee.value": "Free",
  "quoteDetails.priceCard.extras": "Policy Extras Total",
  "quoteDetails.priceCard.deposit": "Deposit",
  "quoteDetails.priceCard.totalPayable": "Total",
  "quoteDetails.priceCard.totalPayableAmount": "Total (Inc 15% Interest/{aprPercentage}% APR)",
  "quoteDetails.priceCard.policyExtras": "Extras",
  "quoteDetails.priceCard.instalments": "{numberOfInstalments} instalments",
  "quoteDetails.priceCard.reference": "Your quote reference is",
  "quoteDetails.priceCard.ipt": "Premiums are inclusive of 12% IPT",
  "quoteDetails.priceCard.header.quoteType.companyCar": "With Company Car Experience",
  "quoteDetails.priceCard.header.quoteType.default": "Starting with zero experience",
  "quoteDetails.priceCard.header.quoteType.namedDriverExperience": "With Named Driving Experience",
  "quoteDetails.priceCard.header.quoteType.noClaims": "With No Claims Bonus",
  "quoteDetails.priceCard.header.quoteReference": "Quote Reference: {quoteReference}",
  "quoteDetails.priceCard.header.acceptedYearsOfExperience": "We can accept {yearsOfExperience, plural, one {# year} other {# years}} of your {drivingExperience}",
  "quoteDetails.priceCard.header.namedDrivingExperience": "Named Driving Experience",
  "quoteDetails.priceCard.header.companyCarDrivingExperience": "Company Car Driving Experience",
  "quoteDetails.quoteResult.loading": "Loading quote...",
  "quoteDetails.quoteResult.error": "Critical error - Quote request failed \n{message}",
  "quoteDetails.quoteResult.invalidVehicle": "Valid quote details not provided - Using hard coded details to get example quote price",
  "quoteDetails.depositAndPayments.title": "Deposit & Payments",
  "quoteDetails.depositAndPayments.description": "Tweak your deposit and number of instalments to suit you.",
  "quoteDetails.depositAndPayments.radioOption": " payments of ",
  "quoteDetails.depositAndPayments.placeholder": "--.--",
  "quoteDetails.policyExtrasQuestion.title": "Add Policy Extras",
  "quoteDetails.summary.editButton": "Edit",
  "quoteDetails.summary.yourCar": "Your Car",
  "quoteDetails.summary.yourCar.addDetails": "Add details",
  "quoteDetails.summary.yourCar.description": "We need some more information before you can continue.",
  "quoteDetails.summary.yourCar.registrationNumber": "Registration Number",
  "quoteDetails.summary.yourCar.dateOfPurchase": "Date of Purchase",
  "quoteDetails.summary.yourCar.make": "Make",
  "quoteDetails.summary.yourCar.model": "Model",
  "quoteDetails.summary.yourCar.fuel": "Fuel",
  "quoteDetails.summary.yourCar.transmission": "Transmission",
  "quoteDetails.summary.yourCar.yearOfReg": "Year of Registration",
  "quoteDetails.summary.yourCar.engineSize": "Engine Size",
  "quoteDetails.summary.yourCar.trimLevel": "Model Type",
  "quoteDetails.summary.yourCar.driverPosition": "Driver Position",
  "quoteDetails.summary.yourCar.seats": "Seats",
  "quoteDetails.summary.yourCar.hasTracker": "Tracker Fitted",
  "quoteDetails.summary.yourCar.alarm": "Alarm/Immobiliser",
  "quoteDetails.summary.yourCar.imported": "Imported Car",
  "quoteDetails.summary.yourCar.modifications": "Modifications",
  "quoteDetails.summary.yourCar.estimatedValue": "Estimated Value",
  "quoteDetails.summary.yourDetails": "Your Details",
  "quoteDetails.summary.yourDetails.name": "Name",
  "quoteDetails.summary.yourDetails.dateOfBirth": "Date of Birth",
  "quoteDetails.summary.yourDetails.address": "Address",
  "quoteDetails.summary.yourDetails.carStorage": "Car Storage",
  "quoteDetails.summary.yourDetails.homeowner": "Homeowner",
  "quoteDetails.summary.yourDetails.jobTitle": "Job Title",
  "quoteDetails.summary.yourDetails.classOfUse": "Class of Use",
  "quoteDetails.summary.yourDetails.licenceInfo": "Licence",
  "quoteDetails.summary.yourDetails.cancellationsEndorsements": "Cancellations/Endorsements",
  "quoteDetails.summary.yourDetails.convictions": "Motoring Offences",
  "quoteDetails.summary.yourDetails.medicalInfo": "Medical Declarations",
  "quoteDetails.summary.yourDetails.claims": "Claims",
  "quoteDetails.summary.yourCover": "Your Cover",
  "quoteDetails.summary.yourCover.startDate": "Policy Start Date",
  "quoteDetails.summary.yourCover.type": "Cover Type",
  "quoteDetails.summary.yourCover.voluntaryExcess": "Voluntary Excess",
  "quoteDetails.summary.yourCover.compulsoryExcess": "Compulsory Excess",
  "quoteDetails.summary.yourCover.noClaimsBonus": "No Claims Bonus",
  "quoteDetails.summary.yourCover.mileage": "Mileage",
  "quoteDetails.summary.additionalDrivers": "Additional Drivers",
  "quoteDetails.summary.additionalDrivers.editDriver": "Edit Driver",
  "quoteDetails.summary.additionalDrivers.button": "Add Driver",
  "quoteDetails.summary.additionalDrivers.description": "You can add or remove up to 4 drivers and see the impact on price at the end.",
  "quoteDetails.summary.cover.title": "Your Cover Summary",
  "quoteDetails.summary.coverStartDate": "Policy Start Date",
  "quoteDetails.summary.coverStartDate.description": "When would you like your policy to start?",
  "quoteDetails.summary.coverStartDate.warning.noClaims": "Oh, it looks like we can't accept your No Claims Bonus. Keep going though and we'll still get you our best quote.",
  "quoteDetails.summary.coverStartDate.warning.noClaims.tooltip": "Based on the start date that you have requested, your No Claims Bonus is not eligible for use. We can only accept a NCB that has been active within the past 2 years.",
  "quoteDetails.summary.coverStartDate.warning.companyCarBonus": "Oh, it looks like we can't accept your Company Car Bonus. Keep going though and we'll still get you our best quote.",
  "quoteDetails.summary.coverStartDate.warning.companyCarBonus.tooltip": "Based on the start date that you have requested, your Company Car Bonus is not eligible for use. We can only accept a Company Car Bonus that has expired within 30 days of the start date that you’ve entered.",
  "quoteDetails.summary.coverStartDate.warning.vehiclePolicyExpiryDate": "Oh, it looks like there will be two active policies on this car at once.",
  "quoteDetails.summary.coverStartDate.warning.vehiclePolicyExpiryDate.tooltip": "You’ve let us know that there’s an active policy on this car until {vehiclePolicyExpiryDate} which means that there'll be an overlap in cover. If you need insurance from the start date entered, make sure there are no other active insurance policies on the car from that time, or change the start date that you’ve entered.",
  "quoteDetails.summary.coverStartDate.warning.combinedCompanyCarAndPolicyExpiryDate": "Oh, we need to let you know some things…",
  "quoteDetails.summary.coverStartDate.warning.combinedCompanyCarAndPolicyExpiryDate.tooltip": "Based on the start date that you’ve selected, your Company Car Bonus is not eligible for use. \n\nYou’ve also let us know that there’s an active policy on this car until {vehiclePolicyExpiryDate}, which means that there'll be an overlap in cover. If you need insurance from the start date entered, make sure there are no other active insurance policies on the car from that time, or change the start date that you’ve entered.",
  "quoteDetails.summary.noClaimsProtection": "No Claims Bonus",
  "quoteDetails.summary.noClaimsProtection.description": "Would you like to protect your NCB?",
  "quoteDetails.summary.noClaimsProtection.protected": "Protected",
  "quoteDetails.summary.noClaimsProtection.unprotected": "Unprotected",
  "quoteDetails.summary.policyExcess": "Policy Excess",
  "quoteDetails.summary.checkYourDocuments": "See your policy documents",
  "quoteDetails.summary.policyExcess.description": "Compulsory excess is set to: £{excess}\n\n" + "Let us know what the max voluntary excess you're willing to quote with.",
  "quoteDetails.summary.policyExcess.detailsList.compulsory": "Compulsory Excess",
  "quoteDetails.summary.policyExcess.detailsList.voluntary": "Voluntary Excess",
  "quoteDetails.summary.voluntaryExcess": "Voluntary Excess",
  "quoteDetails.summary.coverType": "Cover Type",
  "quoteDetails.summary.modal.title": "Edit Cover Details",
  "quoteDetails.summary.modal.cancel": "Cancel",
  "quoteDetails.summary.modal.save": "Update",
  "quoteDetails.carDetails.modal.title": "Your car details",
  "quoteDetails.carDetails.modal.registrationNumber": "Registration number",
  "quoteDetails.carDetails.modal.registrationNumber.placeholder": "STR 0111",
  "quoteDetails.carDetails.modal.registrationNumber.error": "Please enter a valid registration number",
  "quoteDetails.carDetails.modal.registrationNumber.error.qRegistration": "Hey, we don't currently insure cars with a Q-reg plate.",
  "quoteDetails.carDetails.modal.dateOfPurchase": "Date of purchase",
  "quoteDetails.carDetails.modal.dateOfPurchase.afterToday": "Whoops, you can't enter a purchase date in the future.",
  "quoteDetails.carDetails.modal.cancel": "Cancel",
  "quoteDetails.carDetails.modal.save": "Save",
  "quoteDetails.sidebar.title.saveQuote": "Save Your Quote",
  "quoteDetails.sidebar.title.quoteSaved": "It's on the way!",
  "quoteDetails.sidebar.title.quoteEdited": "Quote updated?",
  "quoteDetails.sidebar.description.saveYourQuote.main": "Still thinking about it? No problem.",
  "quoteDetails.sidebar.description.saveYourQuote.secondary": "We'll keep your price safe for 5 days!",
  "quoteDetails.sidebar.description.quoteSaved.main": "We’ve sent your quote to:",
  "quoteDetails.sidebar.description.quoteEdited.main": "We noticed you made some changes to your quote.",
  "quoteDetails.sidebar.description.quoteEdited.secondary": "Any updates will be sent to:",
  "quoteDetails.sidebar.button.saveQuote": "Save Quote",
  "quoteDetails.sidebar.button.quoteEdited": "Send Updates",
  "quoteDetails.sidebar.button.changeEmailAddress": "Update Email Address",
  "quoteDetails.quoteDocuments.title": "Policy Documents",
  "quoteDetails.quoteDocuments.description": "These documents go into the finer details of your car insurance provider. It’s important to take a look at them so you know the key features, benefits and exclusions of your policy.",
  "quoteDetails.quoteDocuments.ipid": "Policy Info",
  "quoteDetails.quoteDocuments.policyBooklet": "Policy Booklet",
  "quoteDetails.legalDocuments.title": "The Legal Stuff",
  "quoteDetails.legalDocuments.description": "It’s important to read our Terms and Conditions and Privacy Policy before you proceed. By clicking “Agree and Purchase” you are confirming you have both read and accepted them. Once you have purchased your policy, these are always available to view on our website.",
  "quoteDetails.legalDocuments.termsAndConditions": "Terms and Conditions",
  "quoteDetails.legalDocuments.privacyPolicy": "Privacy Policy",
  "quoteDetails.contactDetails.title": "Contact Details",
  "quoteDetails.contactDetails.description": "We’ll send confirmation of your cover and any policy related updates to this email address and mobile number.",
  "quoteDetails.contactDetails.email.label": "Email Address",
  "quoteDetails.contactDetails.email.placeholder": "your@email.com",
  "quoteDetails.contactDetails.email.error.required": "You missed this!",
  "quoteDetails.contactDetails.email.error.accountAlreadyExists": "An account with this email already exists, use a different email or contact the Stroll team.",
  "quoteDetails.contactDetails.email.error.genericFailure": "Something went wrong, please try again.",
  "quoteDetails.contactDetails.email.error.invalidEntry": "Whoops, that email address doesn't look right. Give it another go.",
  "quoteDetails.contactDetails.phone.label": "Mobile Number",
  "quoteDetails.contactDetails.phone.placeholder": "7590010944",
  "quoteDetails.contactDetails.confirmation.title": "You've told us that",
  "quoteDetails.contactDetails.confirmation.email.label": "Your email address is",
  "quoteDetails.contactDetails.confirmation.phoneNumber.label": "Your phone number is",
  "quoteDetails.contactDetails.confirmation.question.label": "Is this information correct?",
  "quoteDetails.contactDetails.confirmation.error": "Whoops, you must confirm that these details are correct or change them to be the correct details.",
  "quoteDetails.contactDetails.form.title": "Edit Contact Details",
  "quoteDetails.contactDetails.form.save": "Save Changes",
  "quoteDetails.tooYoungForFinanceStop.title": "We need to speak to you.",
  "quoteDetails.tooYoungForFinanceStop.description": "As you are 17, we need to ask you some additional questions so you can pay in monthly instalments. Get in touch with the Stroll team and we will get you covered.",
  "quoteDetails.tooYoungForFinanceStop.button.contactAgent": "Chat to us",
  "quoteDetails.tooYoungForFinanceStop.button.return": "Return & Pay Annually",
  "quoteDetails.noClaimsProtectionCard.heading.title": "No Claims Bonus Protection",
  "quoteDetails.noClaimsProtectionCard.heading.included": "Included at {price}",
  "quoteDetails.noClaimsProtectionCard.heading.notIncluded": "Not Included",
  "quoteDetails.noClaimsProtectionCard.body.includedAtPrice": "You’ve included No Claims Bonus Protection at an additional cost of {price}.",
  "quoteDetails.noClaimsProtectionCard.body.included.whyProtectYourBonus": "<b>Why should you protect your bonus?</b> By having {years} years NCB you are receiving a discount of {discountPercentage}%.  By buying this No Claims Bonus Protection you are safeguarding this discount if you make a claim.  To check how your NCB would be impacted if you had a claim, make sure to check out the step-back guide for more info on protected and unprotected cover.",
  "quoteDetails.noClaimsProtectionCard.body.included.isNCBProtectionOptional": "<b>Is No Claims Bonus Protection optional?</b> Yes. You can choose to remove this level of cover from your quote.",
  "quoteDetails.noClaimsProtectionCard.body.notIncluded.whyProtectYourBonus": "<b>Why should you protect your bonus?</b> By having {years} years NCB you are receiving a discount of {discountPercentage}%.  If you add No Claims Bonus Protection you would be safeguarding this discount if you make a claim.  To check how your NCB would be impacted if you had a claim, make sure to check out the step-back guide for more info on protected and unprotected cover.",
  "quoteDetails.noClaimsProtectionCard.body.notIncluded.isNCBProtectionOptional": "<b>Is No Claims Bonus Protection optional?</b> Yes. You can choose to add this level of cover to your quote, or continue without it.",
  "quoteDetails.noClaimsProtectionCard.body.doesThisProtectMyPriceNextYear": "<b>Does this protect my price next year?</b> Protecting your No Claims Bonus does not guarantee that your price will be the same or lower, so if you purchase bonus protection and have an accident during the year, premiums can change.",
  "quoteDetails.noClaimsProtectionCard.body.button": "More information",
  "quoteDetails.noClaimsProtectionCard.footer.button": "Edit",
  "quoteDetails.goBack": "< Go back and view quotes",
  "quoteDetails.notificationBanner.changeOfDetails": "Change of Details",
  "quoteDetails.notificationBanner.changeOfDetails.foundACheaperQuote": "Your details have changed and we’ve found you a cheaper quote.",
  "quoteDetails.notificationBanner.changeOfDetails.newQuotes": "Your details have changed and these are the quotes that we're able to offer you now.",
  "quoteDetails.notificationBanner.changeOfDetails.callToAction": "View Quotes",
  "quoteDetails.aggregatorMissingAnswersModal.title": "We need more information from you.",
  "quoteDetails.aggregatorMissingAnswersModal.carPurchaseDate.title": "Car Purchase Date",
  "quoteDetails.aggregatorMissingAnswersModal.carPurchaseDate.description": "When did you buy the car?",
  "quoteDetails.aggregatorMissingAnswersModal.existingPolicyExpiryDate.title": "Existing Policy Expiry Date",
  "quoteDetails.aggregatorMissingAnswersModal.existingPolicyExpiryDate.description": "When does your existing policy expire?",
  "quoteDetails.aggregatorMissingAnswersModal.policyStartDate.title": "Policy Start Date",
  "quoteDetails.aggregatorMissingAnswersModal.policyStartDate.description": "When would you like your policy to start?",
  "quoteDetails.aggregatorMissingAnswersModal.confirm": "Confirm",
  "priorQuote.header.viewSavedQuotes": "View Saved Quotes",
  "priorQuote.header.login": "Login",
  "priorQuote.header.quotePrice.title": "QUOTE:",
  "priorQuote.header.quotePrice.placeholder": "---.--",
  "priorQuote.header.vehicleReg": "Car Reg",
  "priorQuote.header.vehicleReg.placeholder": "-------",
  "priorQuote.header.noClaimsProtection": "No Claims Protection",
  "priorQuote.header.noClaimsProtection.placeholder": "---",
  "priorQuote.header.policyExcess": "Policy Excess",
  "priorQuote.header.coverType": "Cover Type",
  "priorQuote.header.coverType.placeholder": "-------------",
  "priorQuote.header.startDate": "Start Date",
  "priorQuote.header.startDate.placeholder": "--/--/--",
  "priorQuote.header.buyNow": "Buy Now",
  "priorQuote.quoteNotFound.title": "Whoops! Slow down there, driver.",
  "priorQuote.quoteNotFound.subTitle": "You've taken a wrong turn.",
  "priorQuote.quoteNotFound.description": "Your quote is no longer available. You can create a new one here.",
  "priorQuote.quoteNotFound.button": "Start New Quote",
  "priorQuote.coverStartDateExpiredModal.title": "Your start date has expired. To continue, we will need a new date.",
  "priorQuote.coverStartDateExpiredModal.question.title": "Choose a start date",
  "priorQuote.coverStartDateExpiredModal.question.description": "Tell us when you would like your insurance cover to start from.",
  "priorQuote.coverStartDateExpiredModal.buttons.update": "Update",
  "priorQuote.priceGuaranteeExpiredModal.title": "Oh no, it looks like your price has expired.",
  "priorQuote.priceGuaranteeExpiredModal.description": "Don't worry, we will re-quote you and get you a new price.",
  "priorQuote.priceGuaranteeExpiredModal.dismiss": "OK",
  "priorQuote.title": "Refine Your Quote",
  "priorQuote.description": "Welcome back {firstName}. You can customise your quote and when you’re ready, pay all in one go or spread the cost. We’re flexible so it's up to you.",
  "aggregatorQuote.sidebar.description": "Thank you for choosing Stroll, now let's get you insured!",
  "aggregatorQuote.priceCard.footer.editQuote.title": "Want to make a change?",
  "aggregatorQuote.priceCard.footer.editQuote.description": "If you’d like to change some aspects of your quote you can do so now.",
  "aggregatorQuote.priceCard.footer.editQuote.button": "Edit Quote",
  "aggregatorQuote.priceCard.footer.errorsPresent.title": "We’re missing some information from you",
  "aggregatorQuote.priceCard.footer.errorsPresent.description": "In order to proceed with your quote we’ll need you to provide this",
  "aggregatorQuote.priceCard.footer.errorsPresent.button": "Show me",
  "aggregatorQuote.validation.item.doesNotExist": "Given Item Does Not Exist",
  "aggregatorQuote.validationIssueTable.item.resolve": "Resolve",
  "aggregatorQuote.validationIssueTable.yourCar.title": "Your Car",
  "aggregatorQuote.validationIssueTable.yourCar.description": "If your car is modified in any way we need to know as this may affect your quote price.",
  "aggregatorQuote.validationIssueTable.yourCar.tableItem.heading": "Your Car",
  "aggregatorQuote.validationIssueTable.yourCar.tableItem.subHeading": "We've spotted a problem with the details you have given us.",
  "aggregatorQuote.validationIssueTable.yourCar.tableItem.errorMessage": "There {numberOfIssues, plural, =0 {are no issues} one {is one issue} other {are # issues}} that needs to be resolved",
  "aggregatorQuote.validationIssueTable.yourDetails.title": "Your Details",
  "aggregatorQuote.validationIssueTable.yourDetails.description": "Your Details Description",
  "aggregatorQuote.validationIssueTable.yourDetails.tableItem.heading": "Your Details",
  "aggregatorQuote.validationIssueTable.yourDetails.tableItem.subHeading": "We've spotted a problem with the details you have given us.",
  "aggregatorQuote.validationIssueTable.yourDetails.tableItem.errorMessage": "There {numberOfIssues, plural, =0 {are no issues} one {is one issue} other {are # issues}} that needs to be resolved",
  "aggregatorQuote.validationIssueTable.yourCover.title": "Your Cover",
  "aggregatorQuote.validationIssueTable.yourCover.description": "Your Cover Description",
  "aggregatorQuote.validationIssueTable.yourCover.tableItem.heading": "Your Cover",
  "aggregatorQuote.validationIssueTable.yourCover.tableItem.subHeading": "We've spotted a problem with the details you have given us.",
  "aggregatorQuote.validationIssueTable.yourCover.tableItem.errorMessage": "There {numberOfIssues, plural, =0 {are no issues} one {is one issue} other {are # issues}} that needs to be resolved",
  "aggregatorQuote.validationIssueTable.additionalDrivers.title": "Additional Drivers",
  "aggregatorQuote.validationIssueTable.additionalDrivers.description": "Additional Drivers Description",
  "aggregatorQuote.validationIssueTable.additionalDrivers.tableItem.heading": "Additional Driver",
  "aggregatorQuote.validationIssueTable.additionalDrivers.tableItem.subHeading": "{firstName} {lastName} ({relationshipToProposer})",
  "aggregatorQuote.validationIssueTable.additionalDrivers.tableItem.errorMessage": "There {numberOfIssues, plural, =0 {are no issues} one {is one issue} other {are # issues}} that needs to be resolved for this driver",
  "aggregatorQuote.resolveErrorFlow.confirm": "Confirm",
  "protectedNoClaimsDiscountInfo.title": "Protected NCD Information",
  "protectedNoClaimsDiscountInfo.description": "Insurers will give you a discount for each years claims free driving that you have accumulated. Each insurer will have a max number of years claims free driving that they will recognise. Check out the table below to view the discount that {insurerName} applies for each No Claims Discount level.",
  "protectedNoClaimsDiscountInfo.discountTable.yearsNCD": "Number of years NCD",
  "protectedNoClaimsDiscountInfo.discountTable.averageDiscount": "Average NCD",
  "protectedNoClaimsDiscountInfo.stepBack.title": "No Claims Discount Step-Back Procedures",
  "protectedNoClaimsDiscountInfo.stepBack.description": "The below info shows the No Claims Discount step-back guide. This lets you know how your bonus would be impacted if you had a claim this year, with or without No Claims Discount Protection, up to the max number of years discount that {insurerName} recognise.",
  "protectedNoClaimsDiscountInfo.stepBack.withProtection.title": "No Claims Discount at next renewal date <b>WITH</b> NCD Protection",
  "protectedNoClaimsDiscountInfo.stepBack.withoutProtection.title": "No Claims Discount at next renewal date <b>WITHOUT</b> NCD Protection",
  "expiredQuote.title": "Oh no, it looks like your quote has expired!",
  "expiredQuote.description": "To get an accurate quote, we need you to confirm a few things.",
  "expiredQuote.button": "Confirm Details",
  "refusedQuote.title": "Oh, it looks like we can't quote you with those details.",
  "refusedQuote.reference": "Remember to quote this reference number if you're chatting to us:",
  "refusedQuote.description": "A good start is to check out some of the key info below:",
  "refusedQuote.callToAction.edit": "Edit My Details",
  "refusedQuote.callToAction.chat": "Live Chat",
  "refusedQuote.list.checkDateOfBirthAndLicenseInfo": "Mistakes can happen, always check you've entered dates correctly especially your date of birth and licence information.",
  "refusedQuote.list.vehicleBeyondDrivingExperience": "Shopping about for a car? Higher spec'd cars need a bit more driving history behind you, so make sure the car you're quoting for isn't beyond your driving experience.",
  "refusedQuote.list.claimsAndConvictionHistory": "Got claims and motoring offence history? When things aren't straight forward, sometimes we just need to speak directly to the insurer to get you a price.",
  "quoteSteps.header.yourCar": "Your Car",
  "quoteSteps.sidebarDescription.yourCar": "Fill in your vehicle info.",
  "quoteSteps.header.yourDetails": "Your Details",
  "quoteSteps.sidebarDescription.yourDetails": "Tell us a little more about you.",
  "quoteSteps.header.yourCover": "Your Cover",
  "quoteSteps.sidebarDescription.yourCover": "Choose how much cover you need.",
  "quoteSteps.header.yourQuote": "Refine Quote",
  "quoteSteps.sidebarDescription.yourQuote": "Add policy extras and buy.",
  "paymentFlow.inProgress": "Making Payment...",
  "paymentFlow.annual.landing.mobileTitle": "Paying Annually",
  "paymentFlow.annual.landing.description": "We accept the below methods for annual payments",
  "paymentFlow.annual.landing.payByCard": "Pay with Credit / Debit Card",
  "paymentFlow.annual.landing.sidebar.title": "Payment",
  "paymentFlow.annual.landing.sidebar.description": "You’re nearly there, just one last step!\n\n" + "We accept Visa and Mastercard.",
  "paymentFlow.annual.byCard.mobileTitle": "Paying annually",
  "paymentFlow.annual.byCard.sidebar.title": "Payment",
  "paymentFlow.annual.byCard.sidebar.description": "You’re nearly there, just one last step!\n\n" + "We accept Visa and Mastercard.",
  "paymentFlow.monthly.directDebit.title": "Let’s set up your direct debit",
  "paymentFlow.monthly.directDebit.description": "Firstly, we need your bank details to enter into a direct debit agreement with our finance partner, Close Brothers Ltd. You can see your payment summary below.",
  "paymentFlow.monthly.directDebit.confirmationButton": "Continue",
  "paymentFlow.monthly.directDebit.confirmRenewalButton": "Confirm Renewal",
  "paymentFlow.monthly.directDebit.sidebar.title": "Step 1 of {totalSteps}",
  "paymentFlow.monthly.directDebit.sidebar.description": "All finance provided by our partners Close Brothers Modern Merchant Banking.",
  "paymentFlow.monthly.directDebit.interestRate": "Monthly payments have been calculated at {interestRate}%\ninterest (APR @ {apr}%)",
  "paymentFlow.monthly.directDebit.monthlyInstalments.title": "Your Monthly Instalments",
  "paymentFlow.monthly.directDebit.monthlyInstalments.description": "Your first instalment will be debited from your account 30 calendar days after your policy start date: {dateOfFirstPayment}. This will be confirmed to you by Close Brothers Premium Finance via email and post in your welcome pack. The remaining payments will be debited on the same date each month. If you wish to change your direct debit date, please contact Close Brothers directly after your first instalment has been successfully debited.",
  "paymentFlow.monthly.directDebit.monthlyInstalmentsForLoanRenewal.description": "Your first instalment will be debited from your account nine days from today. If your renewal date is more than nine days away, your first payment will be debited from your account on your renewal date (or next banking day). A summary of your payment dates will be confirmed to you by Close Brothers Premium Finance via email and post in your welcome pack. If you wish to change your payment date, please contact Close Brothers directly after your first instalment has been successfully debited.",
  "paymentFlow.monthly.directDebit.bankDetails.NotRequiredForRenewal.description": "As you already have a loan with Close Brothers we can simply reuse the bank details you have previously given us.",
  "paymentFlow.monthly.directDebit.bankDetails.NotRequiredForRenewal.button.title": "Add new bank details",
  "paymentFlow.monthly.directDebit.form.firstPayment.title": "When would you like to make your first payment?",
  "paymentFlow.monthly.directDebit.form.bankDetails.title": "Your Bank Details",
  "paymentFlow.monthly.directDebit.form.bankDetails.sortCode.title": "Sort Code",
  "paymentFlow.monthly.directDebit.form.bankDetails.sortCode.error": "Whoops, that sort code doesn't look right.",
  "paymentFlow.monthly.directDebit.form.bankDetails.accountNumber.title": "Account Number",
  "paymentFlow.monthly.directDebit.form.bankDetails.accountNumber.error": "Whoops, that account number doesn't look right.",
  "paymentFlow.monthly.directDebit.form.bankDetails.error": "Whoops, it looks like the bank details you entered are invalid. Please try again.",
  "paymentFlow.monthly.directDebit.form.bankDetails.useOldDetails": "Reuse previously saved bank details",
  "paymentFlow.monthly.directDebit.confirmRenewal.error": "Whoops, it looks like something went wrong. Please try again.",
  "paymentFlow.monthly.directDebit.paymentInfo.title": "Direct Debit Payment Info",
  "paymentFlow.monthly.directDebit.paymentInfo.description": "When you opt to pay monthly for your insurance, your direct debit payments will be facilitated by our finance partner, Close Brothers Ltd.\n" + "We are an intermediary actioning on their behalf and the broker fee for arrangement of this agreement is £0.\n" + "You are applying for a running account credit agreement with Close Brothers Ltd to pay for your insurance premiums. The credit is not available for any other use.\n\n" + "Should you need to make any changes to your policy during this year which will impact your loan amount, please contact us.\n\n" + "Before you enter into this agreement, it is advised to that you view the below documents prior to completing your agreement.",
  "paymentFlow.monthly.directDebit.paymentInfo.errorMessage": "Oh no, these documents have failed to load. Please try again.",
  "paymentFlow.monthly.directDebit.paymentInfo.links.commonQuestions": "Common Questions",
  "paymentFlow.monthly.directDebit.paymentInfo.links.creditInformation": "Credit Information",
  "paymentFlow.monthly.directDebit.paymentInfo.links.directDebitGuarantee": "Direct Debit Guarantee",
  "paymentFlow.monthly.depositPayment.title": "Pay your deposit",
  "paymentFlow.monthly.depositPayment.description": "Lastly, we require you to pay a deposit upfront before we set up your finance.",
  "paymentFlow.monthly.depositPayment.sidebar.description": "You’re nearly there, just one last step!\n\n" + "We accept Visa, Mastercard.",
  "paymentFlow.monthly.depositPayment.confirmationButton": "Pay Now",
  "paymentFlow.monthly.depositPayment.sidebar.title": "Step 2 of 2",
  "paymentFlow.monthly.depositPayment.yourDeposit.title": "What you pay today:",
  "paymentFlow.monthly.depositPayment.yourDeposit.alternativePaymentMethods": "Or pay by",
  "paymentFlow.financeCard.monthlyCost": "{price} / Month",
  "paymentFlow.financeCard.total.title": "Total Finance",
  "paymentFlow.financeCard.insurancePremium.title": "Insurance Premium",
  "paymentFlow.financeCard.adminFee.title": "Admin Fee",
  "paymentFlow.financeCard.extras.title": "Policy Extras",
  "paymentFlow.financeCard.financeFee.title": "Finance Fee",
  "paymentFlow.financeCard.depositPaid.title": "Deposit Paid",
  "paymentFlow.byCard.form.cardDetails.title": "Card Details",
  "paymentFlow.byCard.form.cardDetails.cardholderName": "Cardholder Name",
  "paymentFlow.byCard.form.cardDetails.cardholderName.placeholder": "Forename Surname",
  "paymentFlow.byCard.form.cardDetails.cardNumber": "Card Number",
  "paymentFlow.byCard.form.cardDetails.cardNumber.placeholder": "•••• •••• •••• ••••",
  "paymentFlow.byCard.form.cardDetails.cvv": "CVV",
  "paymentFlow.byCard.form.cardDetails.cvv.placeholder": "•••",
  "paymentFlow.byCard.form.cardDetails.expiry": "Expiry",
  "paymentFlow.byCard.form.cardDetails.expiry.placeholder": "MM / YYYY",
  "paymentFlow.byCard.form.button": "Pay Now",
  "paymentFlow.byCard.failure": "Oh no, something went wrong! It looks like your payment failed. Give it another try and if you still have no luck, contact us for help.",
  "paymentFlow.error.modal.body.title": "Something went wrong!",
  "paymentFlow.error.modal.footer.title": "Your payment failed.",
  "paymentFlow.error.modal.footer.description.contact": "Please contact us on 08000 113121 to resolve your issue.",
  "paymentFlow.error.modal.footer.description.retry": "Please check your card details and try again.",
  "paymentFlow.error.modal.footer.expiredStartDate.description": "The start date of your policy has expired, click the button below to update your cover start date.",
  "paymentFlow.error.modal.footer.expiredStartDate.button": "Ok",
  "paymentFlow.error.modal.footer.button": "Ok, thanks for letting me know",
  "paymentFlow.success.title": "Success!",
  "paymentFlow.success.description": "You're now insured. Welcome to Stroll.",
  "paymentFlow.success.callToAction.title": "What's next?",
  "paymentFlow.success.callToAction.checkEmail": "You'll need to verify your policy so look out for an email from us showing you how to set up your account and get started.",
  "paymentFlow.success.callToAction.downloadMobileApp": "Download the app on your device to keep up to date with your policy.",
  "paymentFlow.success.renewal.description": "You're now insured.",
  "paymentFlow.success.renewal.weWillSendYouAnEmail": "We’ll send you an email in the next couple of days letting you know when you can view your new insurance docs via your customer account.",
  "paymentFlow.success.renewal.weWillBeInTouch": "If we need anything else from you, the Stroll team will be in touch.",
  "placeholders.dropdown": "Select an option...",
  "placeholders.inputs.emailAddress": "person@email.com",
  "placeholders.date.day": "DD",
  "placeholders.date.month": "MM",
  "placeholders.date.shortYear": "YY",
  "placeholders.date.year": "YYYY",
  "placeholders.loremIpsum": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Eu dignissim adipiscing a vitae blandit sit lobortis. Viverra aliquam elementum est velit molestie sodales quis vitae. Vitae vel tortor et venenatis. ",
  "placeholders.sidebarNav.title": "Title text",
  "placeholders.sidebarNav.description": "Description text here",
  "structure.gender.male": "Male",
  "structure.gender.female": "Female",
  "structure.gender.unknown": "Unknown",
  "structure.buttons.guideButton.story.text": "Confirmed",
  "structure.buttons.viewSavedQuotesButton": "View Saved Quotes",
  "structure.currencies.gbp": "£",
  "structure.currencies.gbp.placeholder": "0",
  "structure.durations.yearDropdownPlaceholder": "Years",
  "structure.durations.monthsDropdownPlaceholder": "Months",
  "structure.durations.lessThan1Year": "Less than 1 year",
  "structure.durations.1Year": "1 Year",
  "structure.durations.1Month": "1 Month",
  "structure.durations.years": "{prefix} Years",
  "structure.durations.months": "{prefix} Months",
  "structure.form.inlineQuestionEmailInput.placeholder": "Email Address",
  "structure.form.inlineQuestionEmailInput.invalidEmailAddressFormat": "The email address you entered is invalid.",
  "structure.form.emailAddressInput.placeholder": "Email Address",
  "structure.form.dateInput.genericError": "Whoops! That doesn't look like a valid date.",
  "structure.form.inputField.required": "This field is required.",
  "structure.form.phoneNumberInput.placeholder": "Mobile Number",
  "structure.form.typeahead.loading": "Loading...",
  "structure.form.typeahead.noOptions": "No options found.",
  "structure.lists.policyExtras.description.anchorText": "Learn More",
  "structure.lists.summaryList.button": "Edit",
  "structure.modals.stopModal.button": "Dismiss",
  "structure.navigation.buttons.login": "Log in",
  "structure.steps.tooltip.defaultTitle": "More info",
  "structure.questions.multipleItems.addButton": "Add",
  "structure.questions.multipleItems.cancelButton": "Cancel",
  "structure.questions.classOfUse.socialOnly": "Social Only",
  "structure.questions.classOfUse.socialAndCommuting": "Social & Commuting",
  "structure.questions.classOfUse.socialCommutingAndBusiness": "Social, Commuting & Business",
  "structure.questions.classOfUse.socialAndBusiness": "Social & Business",
  "structure.questions.medicalConditions.dvlaAwareNoRestrictions": "DVLA Aware - No Restrictions",
  "structure.questions.medicalConditions.restricted.oneYear": "Licence Restricted (1 year)",
  "structure.questions.medicalConditions.restricted.twoYears": "Licence Restricted (2 years)",
  "structure.questions.medicalConditions.restricted.threeYears": "Licence Restricted (3 years)",
  "structure.questions.medicalConditions.restricted.fiveYears": "Licence Restricted (5 years)",
  "structure.questions.medicalConditions.dvlaUnaware": "DVLA Unaware",
  "structure.questions.claims.dropdown.placeholder": "Make a selection",
  "structure.questions.claims.status.settled": "Settled",
  "structure.questions.claims.status.pending": "Pending",
  "structure.questions.claims.incidentType.accident": "Accident",
  "structure.questions.claims.incidentType.theft": "Theft",
  "structure.questions.claims.incidentType.windscreen": "Windscreen/Glass",
  "structure.questions.claims.incidentType.fire": "Fire",
  "structure.questions.claims.incidentType.maliciousDamage": "Malicious damage",
  "structure.questions.claims.incidentType.riot": "Riot",
  "structure.questions.claims.incidentType.storm": "Storm",
  "structure.questions.claims.incidentType.vandalism": "Vandalism",
  "structure.questions.claims.accidentFault.thirdParty": "No",
  "structure.questions.claims.accidentFault.partial": "Partial",
  "structure.questions.claims.accidentFault.own": "Yes",
  "structure.questions.claims.accidentFault.pending": "Pending",
  "structure.questions.claims.accidentDescription.hitByThirdParty.front": "Hit by third-party in front",
  "structure.questions.claims.accidentDescription.hitByThirdParty.rear": "Hit by third-party in rear",
  "structure.questions.claims.accidentDescription.hitByThirdParty.side": "Hit by third-party in side",
  "structure.questions.claims.accidentDescription.hitByThirdParty.whenParked": "Hit by third-party when parked",
  "structure.questions.claims.accidentDescription.hitByThirdParty.whenReversing": "Hit by third-party when reversing",
  "structure.questions.claims.accidentDescription.hitThirdParty.front": "Hit third-party in front",
  "structure.questions.claims.accidentDescription.hitThirdParty.rear": "Hit third-party in rear",
  "structure.questions.claims.accidentDescription.hitThirdParty.side": "Hit third-party in side",
  "structure.questions.claims.accidentDescription.hitThirdParty.whenParked": "Hit third-party when parked",
  "structure.questions.claims.accidentDescription.hitThirdParty.whenReversing": "Hit third-party when reversing",
  "structure.questions.claims.accidentDescription.noOtherVehicleInvolved": "No other vehicle involved",
  "structure.questions.claims.incidentPolicy.company": "Company",
  "structure.questions.claims.incidentPolicy.own": "My own policy",
  "structure.questions.claims.incidentPolicy.other": "Other",
  "structure.questions.claims.incidentPolicy.proposers": "Proposers'",
  "structure.questions.claims.affectedNCB.yes": "Yes",
  "structure.questions.claims.affectedNCB.no": "No",
  "structure.questions.claims.affectedNCB.noProtected": "No (NCB protected)",
  "structure.questions.vehicle.alarmInstaller.manufacturer": "Manufacturer",
  "structure.questions.vehicle.alarmInstaller.manufacturerApproved": "Manufacturer Approved Fitter",
  "structure.questions.vehicle.alarmInstaller.proposer": "Myself",
  "structure.questions.vehicle.alarmInstaller.VSIBAccredited": "VSIB Accredited Fitter",
  "structure.questions.vehicle.alarmInstaller.unknown": "I don't know",
  "structure.actionCard.claims.affectedNCB.yes": "No Claims Bonus Affected",
  "structure.actionCard.claims.affectedNCB.no": "No Claims Bonus Not Affected",
  "structure.actionCard.claims.affectedNCB.noProtected": "No Claims Bonus Protected",
  "structure.footer.helpAndSupport": "Help & Support",
  "structure.footer.blog": "Blog",
  "structure.footer.copyright": "© {currentYear} Stroll Insurance Services Ltd. Part of the Prestige Insurance Holdings Group, Stroll Insurance Services Limited is authorised and regulated by the Financial Conduct Authority − 467185.",
  "structure.footer.registration": "Registered in Northern Ireland (NI004308) 10 Governors Place, Carrickfergus, Co. Antrim, BT38 7BN.",
  "structure.footer.privacyPolicy": "Privacy Policy",
  "structure.footer.cookiePolicy": "Cookie Policy",
  "structure.footer.contactUs": "Contact us",
  "general.boolean.yes": "Yes",
  "general.boolean.no": "No",
  "general.success": "Success!",
  "general.none": "None",
  "general.edit": "Edit",
  "test.string": "PLACEHOLDER",
  "test.string.1": "TEST STRING 1",
  "test.string.2": "TEST STRING 2",
  "test.string.3": "TEST STRING 3",
  "test.string.4": "TEST STRING 4",
  "test.string.withNumbers": "PLACEHOLDER 123",
  "test.string.withArguments": "PLACEHOLDER {arg1}",
  "test.structure.question.title": "Section Header",
  "dashboard.header.welcomeText": "Hi {firstName}!",
  "dashboard.subheader.yourPolicies": "Your Policies",
  "dashboard.subheader.manageYourPolicies": "Manage Your Policies",
  "dashboard.policyCard.activePolicy.progressBarDescription.dueTime": "Due at {time}",
  "dashboard.policyCard.activePolicy.progressBarDescription.daysLeft": "Due in {days, plural, one {# day} other {# days}}",
  "dashboard.policyCard.upcomingPolicy.progressBarDescription.activeTime": "Active at {time}",
  "dashboard.policyCard.upcomingPolicy.progressBarDescription.activeInDays": "Active in {days, plural, one {# day} other {# days}}",
  "dashboard.policyCard.cancelledPolicy.progressBarDescription": "Policy Cancelled",
  "dashboard.policyCard.expiredPolicy.progressBarDescription": "Policy Expired",
  "dashboard.contactCard.title": "Need to get in touch?",
  "dashboard.contactCard.description": "Our team are here for you when you need us most.",
  "dashboard.contactCard.generalClaims.title": "Customer Service & Claims Helpline",
  "dashboard.contactCard.generalClaims.workingHours": "(Mon-Fri, 9am-5pm)",
  "dashboard.contactCard.emergencyClaims.title": "24/7 Emergency Claims",
  "dashboard.uploadDocumentsCard.title": "Upload your docs",
  "dashboard.uploadDocumentsCard.description": "Upload your driving licence, proof of your No Claims Bonus or any other documents we've asked for.",
  "dashboard.uploadDocumentsCard.button.title": "Upload Documents",
  "dashboard.policyCard.renewalStatusPill.timeToRenew": "Click to renew!",
  "dashboard.policyCard.renewalStatusPill.renewComingSoon": "Renewal coming soon!",
  "claims.pointOfContactCard.title": "Points of contact",
  "claims.pointOfContactCard.description": "If you need to get in touch with us about your claim.",
  "claims.pointOfContactCard.generalClaims.item.outOfHoursTitle": "Out of hours helpline",
  "claims.pointOfContactCard.Claims.FAQ": "Need help?",
  "claims.pointOfContactCard.policyExtras.title": "Your Policy Extras",
  "portal.policyCard.activePolicy.title": "Active Policy",
  "portal.policyCard.upcomingPolicy.title": "Upcoming Policy",
  "portal.policyCard.cancelledPolicy.title": "Cancelled Policy",
  "portal.policyCard.expiredPolicy.title": "Expired Policy",
  "portal.policySelector.title": "Select your policy:",
  "portal.renewals.summary.title": "Simple, straightforward renewals",
  "portal.renewals.summary.renewalOnlyDescription": "Compare your renewal with last year's and enhance your cover with our policy extras. Double check your details and let us know about any changes.",
  "portal.renewals.summary.renewalAndRebrokeDescription": "Here’s your renewal price with your current insurer. We also found you an alternative deal, so you can compare and have options.",
  "portal.renewals.summary.rebrokeOnlyDescription": "Your current insurer can’t offer you a price this year, don't worry, we can insure you with {insurerName}. You can compare this policy with last year and enhance your cover with our policy extras.",
  "portal.renewals.summary.cardTitle": "Your renewal is due in {days} days",
  //TODO-REBROKE Update S1 copy following confirmation of updated version
  "portal.renewals.summary.priceCards.loading.title": "Sit tight, we're getting your quote ready",
  "portal.renewals.summary.priceCard.renewalOnlyPriceTitle": "Our best price based on your info",
  "portal.renewals.summary.priceCard.bestPriceTitle": "Our best price based on your info",
  "portal.renewals.summary.priceCard.nextBestPriceTitle": "Our next best price",
  "portal.renewals.summary.priceCard.currentInsurerTag": "Your Current Insurer",
  "portal.renewals.summary.priceCard.renewalInsurer": "This year with {insurerName}*",
  "portal.renewals.summary.priceCard.rebrokeInsurer": "Switch to {insurerName}*",
  "portal.renewals.summary.priceCard.iptText": "*Policy Extras & IPT included",
  "portal.renewals.summary.priceCard.upfrontPayment": "Upfront payment of <primary>{upfrontPayment}</primary>",
  "portal.renewals.summary.priceCard.instalments": "Followed by {instalments} payments of <primary>{monthlyInstalmentAmount}</primary>",
  "portal.renewals.summary.priceCard.button": "Compare with Last Year",
  "portal.renewals.summary.helpCard.title": "Need help?",
  "portal.renewals.summary.helpCard.description": "If you need a hand or have a question about your upcoming renewal, just drop us a note via chat or call us on the number below.",
  "portal.renewals.summary.helpCard.button": "Chat to us",
  "portal.renewals.summary.helpCard.talkToOurTeam": "Call us",
  "portal.renewals.summary.helpCard.phoneNumber": "08000 11 31 21",
  "portal.renewals.summary.compareCover.title": "Compare this year’s price with last year",
  "portal.renewals.summary.compareCover.description": "It’s important to let us know if any details have changed since last year, no matter how small as it can impact your cover. \n\n" + "If you want to delve deeper into your policy info, you can check the policy docs.",
  "portal.renewals.summary.compareCover.thisYears.title": "With {insurer}",
  "portal.renewals.summary.compareCover.thisYears.instalments": "{instalments} monthly payments of ",
  "portal.renewals.summary.compareCover.thisYears.button": "View Payment Options",
  "portal.renewals.summary.compareCover.thisYears.viewIpid": "View Policy Info",
  "portal.renewals.summary.compareCover.lastYears.title": "Last Year's Price",
  "portal.renewals.summary.compareCover.lastYears.previousAmount": "Total Annualised Premium",
  "portal.renewals.summary.compareCover.lastYears.button": "View Breakdown",
  "portal.renewals.summary.compareCover.lastYears.viewIpid": "Old Policy Document",
  "portal.renewals.summary.extrasTable.header.column1": "Optional Policy Extras",
  "portal.renewals.summary.extrasTable.header.column2": "Add to Policy",
  "portal.renewals.summary.extrasTable.header.column3": "Last Year's",
  "portal.renewals.summary.extrasTable.smartfobKeycare.title": "SmartFob Keycare",
  "portal.renewals.summary.extrasTable.smartfobKeycare.description": "Finding your keys has never been easier. Attach the SmartFob to your keys and you can use the app to locate and track where your keys and phone are - it's that simple. This covers the cost to replace your lost or stolen keys up to £1500.",
  "portal.renewals.summary.extrasTable.roadsideAssistance.title": "Roadside Assistance",
  "portal.renewals.summary.extrasTable.roadsideAssistance.description": "Roadside Assistance cover comes in handy as it offers unlimited mileage recovery anywhere in NI and ROI. We will send a repairer who will either fix your car there and then or tow you to the nearest garage.",
  "portal.renewals.summary.extrasTable.courtesyCar.title": "Replacement Car",
  "portal.renewals.summary.extrasTable.courtesyCar.description": "This type of cover is a lifesaver if your car’s out of action for a couple of weeks. We will provide you with a replacement car for up to three weeks if your car has been written off in an \"at fault\" accident.",
  "portal.renewals.summary.extrasTable.legalExpenses.title": "Legal Expenses",
  "portal.renewals.summary.extrasTable.legalExpenses.description": "Legal Expenses comes in useful if you need to make a claim against someone else. It will cover the cost of your legal fees if you want to pursue compensation. It also includes a FREE legal advice helpline service 24/7.",
  "portal.renewals.summary.extrasTable.excessInsurance.title": "Excess Insurance",
  "portal.renewals.summary.extrasTable.excessInsurance.description": "Excess Insurance will reimburse you for the excess you paid following a claim, so you're not out of pocket for too long.",
  "portal.renewals.summary.extrasTable.row.addToPlan": "Add for {price}",
  "portal.renewals.summary.extrasTable.row.addedToPlan": "<b>Added</b> for {price}",
  "portal.renewals.summary.extrasTable.row.includedLastYear": "Included last year",
  "portal.renewals.summary.extrasTable.row.includedLastYear.mobile": "Included last year at {price}",
  "portal.renewals.summary.extrasTable.row.notIncludedLastYear": "Not included last year",
  "portal.renewals.summary.extrasTable.row.viewPolicyDocument": "View Policy Document",
  "portal.renewals.summary.protectNoClaims.header.column1": "Your No Claims Bonus Protection",
  "portal.renewals.summary.protectNoClaims.header.column2": "Last Year's Cover",
  "portal.renewals.summary.protectNoClaims.body.column1.title": "What's No Claims Bonus Protection? (PNCB) ",
  "portal.renewals.summary.protectNoClaims.body.column1.description": "If you have an incident, your No Claims Bonus will usually be reduced at your next renewal. When you opt to buy NCB Protection you are protecting the bonus you have earned from your previous driving experience as a policyholder. It is important to remember that if you do have an accident, even though your NCB won't be reduced your price may still change at your next renewal.",
  "portal.renewals.summary.protectNoClaims.body.column1.whatIsNCBProtection": "What is NCB Protection",
  "portal.renewals.summary.protectNoClaims.body.renewal.add.title": "Add a PNCB to your policy",
  "portal.renewals.summary.protectNoClaims.body.renewal.add.description": "To add this to your policy please talk to a member of the Stroll team.",
  "portal.renewals.summary.protectNoClaims.body.renewal.remove.title": "You have added a PNCB to your policy",
  "portal.renewals.summary.protectNoClaims.body.renewal.remove.description": "If you wish to remove this please get in touch with our team.",
  "portal.renewals.summary.protectNoClaims.body.renewal.button": "Chat to us",
  "portal.renewals.summary.protectNoClaims.body.rebroke.add.title": "Add a PNCB to your policy.",
  "portal.renewals.summary.protectNoClaims.body.rebroke.add.button": "Add this to your policy",
  "portal.renewals.summary.protectNoClaims.body.rebroke.remove.title": "Added for ",
  "portal.renewals.summary.protectNoClaims.body.rebroke.remove.description": "This is added to your policy as default.",
  "portal.renewals.summary.protectNoClaims.body.rebroke.remove.button": "Remove",
  "portal.renewals.summary.priceSummary.title": "Payment Options",
  "portal.renewals.summary.priceSummary.annualOnly.title": "Payment details",
  "portal.renewals.summary.priceSummary.annual.description": "We’re pretty flexible payment wise. Simply choose whatever option is handiest for you. ",
  "portal.renewals.summary.priceSummary.monthly.description": "Last year you paid monthly, do you want to do the same again?",
  "portal.renewals.summary.priceSummary.card.monthly.title": "Pay Monthly",
  "portal.renewals.summary.priceSummary.card.annual.title": "Pay Annually",
  "portal.renewals.summary.priceSummary.card.previouslySelected": "How you paid last year",
  "portal.renewals.summary.priceSummary.card.monthly.costPerMonth": "/month",
  "portal.renewals.summary.priceSummary.card.table.insurancePremium": "Insurance Premium",
  "portal.renewals.summary.priceSummary.card.table.policyExtras": "Policy Extras",
  "portal.renewals.summary.priceSummary.card.table.adminFee": "Admin Fee",
  "portal.renewals.summary.priceSummary.card.table.brokerDiscount": "Stroll Broker Discount",
  "portal.renewals.summary.priceSummary.card.table.monthlyTotalPayable": "Total <span>(Inc {interestRate}% Interest/{apr}% APR)</span>",
  "portal.renewals.summary.priceSummary.card.monthly.deposit.title": "Deposit",
  "portal.renewals.summary.priceSummary.card.monthly.deposit.description": "Choose the amount you want to pay for your deposit.",
  "portal.renewals.summary.priceSummary.card.monthly.deposit.option": "{depositPercentage}% Deposit - <b>{depositAmount}</b>",
  "portal.renewals.summary.priceSummary.card.monthly.deposit.button": "Options",
  "portal.renewals.summary.priceSummary.card.monthly.deposit.cancel": "Cancel",
  "portal.renewals.summary.priceSummary.card.monthly.deposit.confirm": "Confirm",
  "portal.renewals.summary.priceSummary.card.selectQuoteButton": "Confirm and Continue",
  "portal.renewals.summary.footer.legal.title": "The Legal Stuff",
  "portal.renewals.summary.footer.legal.description": "By shopping around, you might be able to get a better price, but as your local provider, we work with our leading Insurer partner to offer you a deal we think suits your needs and gives you the right cover if the unexpected happens.\n" + "\nBy proceeding, you are also confirming you have both read and accepted our T&Cs and Privacy Policy. These are always available to view on the Stroll website.",
  "portal.renewals.summary.footer.legal.button.privacyPolicy": "Privacy Policy",
  "portal.renewals.summary.footer.legal.button.termsAndConditions": "Terms and Conditions",
  "portal.renewals.summary.footer.policyDocuments.title": "Policy Documents",
  "portal.renewals.summary.footer.policyDocuments.description": "These documents go into the finer details of your car insurance provider. It’s important to take a look at them so you know the key features, benefits and exclusions of your policy.",
  "portal.renewals.summary.footer.policyDocuments.button.policyInfo": "Policy Information",
  "portal.renewals.summary.footer.policyDocuments.button.policyBooklet": "Policy Booklet",
  "portal.renewals.summary.footer.policyDocuments.button.policyDocuments": "Policy Documents",
  "portal.renewals.summary.footer.notRenewing.title": "Don’t want to renew with us?",
  "portal.renewals.summary.footer.notRenewing.description": "Make sure to let us know, as we’ll need to prepare some paperwork for your new provider.",
  "portal.renewals.summary.footer.notRenewing.talkToUs": "Call us",
  "portal.renewals.summary.footer.notRenewing.phoneNumber": "08000 11 31 21",
  "portal.renewals.summary.error.title": "Unable to load renewal summary.",
  "portal.renewals.summary.error.description": "We're having trouble loading your renewal summary right now, please try again later.",
  "portal.renewals.summary.onlineRenewalSoftStop.ohItLookslike": "Oh it looks like we can’t insure you online at the moment",
  "portal.renewals.summary.onlineRenewalSoftStop.butYouCanCall": "But you can call us",
  "portal.renewals.summary.onlineRenewalSoftStop.talkToOurTeam": "Talk to our team",
  "portal.renewals.summary.onlineRenewalSoftStop.gotIt": "Got it",
  "portal.renewals.summary.onlineRenewalSoftStop.stillNeedToTalk": "Still need to talk to us? Call us: <a>08000 11 31 21</a>",
  "portal.renewals.excessProtectionModal.title": "Excess Protection",
  "portal.renewals.excessProtectionModal.description": "What level of excess insurance would you like?",
  "portal.renewals.excessProtectionModal.confirm": "Confirm",
  "portal.renewals.excessProtectionModal.cancel": "Cancel",
  "portal.renewals.annualised.breakdown.backToQuote": "< Back to Quote",
  "portal.renewals.annualised.breakdown.title": "Last Year’s Breakdown",
  "portal.renewals.annualised.breakdown.description": "This is what you paid last year, including any changes you made.",
  "portal.renewals.annualised.breakdown.card.header.title": "Last Year's Price:",
  "portal.renewals.annualised.breakdown.card.header.subtitle": "This is what you paid when you first took the policy with us",
  "portal.renewals.annualised.breakdown.card.body.policyUpdate": "Policy Update <span>({date})</span>",
  "portal.renewals.annualised.breakdown.card.body.annualised": "This is what you would have paid including any changes made within the year",
  "portal.renewals.annualised.breakdown.card.footer.button": "Back to Quote",
  "portal.renewals.annualised.breakdown.error.title": "Unable to load last year's breakdown.",
  "portal.renewals.annualised.breakdown.error.description": "We're having trouble loading your last year's breakdown right now, please try again later.",
  "portal.renewals.getInTouchModal.title": "We need more info",
  "portal.renewals.getInTouchModal.description": "Please get in touch with us as we’re not able to change details online at the moment.",
  "portal.renewals.getInTouchModal.buttonText": "Get in Touch",
  "portal.renewals.detailConfirmation.confirmYourDetails": "Next step, Confirm Your Details",
  "portal.renewals.detailConfirmation.previous": "Previous",
  "portal.renewals.detailConfirmation.proceedToPayment": "Proceed to Payment",
  "portal.renewals.detailConfirmation.yourCar": "Your Car",
  "portal.renewals.detailConfirmation.yourCar.registration": "Registration",
  "portal.renewals.detailConfirmation.yourCar.purchaseDate": "Purchase Date",
  "portal.renewals.detailConfirmation.yourCar.vehicle": "Vehicle",
  "portal.renewals.detailConfirmation.yourCar.fuelType": "Fuel Type",
  "portal.renewals.detailConfirmation.yourCar.transmissionType": "Transmission Type",
  "portal.renewals.detailConfirmation.yourCar.yearOfManufacture": "Year of Manufacture",
  "portal.renewals.detailConfirmation.yourCar.engineSize": "Engine Size",
  "portal.renewals.detailConfirmation.yourCar.trimLevel": "Model Type",
  "portal.renewals.detailConfirmation.yourCar.driverPosition": "Driver Position",
  "portal.renewals.detailConfirmation.yourCar.numberOfSeats": "Number of Seats",
  "portal.renewals.detailConfirmation.yourCar.numberOfDoors": "Number of Doors",
  "portal.renewals.detailConfirmation.yourCar.tracker": "Does your car have a tracker fitted?",
  "portal.renewals.detailConfirmation.yourCar.imported": "Is your car imported?",
  "portal.renewals.detailConfirmation.yourCar.modified": "Has your car been modified?",
  "portal.renewals.detailConfirmation.yourCar.owner": "Are you the registered owner of the car?",
  "portal.renewals.detailConfirmation.yourCar.registeredKeeper": "Are you the registered keeper of the car?",
  "portal.renewals.detailConfirmation.yourCarModifications": "Your Car Modifications",
  "portal.renewals.detailConfirmation.yourCarModifications.tableItem.title": "Modification {count} of {max}",
  "portal.renewals.detailConfirmation.yourDetails": "Your Details",
  "portal.renewals.detailConfirmation.yourDetails.title": "Title",
  "portal.renewals.detailConfirmation.yourDetails.firstName": "First Name",
  "portal.renewals.detailConfirmation.yourDetails.surname": "Surname",
  "portal.renewals.detailConfirmation.yourDetails.dateOfBirth": "Date of Birth",
  "portal.renewals.detailConfirmation.yourDetails.gender": "Gender",
  "portal.renewals.detailConfirmation.yourDetails.address": "Address",
  "portal.renewals.detailConfirmation.yourDetails.carAtAddress": "Is the car kept at the above address?",
  "portal.renewals.detailConfirmation.yourDetails.carKeptAtNight": "Where do you keep your car at night?",
  "portal.renewals.detailConfirmation.yourDetails.email": "Email address",
  "portal.renewals.detailConfirmation.yourDetails.phoneNumber": "Your phone number",
  "portal.renewals.detailConfirmation.yourDetails.relationshipStatus": "Relationship status",
  "portal.renewals.detailConfirmation.yourDetails.homeOwner": "Are you a homeowner?",
  "portal.renewals.detailConfirmation.yourDetails.ukResidency.lengthOfTime": "How long have you continuously lived in the UK?",
  "portal.renewals.detailConfirmation.yourDetails.employmentStatus": "Employment status",
  "portal.renewals.detailConfirmation.yourDetails.industry": "What industry do you work in?",
  "portal.renewals.detailConfirmation.yourDetails.jobTitle": "Job title",
  "portal.renewals.detailConfirmation.yourDetails.jobTitle.retired": "What did you do before you retired?",
  "portal.renewals.detailConfirmation.yourDetails.jobTitle.unemployed": "What did you do before you became unemployed?",
  "portal.renewals.detailConfirmation.yourDetails.secondJob": "Do you have a second or part time job?",
  "portal.renewals.detailConfirmation.yourDetails.secondJobIndustry": "What industry is your second job in?",
  "portal.renewals.detailConfirmation.yourDetails.secondJobTitle": "Second job title",
  "portal.renewals.detailConfirmation.yourDetails.carUse": "What do you use your car for?",
  "portal.renewals.detailConfirmation.yourDetails.mainDriver": "Are you the main driver?",
  "portal.renewals.detailConfirmation.yourDetails.licence": "Licence type",
  "portal.renewals.detailConfirmation.yourDetails.licenceDuration": "How long have you held your licence?",
  "portal.renewals.detailConfirmation.yourDetails.medicalConditions": "Do you have any medical conditions or disabilities that the DVLA needs to be aware of?",
  "portal.renewals.detailConfirmation.yourDetails.claims": "Have you had any motor accidents, claims or losses in the past 5 years?",
  "portal.renewals.detailConfirmation.yourDetails.convictions": "Have you had any of the following:",
  "portal.renewals.detailConfirmation.yourDetails.nonMotoringConvictions": "Have you any non-motoring offences?",
  "portal.renewals.detailConfirmation.yourDetails.insuranceDeclined": "Have you ever had insurance declined, cancelled or special terms imposed?",
  "portal.renewals.detailConfirmation.yourDetails.storedAtNight.onTheStreet": "On the street",
  "portal.renewals.detailConfirmation.yourDetails.storedAtNight.inTheDriveway": "In the driveway",
  "portal.renewals.detailConfirmation.yourDetails.storedAtNight.inAGarage": "In a garage",
  "portal.renewals.detailConfirmation.yourDetails.storedAtNight.inACarport": "In a carport",
  "portal.renewals.detailConfirmation.yourDetails.storedAtNight.inACarPark": "In a car park",
  "portal.renewals.detailConfirmation.yourDetails.storedAtNight.inALockedCompound": "In a locked compound",
  "portal.renewals.detailConfirmation.yourDetails.storedAtNight.inAnUnlockedCompound": "In an unlocked compound",
  "portal.renewals.detailConfirmation.yourDetails.storedAtNight.onPrivatePremises": "On private premises",
  "portal.renewals.detailConfirmation.yourDetails.storedAtNight.onUnlockedPrivatePremises": "Within unlocked private premises",
  "portal.renewals.detailConfirmation.yourDetails.storedAtNight.onSecurePrivatePremises": "Within secured private premises",
  "portal.renewals.detailConfirmation.yourDetails.storedAtNight.other": "Other",
  "portal.renewals.detailConfirmation.yourDetails.classOfUse.social": "Social only",
  "portal.renewals.detailConfirmation.yourDetails.classOfUse.socialCommuting": "Social & Commuting",
  "portal.renewals.detailConfirmation.yourDetails.classOfUse.socialCommutingBusiness": "Social, Commuting & Business",
  "portal.renewals.detailConfirmation.yourCover": "Your Cover",
  "portal.renewals.detailConfirmation.yourCover.coverType": "Type of Cover:",
  "portal.renewals.detailConfirmation.yourCover.voluntaryExcess": "Voluntary Excess:",
  "portal.renewals.detailConfirmation.yourCover.compulsoryExcess": "Compulsory Excess:",
  "portal.renewals.detailConfirmation.yourCover.compulsoryExcess.renewal": "Details of the compulsory excesses are shown in the new documents supplied by your Insurer",
  "portal.renewals.detailConfirmation.yourCover.mileage": "Total annual mileage (excluding business mileage):",
  "portal.renewals.detailConfirmation.yourCover.businessMileage": "Total business mileage:",
  "portal.renewals.detailConfirmation.yourCover.insuranceStart": "Renewal Date:",
  "portal.renewals.detailConfirmation.yourClaims": "Your Claims",
  "portal.renewals.detailConfirmation.yourClaims.atFault.noBlame": "No",
  "portal.renewals.detailConfirmation.yourClaims.atFault.partial": "Partial",
  "portal.renewals.detailConfirmation.yourClaims.atFault.pending": "Pending",
  "portal.renewals.detailConfirmation.yourClaims.atFault.yes": "Yes",
  "portal.renewals.detailConfirmation.yourClaims.settled": "Is the claim settled or still pending?",
  "portal.renewals.detailConfirmation.yourClaims.type": "What type of incident did you have?",
  "portal.renewals.detailConfirmation.yourClaims.fault": "Was the accident deemed your fault?",
  "portal.renewals.detailConfirmation.yourClaims.description": "What happened in the accident?",
  "portal.renewals.detailConfirmation.yourClaims.policy": "Which policy were you driving under?",
  "portal.renewals.detailConfirmation.yourClaims.ncbAffected": "Was your No Claims Bonus affected?",
  "portal.renewals.detailConfirmation.yourClaims.injuries": "Were there any injuries?",
  "portal.renewals.detailConfirmation.yourClaims.cost": "Total Claim Cost",
  "portal.renewals.detailConfirmation.yourClaims.tableItem.title": "Claim {count} of {max}",
  "portal.renewals.detailConfirmation.yourClaims.emptyState.title": "You have not declared any claims.",
  "portal.renewals.detailConfirmation.yourClaims.emptyState.subtitle": "Is this information incorrect? Please get in touch with us to update your details",
  "portal.renewals.detailConfirmation.additionalDrivers": "Additional Drivers",
  "portal.renewals.detailConfirmation.additionalDrivers.tableItem.title": "Additional Driver {count} of {max}",
  "portal.renewals.detailConfirmation.additionalDrivers.title": "Title",
  "portal.renewals.detailConfirmation.additionalDrivers.firstName": "First Name",
  "portal.renewals.detailConfirmation.additionalDrivers.surname": "Surname",
  "portal.renewals.detailConfirmation.additionalDrivers.dateOfBirth": "Date of Birth",
  "portal.renewals.detailConfirmation.additionalDrivers.ukResidency.lengthOfTime": "How long have they continuously lived in the UK?",
  "portal.renewals.detailConfirmation.additionalDrivers.relationshipToPolicyHolder": "What is their relationship to you?",
  "portal.renewals.detailConfirmation.additionalDrivers.relationshipStatus": "Relationship status",
  "portal.renewals.detailConfirmation.additionalDrivers.employmentStatus": "Employment status",
  "portal.renewals.detailConfirmation.additionalDrivers.industry": "What industry do they work in?",
  "portal.renewals.detailConfirmation.additionalDrivers.jobTitle": "Job title",
  "portal.renewals.detailConfirmation.additionalDrivers.retired": "What did they do before they retired?",
  "portal.renewals.detailConfirmation.additionalDrivers.unemployed": "What did they do before they became unemployed?",
  "portal.renewals.detailConfirmation.additionalDrivers.secondJob": "Do they have a second job?",
  "portal.renewals.detailConfirmation.additionalDrivers.secondJobIndustry": "What industry is their second job in?",
  "portal.renewals.detailConfirmation.additionalDrivers.secondJobTitle": "What is their second job title?",
  "portal.renewals.detailConfirmation.additionalDrivers.carUsage": "What do they use the car for?",
  "portal.renewals.detailConfirmation.additionalDrivers.mainDriver": "Are they the main driver?",
  "portal.renewals.detailConfirmation.additionalDrivers.licence": "Licence type",
  "portal.renewals.detailConfirmation.additionalDrivers.licenceDuration": "How long have they held their licence?",
  "portal.renewals.detailConfirmation.additionalDrivers.medicalConditions": "Do they have any medical conditions or disabilities that the DVLA needs to be aware of?",
  "portal.renewals.detailConfirmation.additionalDrivers.claims": "Have they had any motor accidents, claims or losses in the past 5 years?",
  "portal.renewals.detailConfirmation.additionalDrivers.convictions": "Have they had any of the following:",
  "portal.renewals.detailConfirmation.additionalDrivers.nonMotorConvictions": "Have they had any non-motoring offences?",
  "portal.renewals.detailConfirmation.additionalDrivers.insuranceDeclined": "Have they ever had insurance declined, cancelled or special terms imposed?",
  "portal.renewals.detailConfirmation.additionalDrivers.emptyState.title": "You have not added any additional drivers.",
  "portal.renewals.detailConfirmation.additionalDrivers.emptyState.subtitle": "Is this information incorrect? You can change this by clicking the update button",
  "portal.renewals.detailConfirmation.householdVehicles": "Household Vehicles",
  "portal.renewals.detailConfirmation.householdVehicles.title": "How many vehicles are at your household?",
  "portal.renewals.detailConfirmation.householdVehicles.subtitle": "No more than 2",
  "portal.renewals.detailConfirmation.yourConvictions": "Your Motoring Offences",
  "portal.renewals.detailConfirmation.yourConvictions.tableItem.title": "Motoring Offence {count} of {max}",
  "portal.renewals.detailConfirmation.yourConvictions.tableItem.additionalDriverTitle": "{driverName} - Motoring Offence {count} of {max}",
  "portal.renewals.detailConfirmation.yourConvictions.tableItem.type": "Motoring Offence type",
  "portal.renewals.detailConfirmation.yourConvictions.tableItem.date": "Conviction Date",
  "portal.renewals.detailConfirmation.yourConvictions.tableItem.fine": "Fine Amount",
  "portal.renewals.detailConfirmation.yourConvictions.tableItem.points": "Penalty Points",
  "portal.renewals.detailConfirmation.yourConvictions.tableItem.points.value": "{value} Points",
  "portal.renewals.detailConfirmation.yourConvictions.tableItem.drivingBan": "Driving Ban Length",
  "portal.renewals.detailConfirmation.yourConvictions.emptyState.title": "You have not declared any motoring offences.",
  "portal.renewals.detailConfirmation.yourConvictions.emptyState.subtitle": "Is this information incorrect? You can change this by clicking the update button",
  "portal.renewals.detailConfirmation.additionalDriverConvictions": "Additional Driver Motoring Offences",
  "portal.renewals.detailConfirmation.additionalDriverConvictions.tableItem.title": "{name} - Motoring Offence {count} of {max}",
  "portal.renewals.detailConfirmation.additionalDriverClaims": "Additional Driver Claims",
  "portal.renewals.detailConfirmation.additionalDriverClaims.atFault.noBlame": "No",
  "portal.renewals.detailConfirmation.additionalDriverClaims.atFault.partial": "Partial",
  "portal.renewals.detailConfirmation.additionalDriverClaims.atFault.pending": "Pending",
  "portal.renewals.detailConfirmation.additionalDriverClaims.atFault.yes": "Yes",
  "portal.renewals.detailConfirmation.additionalDriverClaims.settled": "Is the claim settled or still pending?",
  "portal.renewals.detailConfirmation.additionalDriverClaims.type": "What type of incident did they have?",
  "portal.renewals.detailConfirmation.additionalDriverClaims.fault": "Was the accident deemed their fault?",
  "portal.renewals.detailConfirmation.additionalDriverClaims.description": "What happened in the accident?",
  "portal.renewals.detailConfirmation.additionalDriverClaims.policy": "Which policy were they driving under?",
  "portal.renewals.detailConfirmation.additionalDriverClaims.ncbAffected": "Was their No Claims Bonus affected?",
  "portal.renewals.detailConfirmation.additionalDriverClaims.injuries": "Were there any injuries?",
  "portal.renewals.detailConfirmation.additionalDriverClaims.cost": "Total Claim Cost",
  "portal.renewals.detailConfirmation.additionalDriverClaims.tableItem.title": "{name} - Claim {count} of {max}",
  "portal.renewals.detailConfirmationSidebar.thisYearsPrice": "This Year's Price",
  "portal.renewals.detailConfirmationSidebar.monthlyPrice": "{installments} monthly installments of <span>{price}</span>",
  "portal.renewals.detailConfirmationSidebar.needHelpFooter.needHelp": "Need to make changes?",
  "portal.renewals.detailConfirmationSidebar.needHelpFooter.ourTeamAreHere": "If you need a hand call us on the number below.",
  "portal.renewals.detailConfirmationSidebar.needHelpFooter.contactUs": "Call us",
  "portal.renewals.detailConfirmation.buttonFooter.changeDetails": "Change Details",
  "portal.renewals.detailConfirmation.buttonFooter.update": "Update",
  "portal.renewals.detailConfirmation.buttonFooter.confirm": "Confirm",
  // Agent Portal
  "agentPortal.contactDetails.invalidEmail": "The email address you have entered is invalid.",
  "agentPortal.contactDetails.invalidPhoneNumber": "The mobile number you have entered is invalid.",
  "agentPortal.resetPassword.description": "Generating the account activation link will reset this customer’s password. Please only use this functionality if you are sure the customer has no access to their account.",
  "agentPortal.resetPassword.acceptTerms": "I have read and understand the above"
};
